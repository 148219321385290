import React, {
  useEffect, useState, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Bootstrap from 'bootstrap';
import { formatDistanceToNowStrict } from 'date-fns';
import { getSelectedItems, setSelectedItems, getCurrentFolder } from '../../../store/reducers/folders';
import { ReactComponent as Trash } from '../../../assets/icons/table/trash.svg';
import { ReactComponent as Edit } from '../../../assets/icons/table/edit.svg';
import { ReactComponent as DisabledTrash } from '../../../assets/icons/disabled_trash.svg';
import { ReactComponent as Copy } from '../../../assets/icons/table/copy.svg';
import { ReactComponent as ManageFolder } from '../../../assets/icons/manage_folder.svg';
import { ReactComponent as AllIcon } from '../../../assets/icons/box.svg';
import { ReactComponent as CustomListsIcon } from '../../../assets/icons/custom_list.svg';
import { ReactComponent as SegmentsIcon } from '../../../assets/icons/segment.svg';
import { audiencesApi } from '../../../api/audiences';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import ErrorModal from '../../../components/ui/modals/ErrorModal';
import Tooltip from '../../../components/ui/Tooltip';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import SnapshotElem from './SnapshotElem';
import MainTable from '../../../components/base/MainTable';
import Settings from '../../../assets/images/dashboard/settings.png';
import { convertNumber } from '../../../tools/NumberConverterTool';
import { debounce } from '../../../utils/debounce';
import emitter from '../../../utils/emitter';
import { createdCustomList, createdSegment } from '../../../store/reducers/segment';
import TableHeader from '../../../components/ui/Table/TableHeader';
import NameField from '../../../components/ui/NameField';
import NameFieldDetails from '../../../components/ui/NameFieldDetails';
import Checkbox from '../../../components/ui/Checkbox';
import ListFilter from '../../../components/ui/ListFilter';
import listStyles from '../../../components/ui/ListWithFolders/list.module.scss';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import SearchResult from '../../../components/ui/SearchResult';
import TableCheckbox from '../../../components/base/MainTable/Checkbox';
import useSetSearchParams from '../../../utils/useSetSearchParams';
import useAddToFolder from '../../../utils/useAddToFolder';

const SegmentsTable = ({
  data, count, refetch, field, page, desc,
  limit, isLoading, search, manageFolders, itemType,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentFolder = useSelector((state) => getCurrentFolder(state, 'audiences'));
  const selection = useSelector((state) => getSelectedItems(state, 'audiences'));
  const setParams = useSetSearchParams();

  const [deletedItem, setDeletedItem] = useState();
  const [copyItem, setCopyItem] = useState(null);
  const [snapshotItem, setSnapshotItem] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [deleteTeamSegment, result] = audiencesApi.useDeleteSegmentMutation();
  const [deleteCustomList, deleteCustomListResult] = audiencesApi.useDeleteCustomListMutation();
  const [copySegments, copyResult] = audiencesApi.useCopySegmentsMutation();
  const [snapshotSegments, snapshotResult] = audiencesApi.useSnapshotSegmentsMutation();
  const [allChecked, setAllChecked] = useState(false);

  const addItemToFolder = useAddToFolder(
    {
      onSuccess: () => {
        emitter.emit('refetch_audiences');
      },
      onError: () => {
        showErrorMessage('The audience was copied, but could not be added to your folder');
      },
    },
  );

  const debounceValue = useMemo(() => debounce((val) => {
    setParams({ search: val, page: 1 });
  }, 0), [setParams]);

  const handleSearch = (val) => {
    debounceValue(val);
  };

  const handleNameLength = (name) => (name.length > 30 ? `${name.slice(0, 30)}...` : name);

  useEffect(() => {
    if (data) {
      setAllChecked(data.length === selection.length);
    }
  }, [data, selection]);

  const checkAllSegments = (checked) => {
    let tempSelection = [];
    if (checked) {
      tempSelection = data.map((item) => (
        {
          id: item.id,
          type: item.item_type === 'segment' ? 'audiences' : 'custom_lists',
        }
      ));
    }
    dispatch(setSelectedItems({ type: 'audiences', value: tempSelection }));
  };

  const header = [
    {
      id: 0,
      title: (
        <div
          className={listStyles.checkbox_title}
        >
          <Checkbox
            checked={allChecked}
            onChange={(checked) => checkAllSegments(checked)}
          />
        </div>
      ),
      width: 48,
    },
    {
      id: 1,
      field: 'name',
      title: 'Name',
    },
    {
      id: 7,
      title: 'Type',
    },
    {
      id: 2,
      field: 'population',
      title: 'Audience Size',
    },
    {
      id: 3,
      field: 'created_at',
      title: 'Date of Creation',
    },
    {
      id: 5,
      title: (
        <div className="d-flex justify-content-center">
          Action
        </div>
      ),
    },
  ];

  const handleManageFolders = (item) => {
    let curItemType = '';
    if (item.item_type === 'segment') {
      curItemType = 'audiences';
    } else if (item.item_type === 'custom list') {
      curItemType = 'custom_lists';
    }

    if (manageFolders) {
      manageFolders(item.id, curItemType);
    }
  };

  useEffect(() => {
    if (copyResult.status === 'fulfilled') {
      const newId = copyResult.data.id;
      if (currentFolder.id !== 'all' && currentFolder.id !== 'my') {
        addItemToFolder(
          {
            itemId: newId,
            itemType: 'audiences',
            folderId: currentFolder.id,
            folderType: 'audiences',
          },
        );
      }
    }
  }, [copyResult]);

  const getAudienceLink = (audience) => {
    if (audience.is_restricted || !audience.ready) {
      return '#';
    } if (audience.item_type === 'segment') {
      return `/segment/${audience.id}`;
    }
    return `/custom-list/${audience.id}`;
  };

  const getAudienceTooltip = (audience) => {
    if (audience.is_restricted) {
      return 'This segment cannot be viewed because the dataset, that it used, was deleted or edited';
    } if (!audience.ready) {
      return 'This segment is still in processing';
    }
    return audience.name;
  };

  useEffect(() => {
    if (data) {
      const temp = data.map((elem) => ({
        checked: (
          <div
            className={listStyles.checkbox_title}
          >
            <TableCheckbox
              id={elem.id}
              type="audiences"
              itemType={elem.item_type === 'segment' ? 'audiences' : 'custom_lists'}
            />
          </div>
        ),
        name: (
          <div className={listStyles.name_field_wrapper}>
            <NameField
              id={elem.id}
              text={elem.name}
              search={search}
              link={getAudienceLink(elem)}
              tooltip={getAudienceTooltip(elem)}
              isReady={!elem.is_restricted && elem.ready}
            />
            <NameFieldDetails
              creator={elem.creator}
              updatedAt={elem.updated_at
                ? `${formatDistanceToNowStrict(new Date(elem.updated_at), { addSuffix: true })}`
                : '?'}
            />
          </div>
        ),
        item_type: (
          <span>{elem.item_type === 'segment' ? 'Segment' : 'List'}</span>
        ),
        population: elem.population !== null ? convertNumber(elem.population) : '?',
        created_at: <span className={listStyles.created_at_field}>{elem.created_at.split('T')[0]}</span>,
        ready: elem.ready,
        button: (
          <div className={`ms-auto ${tableStyles.popover_wrapper} text-center position-relative cursor-pointer`}>
            <img
              src={Settings}
              className="cursor-pointer"
              height="25"
              width="25"
              alt="settings"
            />
            <div className={`${tableStyles.invisible_dropdown} position-absolute top-0 right-0`}>
              <div className={`${tableStyles.popover_content} position-absolute`}>
                {elem.is_restricted ? (
                  <>
                    <div
                      className={`
                    d-flex
                    justify-content-start
                    align-items-center
                    gap-1
                    ${tableStyles.modal_row}`}
                      role="presentation"
                      data-for={`edit_info_${elem.id}`}
                      data-tip
                    >
                      <Edit />
                      Edit
                    </div>
                    <Tooltip
                      fixWidth
                      id={`edit_info_${elem.id}`}
                      info="This segment cannot be edited because the dataset, that it used, was deleted or edited."
                    />
                  </>
                ) : (
                  elem?.ready ? (
                    <div
                      className={`
                      d-flex
                      justify-content-start
                      align-items-center
                      gap-1
                      ${tableStyles.modal_row}`}
                      onClick={() => (elem.item_type === 'segment'
                        ? navigate(`/add-segment/${elem.id}?edit=true`)
                        : navigate(`/add-custom-list/${elem.id}`))}
                      role="presentation"
                    >
                      <Edit />
                      Edit
                    </div>
                  ) : (
                    <>
                      <div
                        className={`
                      d-flex
                      justify-content-start
                      align-items-center
                      gap-1
                      ${tableStyles.modal_row}`}
                        role="presentation"
                        data-for={`edit_info_${elem.id}`}
                        data-tip
                      >
                        <Edit />
                        Edit
                      </div>
                      <Tooltip
                        fixWidth
                        id={`edit_info_${elem.id}`}
                        info="This segment cannot be edited because it is in processing."
                      />
                    </>
                  )
                )}
                {elem.item_type === 'segment'
                  ? elem.is_restricted ? (
                    <>
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        data-for={`copy_info_${elem.id}`}
                        data-tip
                      >
                        <Copy />
                        Copy
                      </div>
                      <div
                        data-for={`copy_info_${elem.id}`}
                        data-tip
                      >
                        <SnapshotElem
                          item={elem}
                          setSnapshotItem={setSnapshotItem}
                          copyResult={copyResult}
                          disabled
                        />
                      </div>
                      <Tooltip
                        fixWidth
                        id={`copy_info_${elem.id}`}
                        info="This segment cannot be copied because the dataset, that it used, was deleted or edited."
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        onClick={() => setCopyItem({ name: elem.name, id: elem.id })}
                        role="presentation"
                      >
                        <Copy />
                        Copy
                      </div>
                      {elem?.ready ? (
                        <SnapshotElem
                          item={elem}
                          setSnapshotItem={setSnapshotItem}
                          copyResult={copyResult}
                        />
                      ) : (
                        <>
                          <div
                            data-for={`copy_info_${elem.id}`}
                            data-tip
                          >
                            <SnapshotElem
                              item={elem}
                              setSnapshotItem={setSnapshotItem}
                              copyResult={copyResult}
                              disabled
                            />
                          </div>
                          <Tooltip
                            fixWidth
                            id={`copy_info_${elem.id}`}
                            info="This segment cannot be snapshoted because it is in processing."
                          />
                        </>
                      )}
                    </>
                  ) : null}
                <div
                  className={`
                        d-flex
                        justify-content-start
                        align-items-center
                        gap-1
                        text-truncate
                        ${tableStyles.modal_row}`}
                  onClick={() => handleManageFolders(elem)}
                  role="presentation"
                >
                  <ManageFolder color="#90A0B7" />
                  Manage folders
                </div>
                {elem.IsInRunning
                  ? (
                    <>
                      <Tooltip
                        fixWidth
                        id={`delete_info_${elem.id}`}
                        info={
                          `${elem.item_type !== 'custom list' ? 'Segment' : 'List'}
                           that is used in the running or stopped flow can not be deleted.`
                        }
                      />
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        data-for={`delete_info_${elem.id}`}
                        data-tip
                      >
                        <DisabledTrash />
                        Delete
                      </div>
                    </>
                  ) : (
                    <div
                      className={`
                        d-flex
                        justify-content-start
                        align-items-center
                        gap-1
                        ${tableStyles.modal_row}`}
                      onClick={elem.item_type === 'segment'
                        ? () => setDeletedItem({ name: elem.name, id: elem.id, isSegment: true })
                        : () => setDeletedItem({ name: elem.name, id: elem.id, isSegment: false })}
                      role="presentation"
                    >
                      <Trash />
                      Delete
                    </div>
                  )}
              </div>
            </div>
          </div>
        ),
      }));
      setTableData(temp);
    }
  }, [data, copyResult]);

  const setFilter = (id) => {
    switch (id) {
      case 'segments':
        setParams({ filter: 'segment', page: 1 });
        break;
      case 'lists':
        setParams({ filter: 'custom list', page: 1 });
        break;
      case 'all':
      default:
        setParams({ filter: '', page: 1 });
        break;
    }
  };

  useEffect(() => {
    if (copyResult.isSuccess) {
      showSuccessMessage('Segment was successfully copied');
      dispatch(createdSegment(copyResult?.data?.id));
      if (copyResult.isSuccess) {
        setTimeout(() => {
          dispatch(createdSegment(null));
        }, 10000);
      }
      setCopyItem(null);
      refetch();
      copyResult.reset();
    }
    if (copyResult.isError) {
      showErrorMessage(Object.keys(copyResult.error.data).length !== 0 && copyResult.error.data.detail);
      copyResult.reset();
    }
    return () => clearTimeout();
  }, [copyResult, dispatch, refetch]);

  useEffect(() => {
    if (result.isSuccess) {
      showSuccessMessage('Segment was successfully deleted');
      setDeletedItem(null);
      if (tableData?.length === 1 && page > 1) {
        setParams({ page: page - 1 });
      } else {
        refetch();
      }
      result.reset();
    }
    if (result.isError) {
      if (result.error.status === 400) {
        const myModal = new Bootstrap.Modal(document.getElementById('errorModal'));
        myModal.toggle();
        setDeletedItem({ ...deletedItem, isError: true });
      } else {
        showErrorMessage(Object.keys(result.error.data).length !== 0 && result.error.data.message);
      }
      result.reset();
    }
  }, [page, refetch, result, tableData?.length]);

  useEffect(() => {
    if (snapshotResult.isSuccess) {
      showSuccessMessage('Segment was successfully saved as List');
      dispatch(createdCustomList(snapshotResult?.data?.id));
      setTimeout(() => {
        dispatch(createdCustomList(null));
      }, 10000);
      setSnapshotItem(null);
      snapshotResult.reset();
      refetch();
    }
    if (snapshotResult.isError) {
      showErrorMessage(snapshotResult?.error?.data?.detail || 'Something went wrong');
      setSnapshotItem(null);
      snapshotResult.reset();
    }
  }, [dispatch, refetch, snapshotResult]);

  useEffect(() => {
    if (deleteCustomListResult.isSuccess) {
      showSuccessMessage('List was successfully deleted');
      setDeletedItem(null);
      if (tableData?.length === 1 && page > 1) {
        setParams({ page: page - 1 });
      } else {
        refetch();
      }
      deleteCustomListResult.reset();
    }
    if (deleteCustomListResult.isError) {
      if (deleteCustomListResult.error.status === 400) {
        const myModal = new Bootstrap.Modal(document.getElementById('errorModal'));
        myModal.toggle();
        setDeletedItem({ ...deletedItem, isError: true });
      } else {
        showErrorMessage(Object.keys(deleteCustomListResult.error.data).length !== 0
          && deleteCustomListResult.error.data.message);
      }
      deleteCustomListResult.reset();
    }
  }, [deleteCustomListResult, page, refetch, tableData?.length]);

  useEffect(() => () => clearTimeout());

  return (
    <div className={listStyles.wrapper}>
      {deletedItem && !deletedItem?.isError && (
        <ConfirmModal
          title="Confirm delete"
          description={`Are you sure you want to delete “${handleNameLength(deletedItem?.name)}”?`}
          buttonName="Delete"
          onSubmit={() => (deletedItem?.isSegment
            ? (deleteTeamSegment(deletedItem?.id))
            : deleteCustomList(deletedItem?.id)
          )}
          onCancel={() => setDeletedItem(null)}
          loading={result.isLoading || result.isFetching
            || deleteCustomListResult.isLoading || deleteCustomListResult.isFetching}
        />
      )}
      {copyItem && (
        <ConfirmModal
          title="Confirm copy"
          description={`Are you sure you want to copy the
           “${handleNameLength(copyItem.name)}” segment with its configurations?`}
          buttonName="Copy"
          onSubmit={() => copySegments(copyItem.id)}
          onCancel={() => setCopyItem(null)}
          loading={copyResult.isLoading || copyResult.isFetching}
        />
      )}
      {snapshotItem && (
        <ConfirmModal
          title="Confirm action"
          description={`Are you sure you want to take a snapshot of
           “${handleNameLength(snapshotItem.name)}” segment and save it as List?`}
          buttonName="Take a snapshot"
          onSubmit={() => snapshotSegments({ id: snapshotItem.id })}
          onCancel={() => setSnapshotItem(null)}
          loading={snapshotResult.isLoading || snapshotResult.isFetching}
        />
      )}
      {deletedItem
        ? (
          <ErrorModal
            message={`
            The deletion can’t be completed because the ${deletedItem?.isSegment
            ? 'segment' : 'list'} "${deletedItem.name}"
            is in use in running or stopped flow
            `}
            title={`Cannot delete ${deletedItem?.isSegment ? 'segment' : 'list'}`}
          />
        )
        : null}
      <TableHeader
        count={count}
        page={page}
        limit={limit}
        searchValue={search}
        handleSearch={handleSearch}
        type="audiences"
      >
        <button
          className={`outline-blue-button ${listStyles.header_button}`}
          type="button"
          onClick={() => {
            navigate('/csv-upload');
          }}
        >
          Import Profiles
        </button>
        <button
          className={`regular-button ${listStyles.header_button}`}
          type="button"
          onClick={() => {
            navigate(
              '/add-segment',
              currentFolder !== 'all' && currentFolder !== 'my'
                ? { state: { folder: Number(currentFolder.id) } }
                : { state: null },
            );
          }}
        >
          Create a Segment
        </button>
      </TableHeader>

      <ListFilter
        filters={[
          {
            id: 'all',
            label: 'All',
            icon: <AllIcon color="#323C47" />,
            selected: itemType === '',
          },
          {
            id: 'lists',
            label: 'Lists',
            icon: <CustomListsIcon color="#323C47" />,
            selected: itemType === 'custom list',
          },
          {
            id: 'segments',
            label: 'Segments',
            icon: <SegmentsIcon color="#323C47" />,
            selected: itemType === 'segment',
          },
        ]}
        setFilter={setFilter}
      />

      <MainTable
        total={count}
        data={tableData}
        desc={desc}
        ordering={field}
        page={page}
        limit={limit}
        headers={header}
        isLoading={isLoading}
        nothingComponent={<SearchResult type="audience" search={search} />}
      />
    </div>
  );
};

export default SegmentsTable;
