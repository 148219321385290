import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as Bootstrap from 'bootstrap';
import { userApi } from '../../api/user';
import Loader from '../../components/base/Loader';
import KeyLoadingModal from './KeyLoadingModal';
import ConfirmModal from '../../components/ui/modals/ConfirmModal';
import Spinner from '../../components/base/Spinner';
import { showErrorMessage, showSuccessMessage } from '../../components/base/Notifications';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit-icon.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { regExpSenderEmail } from '../../utils/regExp';
import styles from './Settings.module.scss';
import XMTPSettings from './XMTPSettings';
import eyeOff from '../../assets/icons/eyeOff.svg';

const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [onReset, setOnReset] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSavedSenderEmail, setIsSavedSenderEmail] = useState(false);
  const [isEditSenderEmail, setIsEditSenderEmail] = useState(false);
  const [isDeleteSenderEmail, setIsDeleteSenderEmail] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [keyData, setKeyData] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleSG, setVisibleSG] = useState(false);
  const inputRef = useRef(null);

  const {
    register, setValue, watch, setFocus,
  } = useForm();

  const [updateApiKey, result] = userApi.usePatchApiKeyMutation();
  const [sendGridApiKey, sendGridApiKeyResult] = userApi.useActionsSendGridApiKeyMutation();
  const [deleteSendGridApiKey, deleteGridApiKeyResult] = userApi.useDeleteSendGridApiKeyMutation();

  const [senderEmail, senderEmailResult] = userApi.useActionsSenderEmailMutation();
  const [deleteSenderEmail, deleteSenderEmailResult] = userApi.useDeleteSenderEmailMutation();

  const {
    data, isLoading, isFetching, refetch,
  } = userApi.useGetApiKeyQuery();

  const {
    data: infoSendGridApiKey,
    isLoading: isInfoSendGridApiKeyLoading,
    isFetching: isInfoSendGridApiKeyFetching,
  } = userApi.useGetSendGridApiKeyQuery();

  const {
    data: getSenderEmail,
    isLoading: isSenderEmailLoading,
    isFetching: isSenderEmailFetching,
  } = userApi.useGetSenderEmailQuery();

  const checkOnValidateEmail = (email) => {
    if (regExpSenderEmail.test(email)) {
      setIsInvalidEmail(false);
      senderEmail({
        sender_email: watch('sender_email'),
      });
    } else {
      setIsInvalidEmail(true);
      setTimeout(() => {
        setIsInvalidEmail(false);
      }, 2000);
    }
  };

  useEffect(() => () => clearTimeout());

  useEffect(() => {
    if (watch('key') && !isSaved) {
      setFocus('key');
    }
    if (watch('sender_email') && !isSavedSenderEmail) {
      setFocus('sender_email');
    }
  }, [isSaved, isSavedSenderEmail, setFocus, watch]);

  useEffect(() => {
    if (data) {
      setKeyData(data);
    }
  }, [data]);

  useEffect(() => {
    if (infoSendGridApiKey?.sg_api_key) {
      setValue('key', infoSendGridApiKey.sg_api_key);
      setIsSaved(true);
    }
  }, [infoSendGridApiKey?.sg_api_key, setValue]);

  useEffect(() => {
    if (getSenderEmail?.sender_email) {
      setValue('sender_email', getSenderEmail.sender_email);
      setIsSavedSenderEmail(true);
    }
  }, [getSenderEmail?.sender_email, infoSendGridApiKey, setValue]);

  useEffect(() => {
    if (sendGridApiKeyResult.isSuccess) {
      showSuccessMessage(sendGridApiKeyResult.data.message);
      setValue('key', sendGridApiKeyResult.originalArgs.api_key);
      setIsSaved(true);
      sendGridApiKeyResult.reset();
    }
    if (sendGridApiKeyResult.isError) {
      showErrorMessage(sendGridApiKeyResult.error.data.detail || 'Something went wrong');
      sendGridApiKeyResult.reset();
    }
  }, [sendGridApiKeyResult, setValue]);

  useEffect(() => {
    if (senderEmailResult.isSuccess) {
      showSuccessMessage(senderEmailResult.data.message);
      setValue('sender_email', senderEmailResult.originalArgs.sender_email);
      setIsSavedSenderEmail(true);
      senderEmailResult.reset();
    }
    if (senderEmailResult.isError) {
      showErrorMessage(senderEmailResult.error.data.detail || 'Something went wrong');
      senderEmailResult.reset();
    }
  }, [senderEmailResult, setValue]);

  useEffect(() => {
    if (deleteGridApiKeyResult.isSuccess) {
      showSuccessMessage(deleteGridApiKeyResult?.data?.message || 'SendGrid API Key was deleted successfully');
      setIsDelete(false);
      setIsSaved(false);
      setValue('key', '');
      deleteGridApiKeyResult.reset();
    }
    if (deleteGridApiKeyResult.isError) {
      if (deleteGridApiKeyResult.error.status === 400) {
        const myModal = new Bootstrap.Modal(document.getElementById('errorModal'));
        myModal.toggle();
      } else {
        showErrorMessage(deleteGridApiKeyResult.error.data.detail || 'Something went wrong');
      }
      deleteGridApiKeyResult.reset();
    }
  }, [deleteGridApiKeyResult, setValue]);

  useEffect(() => {
    if (deleteSenderEmailResult.isSuccess) {
      showSuccessMessage(deleteSenderEmailResult?.data?.message || 'Sender Email was deleted successfully');
      setIsDeleteSenderEmail(false);
      setIsSavedSenderEmail(false);
      setValue('sender_email', '');
      deleteSenderEmailResult.reset();
    }
    if (deleteSenderEmailResult.isError) {
      if (deleteSenderEmailResult.error.status === 400) {
        const myModal = new Bootstrap.Modal(document.getElementById('errorModal'));
        myModal.toggle();
      } else {
        showErrorMessage(deleteSenderEmailResult.error.data.detail || 'Something went wrong');
      }
      deleteSenderEmailResult.reset();
    }
  }, [deleteSenderEmailResult, setValue]);

  useEffect(() => {
    if (result.isSuccess && result.data?.api_key) {
      setTimeout(() => {
        setIsOpen(false);
        setKeyData(result.data);
        result.reset();
      }, 2000);
    }
    return () => clearTimeout();
  }, [refetch, result]);

  return (
    <>
      <div className={`${styles.title} mb-4`}>
        My team settings
      </div>
      <div className={styles.wrapper}>
        {isOpen && <KeyLoadingModal result={result} setIsOpen={setIsOpen} />}
        {onReset && (
          <ConfirmModal
            title="Reset API Key"
            description="Resetting your API key might break active integrations using the active one"
            buttonName="Reset Key"
            onSubmit={() => {
              updateApiKey();
              setOnReset(false);
              setIsOpen(true);
            }}
            onCancel={() => setOnReset(false)}
          />
        )}
        {(isEdit || isEditSenderEmail) && (
          <ConfirmModal
            title={isEdit ? 'Edit API Key' : 'Edit Sender Email'}
            description={isEdit
              ? 'Editing your API key might break active integrations using the active one'
              : 'Editing the sender email might break the running flow using the current one'}
            buttonName={isEdit ? 'Edit Key' : 'Edit'}
            onSubmit={isEdit
              ? () => {
                setIsSaved(false);
                setIsEdit(false);
                setVisibleSG(true);
              } : () => {
                setIsEditSenderEmail(false);
                setIsSavedSenderEmail(false);
              }}
            onCancel={() => (isEdit ? setIsEdit(false) : setIsEditSenderEmail(false))}
          />
        )}
        {(isDelete || isDeleteSenderEmail) && (
          <ConfirmModal
            title={isDelete ? 'Delete API Key' : 'Delete Sender Email'}
            description={isDelete
              ? 'Deleting your API key might break active integrations using the active one'
              : 'Deleting the sender email might break the running flow using the current one'}
            buttonName={isDelete ? 'Delete Key' : 'Delete'}
            onSubmit={() => (isDelete ? deleteSendGridApiKey() : deleteSenderEmail())}
            onCancel={() => (isDelete ? setIsDelete(false) : setIsDeleteSenderEmail(false))}
            loading={deleteGridApiKeyResult.isLoading || deleteGridApiKeyResult.isFetching
              || deleteSenderEmailResult.isLoading || deleteSenderEmailResult.isFetching}
          />
        )}
        <div className={styles.header}>
          API Keys
        </div>
        <div className={`${styles.labs_section} d-flex flex-column`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-1">
              <span>
                Absolute Labs API Key
              </span>
              <span className={styles.key_info}>
                This API Key is required to interact with the platform APIs, such as Flow web hooks.
              </span>
            </div>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="regular-button"
                onClick={() => {
                  if (keyData?.api_key) {
                    setOnReset(true);
                  } else {
                    updateApiKey();
                    setIsOpen(true);
                  }
                }}
              >
                {keyData?.api_key ? 'Reset API Key' : 'Generate API key'}
              </button>
            </div>
          </div>
          {isLoading || isFetching
            ? (
              <div className="mt-5">
                <Loader />
              </div>
            )
            : !keyData?.api_key
              ? (
                <div className={styles.no_key}>
                  You don’t have any API key
                </div>
              )
              : (
                <div className={styles.key}>
                  <div className="d-flex align-items-center gap-3">
                    {visible ? (
                      <div
                        className={styles.apiKey}
                        role="presentation"
                        onClick={() => setVisible(false)}
                      >
                        {keyData.api_key}
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center gap-3 position-relative cursor-pointer"
                        role="presentation"
                        onClick={() => setVisible(true)}
                      >
                        <div
                          className={`${styles.overlayContainer} position-absolute d-flex align-items-center
                          start-0 end-0 justify-content-center`}
                        >
                          <div className="d-flex gap-3">
                            <img
                              className={`${styles.eyeOff} align-items-center`}
                              src={eyeOff}
                              height="18px"
                              alt="Hide eye"
                            />
                            <span className={styles.overlayText}>
                              Click to reveal API Key
                            </span>
                          </div>
                        </div>
                        {keyData.api_key}
                      </div>
                    )}
                    <Copy
                      className="cursor-pointer"
                      width="24px"
                      height="24px"
                      onClick={() => {
                        navigator.clipboard.writeText(keyData.api_key);
                        showSuccessMessage('The API key was copied to your clipboard');
                      }}
                    />
                  </div>
                  <div className={styles.date}>
                    {`Creation date: ${keyData.created_at.slice(0, 10).split('-').reverse().join('/')}`}
                  </div>
                </div>
              )}
        </div>
      </div>
      <XMTPSettings
        XMTPkey={data?.xmtp_key}
        walletAddress={data?.wallet_address}
        isLoading={isLoading || isFetching}
      />
      <div className={`${styles.wrapper} mt-4`}>
        <div className={`${styles.header} d-flex flex-column`}>
          Email
          <span className={`${styles.info} mt-1`}>
            To send e-mail messages through Flows, Absolute Labs requires setting up a SendGrid API connection.
            <span
              role="presentation"
              onClick={() => window.open('https://docs.absolutelabs.io/features/flows/activation/e-mail', '_blank')}
              className={`${styles.learn} cursor-pointer px-1`}
            >
              Learn more
            </span>
          </span>
        </div>
        <div className={`${styles.sendGrid_section} d-flex flex-column`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-1">
              <span>
                SendGrid API Key
              </span>
              <span className={styles.key_info}>
                Sending e-mails through Flows requires a SendGrid account,
                for which the API Key should be provided here.
              </span>
            </div>
            <div className="d-flex align-items-center">
              {isSaved ? (
                <div className="d-flex gap-2">
                  <button
                    type="button"
                    className={`outline-button ${styles.buttons} shadow-none d-flex align-items-center`}
                    onClick={() => setIsEdit(true)}
                  >
                    <div className="d-flex align-items-center gap-1 mx-3">
                      <Edit className="cursor-pointer" />
                      <div>Edit</div>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`outline-button ${styles.buttons} shadow-none`}
                    onClick={() => setIsDelete(true)}
                    height="40px"
                  >
                    <div className="d-flex align-items-center gap-1 mx-3">
                      <Trash className="cursor-pointer" />
                      <div>Delete</div>
                    </div>
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="regular-button just"
                  onClick={() => {
                    sendGridApiKey({
                      api_key: watch('key'),
                    });
                  }}
                  disabled={!watch('key') || isEdit}
                >
                  {
                    sendGridApiKeyResult.isLoading || sendGridApiKeyResult.isFetching
                      ? (
                        <div className="d-flex align-items-center mx-2">
                          <Spinner />
                        </div>
                      ) : 'Save'
                  }
                </button>
              )}
            </div>
          </div>
          {isInfoSendGridApiKeyLoading || isInfoSendGridApiKeyFetching
            ? (
              <div className="mt-5">
                <Loader />
              </div>
            ) : (
              <div className={styles.sg}>
                {visibleSG ? (
                  <div
                    className={`${styles.input_area} d-flex align-items-center`}
                    role="presentation"
                  >
                    {isSaved
                      ? (
                        <div className={`${styles.sg_key} d-flex align-items-center gap-3 w-100`}>
                          <div
                            className="cursor-pointer text-truncate"
                            role="presentation"
                            onClick={() => setVisibleSG(false)}
                          >
                            {watch('key')}
                          </div>
                          <Copy
                            className={`cursor-pointer ${styles.svg_sg_key}`}
                            width="24px"
                            height="24px"
                            onClick={() => {
                              navigator.clipboard.writeText(watch('key'));
                              showSuccessMessage('The SendGrid API key was copied to your clipboard');
                            }}
                          />
                        </div>
                      )
                      : (
                        <input
                          {...register('key')}
                          placeholder="Add SendGrid API Key"
                          onChange={(e) => setValue('key', e.target.value)}
                          type="text"
                          className="w-100"
                          maxLength={255}
                        />
                      )}
                  </div>
                ) : (
                  <div
                    className={`${styles.input_area} d-flex align-items-center position-relative w-100`}
                    role="presentation"
                  >
                    {watch('key') && isSaved
                      ? (
                        <>
                          <div
                            role="presentation"
                            className={`${styles.sg_hide} position-relative cursor-pointer text-truncate`}
                            onClick={() => setVisibleSG(true)}
                          >
                            {watch('key')}
                            <span
                              className={`${styles.overlayContainer2} position-absolute d-flex align-items-center
                                start-0 top-0 justify-content-center px-3 w-100 h-100`}
                            >
                              <div className="d-flex gap-3">
                                <img
                                  className={`${styles.eyeOff} align-items-center`}
                                  src={eyeOff}
                                  height="18px"
                                  alt="Hide eye"
                                />
                                <span className={styles.overlayText}>
                                  Click to reveal SendGrid API Key
                                </span>
                              </div>
                            </span>
                          </div>
                          <Copy
                            className={`cursor-pointer ms-3 ${styles.svg_sg_key}`}
                            width="24px"
                            height="24px"
                            onClick={() => {
                              navigator.clipboard.writeText(watch('key'));
                              showSuccessMessage('The SendGrid API key was copied to your clipboard');
                            }}
                          />
                        </>
                      )
                      : (
                        <input
                          {...register('key')}
                          placeholder="Add SendGrid API Key"
                          onChange={(e) => setValue('key', e.target.value)}
                          type="text"
                          className="w-100"
                          disabled={isSaved}
                          ref={inputRef}
                        />
                      )}
                  </div>
                )}
              </div>
            )}
        </div>
        <div className={styles.divider} />
        <div className={`${styles.sendGrid_section} d-flex flex-column`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-1">
              <span>
                Sender Email
              </span>
              <span className={styles.key_info}>
                For better delivery, use an email verified on SendGrid.
                To have a custom sender name, use the format &quot;Your Name &lt;email@domain.com&gt;&quot;.
              </span>
            </div>
            <div className="d-flex align-items-center">
              {isSavedSenderEmail ? (
                <div className="d-flex gap-2">
                  <button
                    type="button"
                    className={`outline-button ${styles.buttons} shadow-none d-flex align-items-center`}
                    onClick={() => setIsEditSenderEmail(true)}
                  >
                    <div className="d-flex align-items-center gap-1 mx-3">
                      <Edit className="cursor-pointer" />
                      <div>Edit</div>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`outline-button ${styles.buttons} shadow-none`}
                    onClick={() => setIsDeleteSenderEmail(true)}
                    height="40px"
                  >
                    <div className="d-flex align-items-center gap-1 mx-3">
                      <Trash className="cursor-pointer" />
                      <div>Delete</div>
                    </div>
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="regular-button just"
                  onClick={() => (checkOnValidateEmail(watch('sender_email')))}
                  disabled={!watch('sender_email') || isEditSenderEmail}
                >
                  {
                    senderEmailResult.isLoading || senderEmailResult.isFetching
                      ? (
                        <div className="d-flex align-items-center mx-2">
                          <Spinner />
                        </div>
                      ) : 'Save'
                  }
                </button>
              )}
            </div>
          </div>
          {isSenderEmailLoading || isSenderEmailFetching
            ? (
              <div className="mt-5">
                <Loader />
              </div>
            ) : (
              <div className={`${watch('sender_email') && isSavedSenderEmail ? '' : `${styles.sg}`}
               ${isInvalidEmail ? styles.invalid : ''} ${styles.input_area} d-flex align-items-center mt-4`}
              >
                <input
                  {...register('sender_email')}
                  placeholder="Add the Sender Email"
                  onChange={(e) => setValue('sender_email', e.target.value)}
                  type="text"
                  className={`${watch('sender_email') && isSavedSenderEmail ? 'py-3' : ''} w-100`}
                  disabled={isSavedSenderEmail}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default Settings;
