import React, { memo } from 'react';
import {
  ComposableMap, Geographies, Geography, ZoomableGroup,
} from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { geoPatterson } from 'd3-geo-projection';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import geoJSON from '../../UsersTab/UserTabHeatMap/features.json';

const HeatMap = ({
  setTooltipContent, maxValue, countriesData, total,
}) => {
  const colorScale = scaleLinear().domain([0.0001, 1]).range(['#CFECFFFF', '#109CF1']);

  const projection = geoPatterson().translate([780 / 2, 550 / 2]).scale(130);

  return (
    <div data-tip="">
      <ComposableMap projection={projection}>
        <ZoomableGroup center={[0, 0]}>
          <Geographies geography={geoJSON}>
            {({ geographies }) => geographies.map((geo) => {
              const d = countriesData.find((s) => geo.id.includes(s.value));
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d && ethFormat((d.visitor_count / total)) > 0.0001
                    ? colorScale(d.visitor_count / maxValue)
                    : '#FFFFFF'}
                  stroke="#DBDBDB"
                  strokeWidth={0.1}
                  onMouseEnter={() => (d
                    ? setTooltipContent(`
                      ${geo.properties.name}: 
                      ${ethFormat(d.visitor_count)} (${ethFormat((d.visitor_count / total) * 100)}%)`)
                    : setTooltipContent(''))}
                  onMouseLeave={() => setTooltipContent('')}
                  style={{
                    default: {
                      boxShadow: '4px 4px 4px 4px',
                    },
                  }}
                />
              );
            })}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default memo(HeatMap);
