import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { showSuccessMessage } from '../../../base/Notifications';
import { ReactComponent as Copy } from '../../../../assets/icons/copy_outline.svg';
import { formsApi } from '../../../../api/forms';
import EditableFoldersList from '../../EditableFoldersList';
import styles from '../TitleSection.module.scss';

const SingleFormTitle = ({ isFormLoading, formData, onShareClick }) => {
  const [data, setData] = useState({});
  const [url, setUrl] = useState('https://dev.wrm.link/');

  const [pauseForm, { isLoading: isPausing }] = formsApi.useCloseFormMutation();
  const [continueForm, { isLoading: isContinuing }] = formsApi.useContinueFormMutation();

  useEffect(() => {
    if (isFormLoading) {
      setData({});
    }
    if (window.location.hostname === 'dev.absolutelabs.app') {
      setUrl('https://dev.wrm.link/');
    } else if (window.location.hostname === 'localhost') {
      setUrl('http://localhost:4000/');
    } else if (window.location.hostname === 'staging.absolutelabs.app') {
      setUrl('https://staging.wrm.link/');
    } else {
      setUrl('https://wrm.link/');
    }
    if (formData) {
      setData(formData);
    }
  }, [isFormLoading, formData]);

  const changeStatus = () => {
    if (data.status === 'Active') {
      pauseForm(data.id);
      setData({ ...data, status: 'Closed' });
    } else {
      continueForm(data.id);
      setData({ ...data, status: 'Active' });
    }
  };

  const fullUrl = url + data.url;

  return (
    <div className={styles.formWrapper}>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-wrap gap-2">
            <div className="d-flex flex-column gap-2">
              <div className={`${styles.title} d-flex gap-2 align-items-center`}>
                {data.name}
                <div className={`
                  ${styles.active_pill}
                  ${styles[`${data?.status?.toLowerCase()}_pill`]}
                  px-3 d-flex align-items-center`}
                >
                  {data.status === 'Completed' || data.status === 'Paused' ? 'Closed' : data.status}
                </div>
              </div>
              <EditableFoldersList itemId={data.id} itemType="forms" />
              <div className={styles.author}>
                Created by
                {' '}
                {data.creator}
                {' '}
                on
                {' '}
                {data.created_at ? format(new Date(data.created_at), 'MMM dd, yyyy') : ''}
              </div>
              { data?.url?.length > 0
                ? (
                  <div className="d-flex gap-2 align-items-center">
                    {fullUrl.includes('localhost') ? fullUrl : fullUrl.replace('https://', '')}
                    <Copy
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(fullUrl);
                        showSuccessMessage('The URL was copied to your clipboard');
                      }}
                    />
                    <button
                      type="button"
                      className={`${styles.share} regular-button text-nowrap`}
                      onClick={() => onShareClick(fullUrl)}
                    >
                      Share
                    </button>
                  </div>
                )
                : ''}
            </div>
          </div>
          <div className="d-flex gap-2">
            <button
              type="button"
              className="regular-button text-nowrap"
              disabled={isPausing || isContinuing}
              onClick={() => changeStatus()}
            >
              {data.status === 'Active' ? 'Close Form' : 'Reopen Form'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFormTitle;
