import React from 'react';
import { useNavigate } from 'react-router-dom';
import PopupMenuButton from '../../PopupMenuButton';
import { ReactComponent as CreateSegment } from '../../../../assets/icons/segment.svg';

const AddSegmentButton = ({ folderId }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    navigate('/add-segment', { state: { folder: folderId } });
  };

  return (
    <PopupMenuButton onClick={(e) => handleClick(e)}>
      <CreateSegment color="#90A0B7" />
      Create segment
    </PopupMenuButton>
  );
};

export default AddSegmentButton;
