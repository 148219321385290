import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigationType, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { profilesApi } from '../../api/profiles';
import { ethFormat } from '../../utils/singleAssetPage/parseData';
import { userApi } from '../../api/user';
import InYourAudiences from './InYourAudiences';
import Portfolio from './Portfolio';
import Assets from './Assets';
import Reach from './Reach';
import Indicators from './Indicators';
import CustomData from './CustomData';
import YourAssets from './YourAssets';
import Wallet from '../../assets/icons/wallet_logo.svg';
import SingleWalletTitle from '../../components/ui/TitleSection/Titles/SingleWalletTitle';
import Exchanges from './Exchnages';
import { selectTabPages, setTabPages } from '../../store/reducers/coin';
import DappUsage from './DappUsage';
import styles from './WalletDetails.module.scss';
import { isAddress } from '../../utils/supportedBlockchains';
import { shortenAddress } from '../../utils/shortenAddress';

const ProfileDetails = () => {
  const dispatch = useDispatch();
  const { name } = useParams();
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemPage, setItemPage] = useState(searchParams.get('tab')?.replace('+', ' ') || 'Portfolio');
  const [pages, setPages] = useState([]);
  const [titleSectionData, setTitleSectionData] = useState(null);
  const [reachData, setReachData] = useState(null);
  const [indicatorsData, setIndicatorsData] = useState(null);
  const [assetsFromDashboard, setAssetsFromDashboard] = useState({
    assets: [],
    watchlist: [],
  });

  const tabPages = useSelector(selectTabPages);

  const {
    data: wallet,
    isLoading,
    isFetching,
    isSuccess,
    refetch: walletRefetch,
  } = profilesApi.useGetWalletDataQuery(isAddress(name) ? `?address=${name}` : `?alid=${name}`);

  const {
    data: assets,
    isLoading: isAssetsLoading,
    isFetching: isAssetsFetching,
    isSuccess: isAssetsSuccess,
    isError,
    refetch,
  } = userApi.useGetAssetsQuery();

  const {
    data: investments,
    isLoading: isInvestmentsLoading,
    isFetching: isInvestmentsFetching,
  } = profilesApi.useGetWalletInvestorPortfolioDataQuery({
    address: isAddress(name)
      ? `?address=${name}`
      : `?alid=${name}&address=${wallet?.address || ''}`,
    limit: 1000000,
    order: 'desc',
    chain: 'ethereum',
  }, {
    skip: isLoading || isFetching || !wallet?.address,
  });

  const {
    data: customLists,
    isLoading: isCustomListsLoading,
    refetch: refetchCustomLists,
  } = profilesApi.useGetWalletCustomListsQuery(isAddress(name) ? `?address=${name}` : `?alid=${name}`);

  const {
    data: segments,
    isLoading: isSegmentsLoading,
    isFetching: isSegmentsFetching,
  } = profilesApi.useGetWalletSegmentsQuery(isAddress(name)
    ? `?address=${name}`
    : `?alid=${name}&address=${wallet?.address || ''}`, {
    skip: isLoading || isFetching || !wallet?.address,
  });

  useEffect(() => {
    if (isAssetsSuccess) {
      setAssetsFromDashboard({
        assets: assets.filter((item) => item.item_type === 'asset'),
        watchlist: assets.filter((item) => item.item_type === 'watchlist'),
      });
    }
    if (isError) {
      setAssetsFromDashboard({
        assets: [],
        watchlist: [],
      });
    }
  }, [assets, isAssetsSuccess, isError]);

  useEffect(() => {
    setItemPage(searchParams.get('tab')?.replace('+', ' ') || 'Portfolio');
    if (searchParams.get('orderBy') || searchParams.get('desc')) {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        orderBy: '',
        desc: '',
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (navType !== 'POP') {
      walletRefetch();
      refetch();
    }
  }, [navType, refetch, walletRefetch]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setTitleSectionData(null);
    }
    if (wallet) {
      setTitleSectionData({
        id: wallet.address || name,
        title: wallet?.address ? shortenAddress(wallet.address) : '',
        icon: wallet.profile_image_url || Wallet,
        pills: [
          wallet.mints_nfts ? 'NFT minter' : '',
          wallet.trades_nfts ? 'NFT trader' : '',
          wallet.trades_tokens ? 'Token trader' : '',
          wallet.uses_cex ? 'CEX user' : '',
          wallet.is_exchange_wallet ? 'Exchange' : '',
          wallet.is_swing_trader ? 'Swing Trader' : '',
        ],
        name: wallet.profile_name || wallet.address || '',
        ensName: wallet.resolved_ens_name || '',
        activeOn: wallet.active_on,
        twitterCount: wallet.twitter_follower_count,
        description: wallet?.marketplace_bio,
      });
      const distributionDataHandle = (value) => {
        if ((!value && value !== 0) || wallet?.total_portfolio_value?.overall === 0) return null;
        const result = ethFormat((value / wallet.total_portfolio_value.overall) * 100);
        return result > 100 ? 100 : result;
      };
      const distributionData = [
        {
          label: 'Native Tokens',
          count: wallet.total_native_token_value,
          percent: wallet.total_native_token_value !== null && wallet?.total_portfolio_value?.overall !== 0
            ? Number(((wallet.total_native_token_value / wallet.total_portfolio_value.overall) * 100).toFixed(1))
            : 0,
        },
        {
          label: 'Stable Coins',
          count: wallet.total_stable_coin_value,
          percent: wallet.total_stable_coin_value !== null && wallet?.total_portfolio_value?.overall !== 0
            ? Number(((wallet.total_stable_coin_value / wallet.total_portfolio_value.overall) * 100).toFixed(1))
            : 0,
        },
        {
          label: 'NFTs',
          count: wallet.total_nft_value,
          percent: wallet.total_nft_value !== null && wallet?.total_portfolio_value?.overall !== 0
            ? Number(((wallet.total_nft_value / wallet.total_portfolio_value.overall) * 100).toFixed(1))
            : 0,
        },
        {
          label: 'Tokens',
          count: wallet.total_token_value,
          percent: wallet.total_token_value !== null && wallet?.total_portfolio_value?.overall !== 0
            ? Number(((wallet.total_token_value / wallet.total_portfolio_value.overall) * 100).toFixed(1))
            : 0,
        },
      ];

      const processMultiChainData = (data) => Object.keys(data.active_on).filter(
        (chain) => {
          const isActive = data.active_on[chain];
          const hasValue = data.total_portfolio_value[chain] > 0;
          return isActive || hasValue;
        },
      ).map((chain) => {
        const result = {
          chain,
          first_activity: data.first_txn_timestamp[chain],
          last_activity: data.last_txn_timestamp[chain],
          transactions: data.qty_txn[chain],
          native_balance: data.native_balance[chain],
          total_value: data.total_portfolio_value[chain],
          share: data.total_portfolio_value[chain] === 0 || data.total_portfolio_value?.overall === 0
            ? 0
            : ((data.total_portfolio_value[chain] / data.total_portfolio_value.overall) * 100).toFixed(2),
        };
        return result;
      });

      let multiChainData = {};
      if (wallet?.active_on) {
        multiChainData = processMultiChainData(wallet);
      }

      setReachData({
        ensName: wallet.resolved_ens_name
          || wallet?.address ? `${wallet.address.slice(0, 6)}...${wallet.address.slice(-4)}` : '',
        airdrop: wallet?.address ? `${wallet?.address.slice(0, 6)}...${wallet?.address.slice(-4)}` : '',
        discord_id: wallet.discord,
        email: wallet.email,
        twitter_id: wallet.twitter,
        phone: wallet.phone,
        telegram_id: wallet.telegram,
        channels: wallet.number_of_channels,
        xmtp: wallet.xmtp,
      });

      setIndicatorsData({
        total_value: wallet?.total_portfolio_value?.overall >= 0
          ? `$${ethFormat(wallet.total_portfolio_value?.overall)}
          ` : '?',
        age: wallet.first_txn_timestamp?.overall,
        activity: wallet.last_txn_timestamp?.overall,
        buying_power: wallet?.buying_power >= 0
          ? `$${ethFormat(wallet.buying_power, 1)}`
          : '?',
      });

      setPages([
        {
          label: 'Portfolio',
          component: (
            <Portfolio
              assetsRefetch={refetch}
              total={wallet?.total_portfolio_value?.overall}
              distributionData={distributionData}
              multiChainData={multiChainData}
              address={wallet?.address}
            />),
          id: 1,
        },
        {
          label: 'Assets',
          component: (
            <Assets portfolioValue={wallet?.total_portfolio_value?.overall} address={wallet?.address} />),
          id: 2,
        },
        {
          label: 'Centralized Exchanges',
          component: <Exchanges address={wallet?.address} />,
          id: 3,
        },
        {
          label: 'Dapp Usage',
          component: <DappUsage address={wallet?.address} />,
          id: 4,
        },
        // {
        //   label: 'Assoc.Wallets',
        //   component: <ComingSoon />,
        //   id: 5,
        // },
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    if (pages?.length && navType !== 'POP') {
      dispatch(setTabPages(pages));
    }
  }, [dispatch, navType, pages]);

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        100,
      );
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    dispatch(setTabPages([]));
  }, [dispatch]);

  return (
    <>
      {titleSectionData && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {isAddress(titleSectionData.name)
              ? shortenAddress(titleSectionData.name)
              : titleSectionData.name || titleSectionData.title}
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <SingleWalletTitle
        isWalletLoading={isLoading || isFetching}
        singleWalletData={titleSectionData}
        address={wallet?.address}
      />
      <div className={`${styles.head} d-flex`}>
        <div className={`${styles.left_col} d-flex flex-column gap-4`}>
          <Reach data={reachData} isWalletLoading={isLoading || isFetching} setReachData={setReachData} />
          <CustomData address={name} />
        </div>
        <div className={`${styles.right_col} w-100 d-flex flex-column gap-4`}>
          <Indicators isWalletLoading={isLoading || isFetching} data={indicatorsData} />
          <InYourAudiences
            customLists={customLists}
            segments={segments}
            isCustomListLoading={isCustomListsLoading || isLoading || isFetching}
            isSegmentLoading={isSegmentsLoading || isSegmentsFetching}
            refetch={refetchCustomLists}
            isSuccess={isSuccess}
            address={name}
            walletAddress={wallet?.address}
          />
          <div className={styles.wrapper_assets}>
            <YourAssets
              isAssetsLoading={isAssetsLoading || isLoading}
              isAssetsFetching={isAssetsFetching || isFetching}
              isInvestmentsLoading={isInvestmentsLoading || isLoading}
              isInvestmentsFetching={isInvestmentsFetching || isFetching}
              assetsData={assetsFromDashboard.assets}
              investments={investments?.results}
              portfolioValue={wallet?.total_portfolio_value?.overall}
              title="Your Assets"
            />
          </div>
          <div className={styles.wrapper_assets}>
            <YourAssets
              isAssetsLoading={isAssetsLoading || isLoading}
              isAssetsFetching={isAssetsFetching || isFetching}
              isInvestmentsLoading={isInvestmentsLoading || isLoading}
              isInvestmentsFetching={isInvestmentsFetching || isFetching}
              assetsData={assetsFromDashboard.watchlist}
              investments={investments?.results}
              portfolioValue={wallet?.total_portfolio_value?.overall}
              title="Assets you Watch"
            />
          </div>
        </div>
      </div>
      <div className="row asset-section m-o mt-4">
        <div className="title-gap asset-navigation">
          <ul className="nav nav-pills gap-2">
            {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((elem) => (
              <li
                role="presentation"
                className="nav-item cursor-pointer"
                key={elem.id}
                onClick={() => {
                  setItemPage(elem.label);
                  setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    tab: elem.label,
                    scroll: '',
                  });
                }}
              >
                <Link
                  className={`nav-link ${
                    elem.label === itemPage ? 'active' : ''
                  }`}
                  to={`${pathname}${search}`}
                >
                  {elem.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown-divider w-100" />
        <div
          className={`
          ${styles.page_wrapper}
          ${itemPage === 'Assets' ? styles.assets_indents : ''}
          `}
        >
          {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (item.label === itemPage ? (
            <div key={item.id}>{item.component}</div>
          ) : null))}
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
