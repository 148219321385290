import React from 'react';
import Chart from 'react-apexcharts';
import Loader from '../../../base/Loader';
import NoData from '../../../base/NoData';
import uniqueId from '../../../../utils/uniqueId';
import styles from './PercentageBased.module.scss';
import Highlight from '../../CircleChart/Highlight';
import CardTitle from '../../CardTitle';

const HoriontalPercentBarChart = ({
  title, highlightText, highlightValue, data, isLoading, total, info, legend, growth,
}) => {
  const percentageToRender = (number) => {
    if (number < 1) {
      return '<1%';
    }
    if (number < 10) {
      return `${+number.toFixed(2)}%`;
    }
    if (number <= 99) {
      return `${+number.toFixed(1)}%`;
    }
    if (number === 100) {
      return '100%';
    }
    return '>99%';
  };

  const percentageHolders = (count, message) => {
    if (data && total) {
      const res = (count / total) * 100;
      const filterNumberLessOne = Math.max(...data
        .map((elem) => ((elem.value) / total) * 100)
        .filter((elem) => elem < 1));
      const maxValue = Math.max(
        ...data.map((item) => ((item.value) / total) * 100),
      );
      return {
        result: percentageToRender(res),
        series: [
          {
            data: [{
              x: '',
              y: res < 1
                ? Math.ceil(filterNumberLessOne)
                : res > 99
                  ? Math.ceil(maxValue)
                  : res === maxValue
                    ? Math.ceil(res) : res,
            }],
          },
        ],
        options: {
          chart: {
            width: '100%',
            toolbar: {
              show: false,
            },
          },
          colors: ['#109cf1'],
          fill: {
            opacity: 1,
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            hover: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: 'none',
                value: 0,
              },
            },
          },
          plotOptions: {
            bar: {
              expandOnClick: false,
              borderRadius: 4,
              horizontal: true,
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            followCursor: true,
            fixed: {
              position: 'topRight',
            },
            style: {
              fontSize: '12px',
              color: '#109CF1',
            },
            custom: () => (
              '<div style="padding: 8px; font-size: 12px; color: #6A707E; font-weight: 500;">'
              + '<div>'
              + `${message}`
              + '</div>'
            + '</div>'
            ),
            marker: {
              show: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: () => '',
              },
            },
          },
          xaxis: {
            labels: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            min: 0,
            max: Math.ceil(maxValue),
            labels: {
              show: false,
              style: {
                width: 0,
              },
            },
          },
        },
      };
    }
    return 0;
  };

  return (
    <div className={`${styles.wrapper} h-100`}>
      <CardTitle title={title} info={info} />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
      ) : (
        total && total !== 0 && data && data.length ? (
          <>
            <div className="d-flex w-100 flex-column h-100">
              <div className={styles.subTitle}>
                <Highlight text={highlightText} value={highlightValue} growth={growth} />
              </div>
              {data.map((item) => (
                <div
                  className={`${styles.gap} d-flex w-100 flex-column align-items-center h-100`}
                  key={uniqueId('Investments')}
                >
                  <div key={item.label} className="d-flex w-100 align-items-start gap-2">
                    {item.logo}
                    <div
                      className={`${styles.content} d-flex align-items-center justify-content-between`}
                    >
                      <div
                        className={`${styles.chart_name} d-flex`}
                        data-for={title.includes('NFT')
                        && `preview_${item.label}`}
                        data-tip="show"
                      >
                        <span
                          className={`${styles.notable_name} text-truncate cursor-pointer`}
                          role="presentation"
                        >
                          {item.name}
                        </span>
                      </div>
                      <span className={styles.notable_count}>
                        {percentageHolders(item.value).result}
                      </span>
                    </div>
                  </div>
                  <div className="w-100 d-flex position-relative align-items-start">
                    <div className={`position-absolute ${styles.chart} align-items-start`}>
                      <Chart
                        type="bar"
                        options={percentageHolders(item.value, item.message).options}
                        series={percentageHolders(item.value, item.message).series}
                        height={55}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex">
              <div className="d-flex align-items-center gap-2 asset-wrap">
                <div className="dot" />
                <span>
                  {`${legend}`}
                </span>
              </div>
            </div>
          </>
        ) : (
          <div>
            <NoData />
          </div>
        ))}
    </div>
  );
};

export default React.memo(HoriontalPercentBarChart);
