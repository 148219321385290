import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { ethFormat } from '../../../../../../utils/singleAssetPage/parseData';
import ProvideTokensModal from './ProvideTokensModal';
import { setERC20Address } from '../../../../../../store/reducers/web3';
import { getAirdropContractAddress } from '../../../../../../utils/web3/loadContract';
import { coinApi } from '../../../../../../api/coin';
import { userApi } from '../../../../../../api/user';
import McapLabel from '../../../Components/McapLabel';
import { debounce } from '../../../../../../utils/debounce';
import { isValidContractAddress } from '../../../../../../tools/EtherTool';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import { getFormattedCoinData } from '../../../../../../utils/segments/getCoinNftData';
import { getUser } from '../../../../../../store/reducers/user';
import warning from '../../../../../../assets/icons/warning_rounded.svg';
import styles from './TokenAirdrop.module.scss';
import { convertSciToDec } from '../../../../../../tools/NumberConverterTool';
import { setTeamBalanceReducer } from '../../../../../../store/reducers/flows';
import { ControlComponent, onChainInputStyles, sourceInputStyles } from '../SelectComponentStyling';
import { blockchainApi } from '../../../../../../api/blockchain';
import { blockChainOptionsWithTestnets } from '../../../../../../components/base/ChainLogo/chains';

const TokensAirdrop = ({
  selectedBlockchain,
  setSelectedBlockchain,
  setAmountToAirdrop,
  register,
  setSelectedItem,
  selectedItem,
  setValue,
  getValues,
  disableChangeStatus,
  openWeb3Modal,
}) => {
  const dispatch = useDispatch();
  const [showProvideTokenModal, setShowProvideTokenModel] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(selectedItem?.contract_address);
  const [showAirDropWarning, setShowAirDropWarning] = useState(false);
  const [teamBalance, setTeamBalance] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [coinOptions, setCoinOptions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const user = useSelector(getUser);

  const {
    data: searchCoin,
    isLoading: searchCoinIsLoading,
    isFetching: searchCoinIsFetching,
  } = coinApi.useSearchERC20TokenQuery(
    {
      query: '',
    },
  );

  const {
    data: teamData,
    isLoading: teamDataIsLoading,
    isFetching: teamDataIsFetching,
  } = userApi.useGetAssetsSearchQuery();

  const fetchCoinOptions = useCallback(() => {
    const filteredSearchCoin = searchCoin?.filter((item) => item.blockchain !== 'near');
    const filteredTeamData = teamData?.filter((item) => item.network !== 'near');
    const formattedCoinData = getFormattedCoinData(filteredTeamData, filteredSearchCoin);
    setCoinOptions(formattedCoinData[0] && formattedCoinData[0].length
      ? formattedCoinData[0] : formattedCoinData[1].slice(0, 5));
  }, [teamData, searchCoin]);

  useEffect(() => {
    fetchCoinOptions();
  }, [fetchCoinOptions, teamData, searchCoin]);

  const getBalance = async () => {
    if (user.team && selectedAddress && selectedBlockchain) {
      const selectedBlockChainValue = blockChainOptionsWithTestnets
        .find((elem) => elem.network === selectedBlockchain)?.value;
      const airdropContractAddress = getAirdropContractAddress(selectedBlockChainValue);
      const response = await dispatch(
        blockchainApi
          .endpoints
          .getTeamTokenBalance
          .initiate({
            blockchain: selectedBlockChainValue,
            userContractAddress: selectedAddress,
            teamContractAddress: airdropContractAddress,
          }, { forceRefetch: true }),
      );
      if (response?.error) {
        setTeamBalance(0);
        dispatch(setTeamBalanceReducer(null));
      } else {
        let result = response.data?.balance;
        if (!Number.isNaN(result)) {
          result /= 10 ** 18;
          setTeamBalance(result);
          dispatch(setTeamBalanceReducer(result));
        }
      }
    }
  };

  useEffect(() => {
    getBalance();
  }, [dispatch, user.team, selectedAddress, selectedBlockchain]);

  const getUserInputData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        head: true,
      }]);
    }

    const result = await dispatch(
      coinApi.endpoints.searchERC20Token.initiate({ query: val }),
    );

    if (result.status === 'fulfilled') {
      const filteredData = result.data.filter((item) => item.blockchain !== 'near');
      return filteredData.map((item) => ({
        ...item,
        value: item.contract_address,
        label: item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const loadUserInputData = useCallback(debounce((val) => getUserInputData(val), 1000), [getUserInputData]);
  const getMcapLabel = (val) => <McapLabel val={val} type="token" />;
  const getChainLabel = useCallback((val) => <IconNearby val={val} />, []);

  return (
    <div className={styles.wrapper}>
      {
        showProvideTokenModal && window.ethereum
        && (
          <ProvideTokensModal
            selectedItem={selectedItem}
            getValues={getValues}
            setShowProvideTokenModel={setShowProvideTokenModel}
            setTeamBalanceOnSettings={setTeamBalance}
            openWeb3Modal={openWeb3Modal}
            teamBalance={teamBalance}
            getTeamBalance={getBalance}
          />
        )
      }

      <AsyncSelect
        className={styles.chain_select}
        styles={onChainInputStyles}
        maxMenuHeight={300}
        isOptionSelected={false}
        loadOptions={loadUserInputData}
        components={
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            Control: (props) => <ControlComponent props={props} onChain />,
          }
        }
        selectProps={selectedItem}
        getOptionLabel={(val) => getMcapLabel(val)}
        onChange={(e) => {
          if (e) {
            if (!e.blockchain) {
              setSelectedAddress(e.value);
              setSelectedBlockchain(null);
              setTokenAmount('');
              setValue('address', e.value);
              setValue('blockchain', null);
              setValue('token_amount', '');
              setSelectedItem({
                ...e,
                label: `${e.label.slice(0, 21)}...${e.label.slice(-5)}`,
              });
            } else {
              setSelectedItem(e);
              setSelectedAddress(e.value);
              setValue('address', e.value);
              setValue('blockchain', e.blockchain);
              setValue('asset_name', e.label);
              setSelectedBlockchain(e.blockchain);
            }
            dispatch(setERC20Address(e.platforms?.ethereum || e.value));
          } else {
            setSelectedItem(null);
            setSelectedAddress(null);
            setSelectedBlockchain(null);
            setTokenAmount('');
            setValue('address', '');
            setValue('blockchain', '');
            setValue('token_amount', '');
          }
        }}
        isLoading={teamDataIsLoading || teamDataIsFetching || searchCoinIsLoading || searchCoinIsFetching}
        placeholder="Token name or contract address"
        value={selectedItem}
        defaultOptions={coinOptions}
        isDisabled={disableChangeStatus}
        isClearable
      />
      {!selectedItem?.image_url && selectedItem?.value && (
        <div>
          <div className={`mb-1 ${styles.block_title}`}>Select blockchain</div>
          <div>
            <Select
              {...register('blockchain')}
              className="w-100"
              styles={sourceInputStyles}
              value={blockChainOptionsWithTestnets.find((elem) => elem.value === getValues('blockchain'))}
              onChange={(val) => {
                setSelectedBlockchain(val.network);
                setValue('blockchain', val.value);
              }}
              getOptionLabel={(val) => getChainLabel(val)}
              options={blockChainOptionsWithTestnets}
              isDisabled={disableChangeStatus}
            />
          </div>
        </div>
      )}

      {getValues('blockchain') && getValues('address') && (
        <div>
          <div className={styles.balance_title}>
            <div className={styles.block_title}>
              Balance available for airdrop:
            </div>
            <div className={styles.balance_count}>
              {teamBalance || 0}
              {' '}
              {!selectedItem.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''}
            </div>
          </div>
          <button
            type="button"
            className={`${styles.wallet_btn}
                outline-blue-button`}
            onClick={() => {
              if (!user.air_drop) {
                setShowAirDropWarning(true);
              } else {
                setShowProvideTokenModel(true);
              }
            }}
            disabled={disableChangeStatus}
          >
            {`Provide ${!selectedItem.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''} tokens`}
          </button>
          {showAirDropWarning && (
            <div className={`d-flex w-100 gap-2 mt-2 ${styles.wrap_warning}`}>
              <img src={warning} alt="warning" width="24px" height="24px" className="mt-1 align-self-start" />
              <div className="d-flex text-start w-75">
                Your account is not allowed to launch a Flow performing airdrops.
                Please communicate with your CSM for details.
              </div>
            </div>
          )}
        </div>
      )}

      {getValues('blockchain') && getValues('address') && (
        <div>
          <div className={`${styles.block_title} mb-1`}>
            Number of tokens to send to each wallets entering
          </div>
          <div className="d-flex align-items-center gap-3">
            <input
              type="number"
              step=".00000001"
              className={styles.input_number}
              value={isFocused
                ? getValues('token_amount' || tokenAmount) : convertSciToDec(getValues('token_amount' || tokenAmount))}
              onChange={(e) => {
                setValue('asset_name', selectedItem.label);
                setValue('token_amount', e.target.value);
                setTokenAmount(e.target.value);
                setAmountToAirdrop(e.target.value);
              }}
              min="0"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onWheel={(e) => e.target.blur()}
              disabled={disableChangeStatus}
            />
            <div className={styles.price}>
              {selectedItem?.current_price
                && `$${ethFormat(selectedItem.current_price * tokenAmount)}`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TokensAirdrop;
