import React from 'react';
import styles from './NotSupportedBlockchain.module.scss';
import { ReactComponent as NotSupportedImg } from '../../assets/images/not_supported.svg';

const NotSupportedBlockchain = () => (
  <div className={`${styles.wrapper} d-flex flex-column align-items-center text-center`}>
    <span>The blockchain is not supported</span>
    <span>This asset is on a blockchain we do not support yet</span>
    <NotSupportedImg />
  </div>
);

export default NotSupportedBlockchain;
