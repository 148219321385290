import React from 'react';
import { useDispatch } from 'react-redux';
import { clearActiveSettings } from '../../../../../store/reducers/forms';
import { ReactComponent as Close } from '../../../../../assets/icons/close.svg';
import styles from './ModalSettings.module.scss';

const ModalSettings = ({ title, children }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <div className={styles.settings_title}>
        {title}
      </div>
      <div className={`${styles.close}`}>
        <Close
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => dispatch(clearActiveSettings())}
        />
      </div>
      {children}
    </div>
  );
};

export default ModalSettings;
