import React from 'react';
import infoImg from '../../../assets/icons/info.svg';
import Tooltip from '../../ui/Tooltip';
import uniqueId from '../../../utils/uniqueId';
import styles from './TitleComponent.module.scss';

const TitleComponent = ({ children, info, place }) => {
  const toolTipId = uniqueId('tooltip');

  return (
    <div className={`d-flex justify-content-between align-items-center ${info ? '' : 'w-75'}`}>
      <div className={`${styles.title_component} text-nowrap`}>
        <h3 className="text-truncate">{children}</h3>
        <div className={`${styles.divider_bold} mt-1`} />
      </div>
      {info
        ? (
          <>
            <button
              type="button"
              className={`${styles.btn_component} btn d-flex align-items-center justify-content-center shadow-none`}
              data-for={toolTipId}
              data-tip
            >
              <img src={infoImg} alt="info" width="20px" height="20px" />
            </button>
            <Tooltip id={toolTipId} info={info} truncate={false} place={place || 'top'} />
          </>
        )
        : null}
    </div>
  );
};

export default TitleComponent;
