import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Title from '../../../../components/base/Title';
import uniqueId from '../../../../utils/uniqueId';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import styles from './FormActivityGraph.module.scss';
import DailyChart from './DailyChart';
import TopSources from '../../../../assets/icons/forms/top_sources.svg';
import ConversionRate from '../../../../assets/icons/forms/conversion_rate.svg';
import Responses from '../../../../assets/icons/forms/responses.svg';
import Views from '../../../../assets/icons/forms/views.svg';
import { formsApi } from '../../../../api/forms';
import WidgetCard from '../../../../components/ui/WidgetCard';
import { getCardValue, getPercent } from '../../../../utils/values';

const FormActivityGraph = ({
  isFormLoading, url, formDataOverview, isFormDataOverviewFetching, isFormDataOverviewLoading,
}) => {
  const dispatch = useDispatch();
  const [widgetData, setWidgetData] = useState([]);

  const {
    data: formGraphChart,
    isLoading: isGraphLoading,
    isFetching: isGraphFetching,
    isSuccess: isGraphSuccess,
  } = formsApi.useFormResponseDailyChartQuery(url, { skip: !url });

  useEffect(() => {
    if (formDataOverview && !isFormDataOverviewLoading && !isFormDataOverviewFetching) {
      setWidgetData([
        {
          title: 'Views',
          value: getCardValue(formDataOverview?.views),
          icon: Views,
          info: 'Number of views of your form',
        },
        {
          title: 'Responses',
          value: getCardValue(formDataOverview?.response_count),
          icon: Responses,
          info: 'Number of responses to your form',
        },
        {
          title: 'Conversion Rate',
          value: getPercent(formDataOverview?.response_count, formDataOverview?.visitors),
          icon: ConversionRate,
          info: 'Percentage of visitors who responded to your form',
        },
        {
          title: 'Top Source',
          value: formDataOverview?.top_source !== null
            ? `${convertNumber(formDataOverview?.top_sources)}`
            : '?',
          icon: TopSources,
          info: 'Number 1 source of traffic to your form',
        },
      ]);
    }
  }, [dispatch, formDataOverview, isFormLoading]);

  return (
    <div className="asset-container">
      <div className="title-gap d-flex align-items-center gap-2">
        <Title>Overview</Title>
      </div>
      <div className="dropdown-divider w-100" />
      <div className="info-wrapper-top d-flex justify-content-between gap-4">
        {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
          <WidgetCard
            key={uniqueId('form-widget')}
            icon={item.icon}
            info={item.info}
            growth={item.growth}
            title={item.title}
            value={item.value}
            isLoading={isFormDataOverviewLoading
                     || isFormDataOverviewFetching || item.isLoading}
          />
        ))}
      </div>
      <div className={styles.blockWrapper}>
        <DailyChart
          graphData={formGraphChart}
          isGraphDataLoadin={isGraphLoading}
          isGraphDataFetching={isGraphFetching}
          isSuccess={isGraphSuccess}
        />
      </div>
    </div>
  );
};

export default FormActivityGraph;
