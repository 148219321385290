import React from 'react';
import uniqueId from '../../../utils/uniqueId';
import Tooltip from '../Tooltip';
import ArrowUp from '../../../assets/icons/arrow_up.svg';
import ArrowDown from '../../../assets/icons/arrow_down.svg';
import styles from './GrowthIndicator.module.scss';

const GrowthIndicator = ({ percent, oldValue }) => {
  const tooltipId = uniqueId('date');

  if (percent === '-') {
    return (
      <>
        <div
          data-for={tooltipId}
          data-tip
        >
          <img src={ArrowDown} height="8" width="6" alt="arrow down" />
        </div>
        <Tooltip id={tooltipId} info={oldValue} />
      </>
    );
  }

  if (percent === '+') {
    return (
      <>
        <div
          data-for={tooltipId}
          data-tip
        >
          <img src={ArrowUp} height="8" width="6" alt="arrow up" />
        </div>
        <Tooltip id={tooltipId} info={oldValue} />
      </>
    );
  }

  return (
    <>
      <div
        data-for={tooltipId}
        data-tip
      >
        <span className={`${styles.percent} ${percent < 0
          ? styles.negative : ''}`}
        >
          {percent < 0
            ? <img src={ArrowDown} height="8" width="6" alt="arrow down" />
            : percent > 0 ? <img src={ArrowUp} height="8" width="6" alt="arrow up" /> : '= '}
          {percent < 0 ? percent * -1 : percent}
          %
        </span>
      </div>
      {percent !== '0' && <Tooltip id={tooltipId} info={oldValue} />}
    </>
  );
};

export default GrowthIndicator;
