import React from 'react';
import ReactTooltip from 'react-tooltip';
import attention from '../../../assets/icons/attention.svg';
import styles from './DownloadTooltip.module.scss';
import uniqueId from '../../../utils/uniqueId';

const DownloadTooltip = ({
  header, warningsList, id,
}) => (
  <ReactTooltip
    id={id}
    backgroundColor="white"
    place="top"
    multiline
    className={styles.tooltip}
    textColor="#90A0B7"
    offset={{ left: 100 }}
  >
    <div className="d-flex flex-column">
      <div className={styles.header}>
        {header}
      </div>
      {warningsList.map((elem) => (
        <div
          className={`d-flex gap-1  ${styles.wrapper_info}`}
          key={uniqueId('warning')}
        >
          <img src={attention} alt="attention" width="14px" height="12px" className="mt-1" />
          {elem}
        </div>
      ))}
    </div>
  </ReactTooltip>
);

export default DownloadTooltip;
