import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import NoData from '../../../../../components/base/NoData';
import RoundSpinner from '../../../../../components/base/RoundSpinner';
import { defaultOptions } from '../../../../../components/ui/Market/Price';
import styles from './DailyChart.module.scss';

const DailyChart = ({
  graphData, isGraphDataLoading, isGraphDataFetching, isSuccess,
}) => {
  const handleData = (data) => {
    const reversedData = [...data].reverse();
    return {
      series: [{
        name: 'Views',
        type: 'column',
        data: reversedData.map((elem) => elem.views),
      }, {
        name: 'Responses',
        type: 'column',
        data: reversedData.map((elem) => elem.responses),
      }],
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'arena',
          stacked: false,
        },
        stroke: {
          width: [1, 1, 1],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 1,
            columnWidth: '35%',
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: ['#109cf1', '#ffb946'],
        fill: {
          opacity: 1,
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
          categories: reversedData.map((elem) => elem.day),
          tickPlacement: 'on',
          axisBorder: {
            show: true,
            color: '#6A707E',
          },
          axisTicks: {
            show: true,
            color: '#6A707E',
          },
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
            color: '#6A707E',
          },
          axisTicks: {
            show: true,
            color: '#6A707E',
            width: 4,
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y, { ...chartData }) => (!chartData.seriesIndex ? `${y}` : y),
          },
        },
        legend: {
          show: false,
        },
      },
    };
  };

  const chartContent = useMemo(() => {
    if ((isGraphDataLoading || isGraphDataFetching) && !graphData) {
      return (
        <div className={`${styles.load_wrap}`}>
          <RoundSpinner position="position-absolute" theme="light" />
        </div>
      );
    } if (!graphData && isSuccess) {
      return (
        <div className={`${styles.load_wrap}`}>
          <NoData />
        </div>
      );
    } return (
      <Chart
        options={isGraphDataLoading || isGraphDataFetching
          ? defaultOptions.options
          : graphData && !isGraphDataLoading ? handleData(graphData).options : defaultOptions.options}
        series={(isGraphDataLoading || isGraphDataFetching)
          ? defaultOptions.series
          : graphData && !isGraphDataLoading ? handleData(graphData).series : defaultOptions.series}
        type="line"
        height={400}
      />
    );
  }, [graphData, isGraphDataFetching, isGraphDataLoading, isSuccess]);

  return useMemo(() => (
    <>
      <div id="chart" className="position-relative">
        {chartContent}
      </div>
      <div className="d-flex gap-2">
        <div className="d-flex align-items-center gap-2 asset-wrap">
          <div className="dot" />
          <span>Views</span>
        </div>
        <div className="d-flex align-items-center gap-2 asset-wrap">
          <div className="dot-2" />
          <span>Responses</span>
        </div>
      </div>
    </>
  ), [chartContent, graphData, isGraphDataFetching, isGraphDataLoading]);
};

export default DailyChart;
