import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import styles from './ErrorModal.module.scss';

const ErrorModal = ({ title, message, redirectUrl }) => {
  const navigate = useNavigate();
  return (
    <div className="modal fade" id="errorModal" tabIndex="-1" aria-labelledby="errorModalLabel" aria-hidden="false">
      <div className="modal-dialog modal-dialog-centered">
        <div className={`${styles.wrapper} modal-content border-0`}>
          <div className={styles.header}>
            <div className="d-flex justify-content-end">
              <Close type="button" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className={`${styles.title} d-flex`}>
              {title || 'Cannot delete segment'}
            </div>
          </div>
          <div className={`${styles.description} d-flex w-100 mt-3`}>
            <span>
              {message}
            </span>
          </div>
          <div className={`${styles.footer} mt-3 d-flex justify-content-end`}>
            <button
              onClick={() => {
                if (redirectUrl) {
                  navigate(redirectUrl);
                }
              }}
              type="button"
              className="regular-button border border-1"
              data-bs-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
