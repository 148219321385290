import React from 'react';
import styles from '../../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import ShareStyles from './ShareFormModal.module.scss';
import CloseModalButton from '../../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../../components/hooks/app/useKeydown';
import { ReactComponent as Copy } from '../../../../assets/icons/copy_outline.svg';
import { showInfoMessage } from '../../../../components/base/Notifications';

const ShareFormModal = ({
  onCancel, url,
}) => {
  useKeydown('Escape', onCancel);
  const iFrameText = `
  <!--We recommend the width to be 600px-->
  <iframe 
    src="${url}"
    frameborder="0" style="max-width:600px; width:100%;" 
    onload="window.addEventListener(
      'message',(e)=>{
        if(e.data.type==='wrmSize'){
          this.style.height=e.data.height+'px';
        }
      },
    false);">
  </iframe>`;

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div className="mb-3">
            <div className={`${styles.title} d-flex justify-content-start`}>
              Publish form
            </div>
            <div className={`${ShareStyles.description} d-flex justify-content-start`}>
              <span>Copy this link and send it to your audience</span>
            </div>
            <div className={`${ShareStyles.copy_container} d-flex justify-content-start`}>
              <p>{url}</p>
              <div>
                <Copy
                  className={`${ShareStyles.copy_icon} cursor-pointer`}
                  onClick={() => {
                    navigator.clipboard.writeText(url);
                    showInfoMessage('The URL was copied to your clipboard');
                  }}
                />
              </div>
            </div>
            <div className={`${ShareStyles.description} d-flex justify-content-start`}>
              <span>Or copy the embedding code below</span>
            </div>
            <div className={`${ShareStyles.copy_container}`}>
              <p>
                {iFrameText}
              </p>
              <div>
                <Copy
                  className={`${ShareStyles.copy_icon} cursor-pointer`}
                  onClick={() => {
                    navigator.clipboard.writeText(iFrameText);
                    showInfoMessage('The iFrame Embedding was copied to your clipboard');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareFormModal;
