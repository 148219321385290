import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FolderSettingsButton from './FolderSettingsButton';
import { ReactComponent as AllIcon } from '../../../assets/icons/folders/all.svg';
import { ReactComponent as MyIcon } from '../../../assets/icons/folders/my.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/folders/folder.svg';
import styles from './listWithFolders.module.scss';
import useSetSearchParams from '../../../utils/useSetSearchParams';

const FolderButton = ({
  folder, addSubfolder, renameFolder, deleteFolder, type, foldersRef,
}) => {
  const setParams = useSetSearchParams();
  const [icon, setIcon] = useState(null);
  const [searchParams] = useSearchParams();
  const selectedFolder = searchParams.get('folder') || 'all';

  useEffect(() => {
    if (!folder) {
      setIcon(null);
    } else if (folder.id === 'all') {
      setIcon(<AllIcon />);
    } else if (folder.id === 'my') {
      setIcon(<MyIcon />);
    } else {
      setIcon(<FolderIcon />);
    }
  }, [folder]);

  const handleSelection = () => {
    setParams({
      folder: folder.id,
      page: 1,
    });
  };

  return (
    <button
      // eslint-disable-next-line eqeqeq
      className={`nav-button ${selectedFolder == folder.id ? 'nb-selected' : ''}`}
      type="button"
      onClick={() => handleSelection()}
      style={{ position: 'relative' }}
    >
      <div className={styles.icon}>{icon}</div>

      <div className={styles.buttonText}>{folder.name}</div>

      {folder.id !== 'all' && folder.id !== 'my' && (
        <FolderSettingsButton
          folder={folder}
          addSubfolder={addSubfolder}
          renameFolder={renameFolder}
          deleteFolder={deleteFolder}
          type={type}
          foldersRef={foldersRef}
        />
      )}
    </button>
  );
};

export default FolderButton;
