import React from 'react';
import styles from './ChartIndicator.module.scss';
import ArrowUp from '../../../assets/icons/arrow_up.svg';
import ArrowDown from '../../../assets/icons/arrow_down.svg';

const ChartIndicator = ({ text, value }) => (
  <div className={styles.indicator}>
    {text}
    <span className={`${styles.percent} ${value < 0
      ? styles.negative : ''}`}
    >
      {value < 0 && <img src={ArrowDown} height="12" width="8" alt="arrow down" />}
      {value > 0 && <img src={ArrowUp} height="12" width="8" alt="arrow up" />}
      {value < 0 ? value * -1 : value}
      %
    </span>
  </div>
);

export default ChartIndicator;
