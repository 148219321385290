import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styles from './SegmentsNodeSettings.module.scss';
import { audiencesApi } from '../../../../../api/audiences';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList, selectFlowStatus,
} from '../../../../../store/reducers/flows';
import { ReactComponent as Warning } from '../../../../../assets/icons/flows/info.svg';
import { debounce } from '../../../../../utils/debounce';
import SegmentsLabel from '../../Components/SegmentsLabel';
import Tooltip from '../../../../../components/ui/Tooltip';
import { convertToReadableFormat, convertToComma } from '../../../../../tools/NumberConverterTool';
import EditableTitle from '../../../../../components/base/EditableTitle';
import Checkbox from '../../../../../components/ui/Checkbox';

const inputStyles = {
  option: (style) => ({
    ...style,
    cursor: 'pointer',
    padding: '13px 12px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  placeholder: (style) => ({
    ...style,
    color: '#90A0B7',
    fontSize: '14px',
    lineHeight: '20px',
  }),
};

const ControlComponent = ({ props }) => {
  const propsToSet = { ...props };
  if (props.hasValue) {
    propsToSet.selectProps.selectProps.head = true;
  }
  return (
    <div>
      <components.Control {...propsToSet} />
    </div>
  );
};

const SegmentsNodeSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();

  const [userInput, setUserInput] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [selectedItem, setSelectedItem] = useState();

  const {
    register, handleSubmit, setValue, watch,
  } = useForm();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const newObj = {
      ...submitData, segment_name: selectedItem.name, segment_id: selectedItem.id, user_input: userInput,
    };
    if (selectedItem.population || selectedItem.population === 0) {
      newObj.population = selectedItem.population;
    }
    if (titleValue) {
      newObj.name = titleValue;
    }
    if (!newObj.frequency || newObj.frequency === 'Once') {
      delete newObj.can_re_enter;
      delete newObj.skip_initial;
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...newObj, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  const {
    data: topSegments,
    isLoading: topSegmentsLoading,
    isFetching: topSegmentsFetching,
  } = audiencesApi.useGetFlowsSegmentsQuery('');

  const getData = useCallback(async (val) => {
    const tempData = await dispatch(
      audiencesApi.endpoints.getFlowsSegments.initiate(val),
    );

    if (tempData.status === 'fulfilled') {
      return tempData.data.results.map((item) => ({
        ...item,
        value: item.name,
        label: item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const loadData = useCallback(debounce((val) => getData(val), 1000), [getData]);

  const getSegmentsLabel = (val) => <SegmentsLabel val={val} />;

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setSelectedItem({
          value: configList[index].segment_name,
          label: configList[index].segment_name,
          name: configList[index].segment_name,
          population: configList[index].population,
          id: configList[index].segment_id,
        });
        setTitleValue(configList[index].name);
        setUserInput(configList[index].user_input);
        setValue('frequency', configList[index].frequency);
        setValue('can_re_enter', configList[index].can_re_enter);
        setValue('skip_initial', configList[index].skip_initial);
      }
    }
  }, [activeSettings.node_id, configList, setValue]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="New segment source"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              onSave={() => setUserInput(true)}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <AsyncSelect
            className="w-100 mb-3"
            styles={inputStyles}
            maxMenuHeight={300}
            loadOptions={loadData}
            components={
              {
                // eslint-disable-next-line react/no-unstable-nested-components
                Control: (props) => <ControlComponent props={props} />,
              }
            }
            selectProps={selectedItem}
            getOptionLabel={(val) => getSegmentsLabel(val)}
            onChange={(e) => {
              if (!userInput) {
                setTitleValue(e.value);
              }
              setSelectedItem(e);
            }}
            isLoading={topSegmentsLoading || topSegmentsFetching}
            placeholder="Search for segments"
            value={selectedItem}
            defaultOptions={topSegments?.results.map((elem) => ({ ...elem, value: elem.name, label: elem.name })) || []}
            isDisabled={disableChangeStatus}
          />
          {selectedItem?.name
            ? (
              <div className={`${styles.select} mb-3`}>
                <div className={`${styles.audience} d-flex justify-content-between mb-3`}>
                  <div className={styles.audience_text}>
                    Audience Size:
                  </div>
                  <div className="text-right">
                    {convertToComma(convertToReadableFormat((selectedItem?.population || 0)))}
                  </div>
                </div>
                <div className={`${styles.select_title} mb-1`}>Frequency</div>
                <select
                  {...register('frequency')}
                  disabled={disableChangeStatus}
                >
                  <option hidden value="">Select</option>
                  <option value="Once">Once</option>
                  <option value="Hourly">Hourly</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
            )
            : null}
          {watch('frequency') && watch('frequency') !== 'Once'
            ? (
              <>
                <div className={`${styles.checkbox} d-flex gap-1 align-items-center`}>
                  <Checkbox
                    label="Allow audience members to be sent multiple times"
                    useFormData={register('can_re_enter')}
                    disabled={disableChangeStatus}
                  />
                  <div className={styles.wrap_warning} data-for="re_enter" data-tip>
                    <Warning />
                  </div>
                  <Tooltip
                    id="re_enter"
                    info="Addresses are sent through the flow again if still present when the flow repeats"
                    place="top"
                    fixWidth
                  />
                </div>
                <div className={`${styles.checkbox} d-flex gap-2 align-items-center mt-2`}>
                  <Checkbox
                    label="Skip initial audience"
                    useFormData={register('skip_initial')}
                    disabled={disableChangeStatus}
                  />
                  <div className={styles.wrap_warning} data-for="skip_initial" data-tip>
                    <Warning />
                  </div>
                  <Tooltip
                    id="skip_initial"
                    info="Initial audience of segment will be ignored,
                     and only future segment members will be considered"
                    place="bottom"
                  />
                </div>
              </>
            )
            : null}
        </div>
        <div>
          <div className="d-flex gap-4 mt-4">
            <button
              type="button"
              className={`${styles.btn_cancel}`}
              onClick={() => dispatch(clearActiveSettings())}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button w-100"
              disabled={!selectedItem || disableChangeStatus}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SegmentsNodeSettings;
