import React from 'react';
import TitleComponent from '../../../../components/base/TitleComponent';
import { LoadingLines } from '../../../../components/ui/modals/SearchModal/LoadingList';
import NoData from '../../../../components/base/NoData';
import uniqueId from '../../../../utils/uniqueId';
import styles from './TopBlocks.module.scss';
import LogoDisplay from '../../../../components/ui/modals/SearchModal/LogoDisplay';

const TopBlocks = ({
  title, info = null, data, isLoading,
}) => {
  const languageNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  return (
    <div className={styles.wrapper}>
      <TitleComponent info={info}>{title}</TitleComponent>
      <div className="d-flex flex-column w-100 pt-3">
        {isLoading ? <LoadingLines rows={1} /> : data.length ? data.filter((elem) => elem.value).map((elem) => (
          <div
            className="d-flex justify-content-between w-100 my-2 align-items-center"
            key={uniqueId('top_elem')}
          >
            <div className="d-flex align-items-center gap-2 w-50">
              {title === 'Top Countries' ? (
                <LogoDisplay
                  logo={`/trackedWebsite/countryFlags/${elem.value.toLowerCase()}.svg`}
                  size="21px"
                  asset="address"
                  position="static"
                />
              ) : title === 'Top Wallets' ? (
                <LogoDisplay
                  logo={`/trackedWebsite/walletIcons/wallet_${elem.value.toLowerCase()}.png`}
                  size="21px"
                  asset="address"
                  position="static"
                />
              ) : null}
              <span className={`${styles.spanField} text-truncate`}>
                {title === 'Top Languages'
                  ? languageNamesInEnglish.of(elem.value) : title === 'Top Countries'
                    ? regionNamesInEnglish.of(elem.value) : title === 'Top Wallets'
                      ? elem.value.replace(/Wallet/g, ' Wallet') : elem.value}
              </span>
            </div>
            <span className={`${styles.spanValue} text-truncate`}>
              { `${elem.visitor_count.toLocaleString()} visitor${elem.visitor_count !== 1 ? 's' : ''}`}
            </span>
          </div>
        )) : <NoData />}
      </div>
    </div>
  );
};

export default TopBlocks;
