import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styles from './WebhookNode.module.scss';
import { ReactComponent as Webhook } from '../../../../../assets/icons/flows/webhook_activation.svg';
import { ReactComponent as Edit } from '../../../../../assets/icons/flows/edit.svg';
import { ReactComponent as Alert } from '../../../../../assets/icons/flows/alert.svg';
import {
  clearActiveSettings,
  deleteSettings,
  selectConfigsList,
  selectErrors, selectFlowStatus,
  setActiveSettings,
} from '../../../../../store/reducers/flows';
import { ReactComponent as Divider } from '../../../../../assets/icons/divider.svg';
import { ReactComponent as Delete } from '../../../../../assets/icons/flows/delete.svg';
import { handle, handleInput } from '../../../../../utils/flows';
import Tooltip from '../../../../../components/ui/Tooltip';

const WebhookNode = ({ ...props }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [completed, setCompleted] = useState(false);
  const [onNodeHover, setOnNodeHover] = useState(false);

  const configList = useSelector(selectConfigsList);
  const errors = useSelector(selectErrors);
  const status = useSelector(selectFlowStatus);

  const filteredErrors = () => errors.length && errors.filter((elem) => elem.node_id === props.id);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(props.id);
      if (index >= 0) {
        setData(configList[index]);
      }
    }
  }, [configList, props.id]);

  useEffect(() => {
    if (data && data?.url && data.name) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [data]);

  return (
    <>
      <div
        className={`
        ${styles.wrapper}
        ${data && data.url ? styles.size120 : ''}
        ${status === 'running' || status === 'scheduled' || status === 'stopped' ? 'cursor-pointer' : ''}
        d-flex
        flex-column
        justify-content-center
        `}
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
        role="presentation"
        onClick={() => {
          if (status === 'running' || status === 'scheduled' || status === 'stopped') {
            dispatch(setActiveSettings({ type: 'webhookNode', node_id: props.id }));
          }
        }}
      >
        <div className={`${styles.title} d-flex align-items-center justify-content-between gap-1 w-100`}>
          <div className={`${styles.title_size} d-flex align-items-center gap-1`}>
            {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
              ? (
                <>
                  {!completed || filteredErrors().length
                    ? (
                      <>
                        <div
                          className={styles.alert}
                          data-for={`${props.id}_error`}
                          data-tip
                        >
                          <Alert />
                        </div>
                        {filteredErrors().length
                          ? (
                            <Tooltip
                              id={`${props.id}_error`}
                              info={`${filteredErrors()[0].detail}`}
                            />
                          )
                          : null}
                      </>
                    )
                    : null}
                  <div className={onNodeHover ? styles.delete : styles.delete_hide}>
                    <Delete onClick={() => {
                      props.data.onRemove(props.id);
                      dispatch(deleteSettings(props.id));
                      dispatch(clearActiveSettings());
                    }}
                    />
                  </div>
                </>
              )
              : null}
            <div width="32px" height="32px">
              <Webhook />
            </div>
            <div className={`${styles.description} d-block text-truncate`}>
              {(data && data.name) || 'Webhook'}
            </div>
          </div>
          <div className="cursor-pointer" width="20px" height="20px">
            <Edit onClick={() => dispatch(setActiveSettings({ type: 'webhookNode', node_id: props.id }))} />
          </div>
        </div>
        {data && data.url
          ? (
            <div>
              <div className={`${styles.divider} d-flex`}>
                <Divider />
              </div>
              <div className={`${styles.info} d-flex`}>
                <div className={`${styles.info_col} d-flex align-items-start flex-column gap-1`}>
                  <div>
                    Link:
                  </div>
                  <div className="text-truncate w-100">
                    {data.url}
                  </div>
                </div>
              </div>
            </div>
          )
          : null}
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ ...handleInput, left: '-10px' }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ ...handle, right: '-16px' }}
        id="webhookNode_output"
      />
    </>
  );
};

export default WebhookNode;
