import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useParams, useSearchParams, useLocation,
} from 'react-router-dom';
import { useNavigationType } from 'react-router';
import SingleFormTitle from '../../../components/ui/TitleSection/Titles/SingleFormTitle';
import FormActivityGraph from './FormActivityGraph';
import { selectTabPages, setTabPages } from '../../../store/reducers/forms';
import uniqueId from '../../../utils/uniqueId';
import ResponsesTable from './FormAnalyticsTabs/Responses';
import ComingSoon from '../../../components/ComingSoon';
import { formsApi } from '../../../api/forms';
import ShareFormModal from '../Modals/ShareFormModal';

const FormAnalytics = () => {
  const navType = useNavigationType();
  const tabPages = useSelector(selectTabPages);
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [itemPage, setItemPage] = useState(searchParams.get('tab')?.replace('+', ' ') || 'Responses');
  const [shareFormModal, setShareFormModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  const isFetching = false;
  const isLoading = false;

  const { formId } = useParams();

  const {
    currentData: form,
    isLoading: isFormLoading,
    isFetching: isFormFetching,
  } = formsApi.useGetFormByIdQuery(
    {
      id: formId,
    },
    { skip: !formId || isLoaded },
  );

  const {
    data: formDataOverview,
    isLoading: isFormDataOverviewLoading,
    isFetching: isFormDataOverviewFetching,
  } = formsApi.useResponsesTopBlockQuery(
    form?.url,
    { skip: !formId || isLoaded || !form },
  );

  useEffect(() => () => {
    setIsLoaded(false);
    dispatch(setTabPages([]));
  }, [dispatch]);

  const handleShareClick = (fullUrl) => {
    setShareUrl(fullUrl);
    setShareFormModal(true);
  };

  const pages = [
    {
      label: 'Responses',
      component: <ResponsesTable
        url={form?.url}
        isFormLoading={isFormLoading || isFormFetching
          || isFormDataOverviewLoading || isFormDataOverviewFetching}
        form={form}
        responses={formDataOverview?.response_count}
      />,
      id: 1,
    },
    { label: 'Sources', component: <ComingSoon />, id: 2 },
    { label: 'Audiences', component: <ComingSoon />, id: 3 },
    { label: 'Configuration', component: <ComingSoon />, id: 4 },
  ];

  return (
    <div>
      <SingleFormTitle
        isFormLoading={isFormLoading || isFormFetching}
        formData={form}
        onShareClick={handleShareClick}
      />
      {shareFormModal && (
        <ShareFormModal
          onCancel={() => setShareFormModal(false)}
          url={shareUrl}
        />
      )}
      <div className="asset-section mt-3 pb-4">
        { isFormLoading || isFormFetching ? null : (
          <FormActivityGraph
            isLoading={isLoading || isFetching}
            url={form?.url}
            formDataOverview={formDataOverview}
            isFormDataOverviewFetching={isFormDataOverviewFetching}
            isFormDataOverviewLoading={isFormDataOverviewLoading}
          />
        )}
      </div>
      <div className="asset-section m-o mt-4">
        <div className="title-gap asset-navigation">
          <ul className="nav nav-pills gap-2">
            {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((elem) => (
              <li
                role="presentation"
                className="nav-item"
                key={uniqueId('segment-analytics-tab')}
                onClick={() => {
                  setItemPage(elem.label);
                  setSearchParams({
                    tab: elem.label,
                    scroll: '',
                  });
                }}
              >
                <Link
                  className={`nav-link ${
                    elem.label === itemPage ? 'active' : ''
                  }`}
                  to={`${pathname}${search}`}
                >
                  {elem.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown-divider w-100" />
        <div>
          {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (item.label === itemPage ? (
            <div key={uniqueId('form-analytics-page')}>{item.component}</div>
          ) : null))}
        </div>
      </div>
    </div>
  );
};

export default FormAnalytics;
