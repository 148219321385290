import React from 'react';

import styles from './GoogleCloudSignUpModal.module.scss';
import { ReactComponent as TickCircle } from '../../../../assets/icons/tick_circle.svg';

const GoogleCloudSignUpModal = ({ openModal }) => (
  <div
    className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
    id="exampleModal"
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      role="presentation"
      className="modal-dialog modal-md modal-phone w-100"
    >
      <div className="modal-content p-3">
        <div className="d-flex flex-column align-items-center justify-content-center mt-2">
          <TickCircle className="m-3" />
          <div className="title-h2-regular">Google Cloud Marketplace Sign-up</div>
          <div className="text-md-regular text-center mb-1 mt-2 w-100">
            An account creation request has been sent to our teams. 
          </div>
          <div className="text-md-regular text-center mb-3 mt-1 w-100">
            Please now set your password in the next modal. We will be contacting you shortly.
          </div>
          <button className="regular-button mb-4" type="button" onClick={() => { openModal(); }}>Create my account</button>
        </div>
      </div>
    </div>
  </div>
);

export default GoogleCloudSignUpModal;
