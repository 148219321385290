import { ReactComponent as Polygon } from '../../../assets/icons/chainLogo/chain-polygon.svg';
import { ReactComponent as Ethereum } from '../../../assets/icons/chainLogo/chain-ethereum.svg';
import { ReactComponent as Linea } from '../../../assets/icons/chainLogo/chain-linea.svg';
import { ReactComponent as Flow } from '../../../assets/icons/chainLogo/chain-flow.svg';
import { ReactComponent as Near } from '../../../assets/icons/chainLogo/chain-near.svg';
import { ReactComponent as Base } from '../../../assets/icons/chainLogo/chain-base.svg';
import { ReactComponent as PolygonTestnet } from '../../../assets/icons/chainLogo/polygon-test.svg';
import { ReactComponent as EthereumTestnet } from '../../../assets/icons/chainLogo/ethereum-test.svg';
import { ReactComponent as BaseTestnet } from '../../../assets/icons/chainLogo/base-test.svg';
import { ReactComponent as LineaTestnet } from '../../../assets/icons/chainLogo/linea-test.svg';
import { ReactComponent as NearTestnet } from '../../../assets/icons/chainLogo/near-test.svg';

export const supportedChains = {
  polygon: {
    icon: Polygon,
    tooltip: 'Polygon',
  },
  matic: {
    icon: Polygon,
    tooltip: 'Polygon',
  },
  'polygon-pos': {
    icon: Polygon,
    tooltip: 'Polygon',
  },
  amoy: {
    icon: PolygonTestnet,
    tooltip: 'Amoy (Polygon Testnet)',
  },
  ethereum: {
    icon: Ethereum,
    tooltip: 'Ethereum',
  },
  homestead: {
    icon: Ethereum,
    tooltip: 'Ethereum',
  },
  sepolia: {
    icon: EthereumTestnet,
    tooltip: 'Sepolia (Ethereum Testnet)',
  },
  'eth-sepolia': {
    icon: EthereumTestnet,
    tooltip: 'Sepolia (Ethereum Testnet)',
  },
  base: {
    icon: Base,
    tooltip: 'Base',
  },
  'base-sepolia': {
    icon: BaseTestnet,
    tooltip: 'Sepolia (Base Testnet)',
  },
  near: {
    icon: Near,
    tooltip: 'Near',
  },
  'near-protocol': {
    icon: Near,
    tooltip: 'Near',
  },
  testnet: {
    icon: NearTestnet,
    tooltip: 'Near Testnet',
  },
  linea: {
    icon: Linea,
    tooltip: 'Linea',
  },
  'linea-sepolia': {
    icon: LineaTestnet,
    tooltip: 'Sepolia (Linea Testnet)',
  },
  flow: {
    icon: Flow,
    tooltip: 'Flow',
  },
};

export const blockChainOptions = [
  {
    label: 'Ethereum',
    value: 'ethereum',
    network: 'homestead',
    networkId: 1,
    chainId: '0x1',
  },
  {
    label: 'Polygon',
    value: 'polygon',
    network: 'matic',
    networkId: 137,
    chainId: '0x89',
  },
  {
    label: 'Base',
    value: 'base',
    network: 'base',
    networkId: 8453,
    chainId: '0x2105',
  },
  {
    label: 'Linea',
    value: 'linea',
    network: 'linea',
    networkId: 59144,
    chainId: '0xe708',
  },
];

export const blockChainOptionsWithTestnets = [
  {
    label: 'Ethereum',
    value: 'ethereum',
    network: 'homestead',
    networkId: 1,
    chainId: '0x1',
    testnet: false,
  },
  {
    label: 'Sepolia',
    value: 'eth-sepolia',
    network: 'sepolia',
    networkId: 11155111,
    chainId: '0xaa36a7',
    testnet: true,
  },
  {
    label: 'Polygon',
    value: 'polygon',
    network: 'matic',
    networkId: 137,
    chainId: '0x89',
    testnet: false,
  },
  {
    label: 'Amoy',
    value: 'amoy',
    network: 'amoy',
    networkId: 80002,
    chainId: '0x13882',
    testnet: true,
  },
  {
    label: 'Base',
    value: 'base',
    network: 'base',
    networkId: 8453,
    chainId: '0x2105',
    testnet: false,
  },
  {
    label: 'Sepolia',
    value: 'base-sepolia',
    network: 'base-sepolia',
    networkId: 84532,
    chainId: '0x14a34',
    testnet: true,
  },
  {
    label: 'Linea',
    value: 'linea',
    network: 'linea',
    networkId: 59144,
    chainId: '0xe708',
    testnet: false,
  },
  {
    label: 'Sepolia',
    value: 'linea-sepolia',
    network: 'linea-sepolia',
    networkId: 59141,
    chainId: '0xe705',
    testnet: true,
  },

];
