import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSelectedAsset } from '../../../../../store/reducers/app';
import uniqueId from '../../../../../utils/uniqueId';
import { supportedBlockchains } from '../../../../../utils/supportedBlockchains';
import LogoDisplay from '../LogoDisplay';
import Verification from '../../../Verification';
import ItemPreview from '../../../ItemPreview';
import ChainList from '../../../../base/ChainLogo/ChainList';
import styles from '../List/List.module.scss';
import AddAssetsFromHeader from '../AddAssetsFromHeader';
import { getIsSearchModalOpen } from '../../../../../store/reducers/search';

const SearchList = ({
  filterDataList, setShowModal, refetchNftInAsset, refetchTokenInAsset,
}) => {
  const dispatch = useDispatch();

  const handleUrl = (assetId, category, network) => {
    if (category === 'Wallet') {
      return `/profile/${assetId.id}`;
    }
    if (category === 'NFT Collection') {
      return `/nfts/${network}/${assetId.id}`;
    }
    if (category === 'Token') {
      return `/coins/${assetId.id}`;
    }
    return '#';
  };

  const onUrlClick = useCallback((category, dispatchData) => {
    if (category === 'Wallet') {
      dispatch(setSelectedAsset({}));
    }
    if (category === 'NFT Collection') {
      dispatch(setSelectedAsset(dispatchData));
    }
    if (category === 'Token') {
      dispatch(setSelectedAsset(dispatchData));
    }
  }, [dispatch]);

  return (
    <div className={styles.search_list_wrapper}>
      <div className={styles.search_list_single_data_container}>
        <div className={`${styles.search_list_container} d-flex flex-column justify-content-center align-items-center`}>
          {filterDataList.map((data, index) => (
            <div key={uniqueId('search_list')} className="w-100">
              <div
                className={`
                ${styles.search_list_data_wrapper} cursor-pointer ${data?.category === 'Wallet' ? 'my-1' : ''}`}
              >
                <div className="d-flex justify-content-between">
                  <Link
                    to={handleUrl(
                      data.nameId,
                      data.category,
                      data?.platforms ? data?.platforms[0] : null,
                    )}
                    className={`${styles.data_body} d-flex gap-3 w-100`}
                    role="presentation"
                    onClick={() => {
                      onUrlClick(
                        data.category,
                        {
                          type: data.type,
                          name: data.name,
                          logo: data.img,
                          symbol: data.symbol,
                          network: data?.platforms,
                        },
                      );
                      setShowModal(false);
                      dispatch(getIsSearchModalOpen(false));
                    }}
                  >
                    <div className={`
                      ${styles.search_list_image_wrapper}
                      position-relative d-flex justify-content-center`}
                    >
                      <div
                        className={`
                          ${styles.search_list_image_wrapper}
                          position-relative d-flex align-items-center justify-content-center`}
                        data-for={`preview_${data.nameId.id}`}
                        data-tip="show"
                      >
                        <LogoDisplay logo={data.img} asset={data.category} type={data.type} />
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center position-relative">
                      <div
                        className={`
                        ${data.type === 'wallet' ? styles.profile : styles.search_list_data_title}
                        text-truncate d-flex
                        ${data.type === 'wallet' ? 'flex-column' : 'flex-row'}
                        ${data.type !== 'wallet' ? 'align-items-center' : ''}
                        flex-wrap
                        `}
                      >
                        <div
                          data-for={`preview_${data.nameId.id}`}
                          data-tip="show"
                        >
                          <div
                            className={`${styles.alias} d-flex justify-content-start`}
                          >
                            {data.alias}
                          </div>
                          <div
                            className={`
                              text-truncate
                              ${styles.name}
                              ${data.type === 'wallet' && !data.alias ? styles.name_bold : ''}
                            `}
                          >
                            {
                              data.name ? (
                                data.name.length > 50 ? `${data.name.slice(0, 50)}...` : data.name
                              ) : data.id
                            }
                          </div>
                        </div>
                        <div className={styles.symbol}>
                          {data.symbol ? `($${data.symbol})` : ''}
                        </div>
                        {data.verified
                          ? <Verification className="ms-1" width="16px" height="16px" />
                          : null}
                      </div>
                      {data.transcript
                        ? <div className={styles.transcript}>{data.transcript}</div>
                        : null}
                      <div className={`${styles.search_list_stats_container} d-flex align-items-center`}>
                        <p className={`${styles.search_list_stats_value} pb-1 m-0`}>
                          {data.value}
                          {' '}
                          <span>
                            {data.boldValue}
                          </span>
                        </p>
                        <p className={`${styles.search_list_stats_value} pb-1 m-0`}>
                          {data.floor}
                          {' '}
                          <span>
                            {data.boldFloor}
                          </span>
                        </p>
                        {data.boldHolders ? (
                          <p className={`${styles.search_list_stats_value} pb-1 m-0`}>
                            {data.holders}
                            {' '}
                            <span>
                              {data.boldHolders}
                            </span>
                          </p>
                        ) : ''}
                      </div>
                    </div>
                  </Link>
                  <div className={`d-flex align-items-start ms-auto pb-1 gap-1
                    ${data.type === 'wallet' ? '' : 'justify-content-between w-25'}`}
                  >
                    <div className="d-flex align-items-center">
                      <div className={`
                          ${styles.pills}
                          ${data.category.includes('Token') ? styles.red : ''}
                          px-3 d-flex justify-content-center text-truncate align-items-center mx-1`}
                      >
                        {data.category}
                      </div>
                      {data?.platforms?.length ? (
                        <div className={styles.separator_icon} />)
                        : null}
                    </div>
                    {data?.platforms?.length ? (
                      <ChainList chains={supportedBlockchains(data.platforms)} />
                    ) : null}
                    {(data.type === 'nft' || data.type === 'token')
                    && (
                      <div className={`${styles.addButtonList} d-flex gap-1 align-items-center`}>
                        <AddAssetsFromHeader
                          type={data.type === 'nft' ? data.type : 'coin'}
                          data={data.onDashboard}
                          name={data.nameId.id}
                          network={data.platforms?.[0]}
                          refetchNftInAsset={refetchNftInAsset}
                          refetchTokenInAsset={refetchTokenInAsset}
                        />
                      </div>
                    )}
                  </div>
                  {data.type === 'nft' || data.type === 'wallet'
                    ? (
                      <ItemPreview
                        id={`preview_${data.nameId.id}`}
                        data={data}
                      />
                    )
                    : null}
                </div>
              </div>
              { filterDataList.length - 1 !== index && <div className="dropdown-divider w-100 m-1" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchList;
