import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import TitleSocials from './TitleSocials/TitleSocials';
import { useWindowSize } from '../../../hooks/app';
import { coinApi } from '../../../../api/coin';
import { selectSelectedAsset, setAssetSlug } from '../../../../store/reducers/app';
import ToDashboardButton from '../ToDashboardButton';
import AirdropButton from '../AirdropButton';
import { LoadingAvatar, LoadingLines } from '../../modals/SearchModal/LoadingList';
import AddressesModal from '../../NotableInvestments/AddressesModal';
import Verification from '../../Verification';
import DefaultIcon from '../../DefaultIcon';
import { showSuccessMessage } from '../../../base/Notifications';
import Tooltip from '../../Tooltip';
import ChainLogo from '../../../base/ChainLogo';
import { shortenAddress } from '../../../../utils/shortenAddress';
import { ReactComponent as NonTransferableIcon } from '../../../../assets/icons/non_transferable_circle.svg';
import styles from '../TitleSection.module.scss';
import { getIsSearchModalOpen, setContractDeployer } from '../../../../store/reducers/search';
import { userApi } from '../../../../api/user';
import { ReactComponent as InfoSmall } from '../../../../assets/icons/info_small.svg';
import { supportedChains } from '../../../base/ChainLogo/chains';

const SingleNFTTitle = ({ isAssetLoading, singleNftData }) => {
  const dispatch = useDispatch();
  const selectedAsset = useSelector(selectSelectedAsset);

  const [readMoreIndicator, setReadMoreIndicator] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [data, setData] = useState({});

  const { width } = useWindowSize();

  const { pathname } = useLocation();

  const {
    data: deployer,
  } = coinApi.useGetNFTDeployerQuery({
    network: pathname.split('/')[2],
    address: pathname.split('/')[3],
  });

  const {
    data: nftInAsset,
    isLoading,
    isFetching,
  } = userApi.useGetNFTsInAssetsQuery([{
    address: pathname.split('/')[3],
    network: pathname.split('/')[2],
  }]);

  useEffect(() => {
    if (isAssetLoading) {
      setReadMoreIndicator(false);
      setImgError(false);
    }
  }, [isAssetLoading]);

  useEffect(() => {
    if (isAssetLoading && !isLoading && !isFetching) {
      setData({});
    } else if (singleNftData && !isAssetLoading && !isLoading && !isFetching) {
      setData(singleNftData);
    } else if (Object.keys(selectedAsset).length && !isAssetLoading && !isLoading && !isFetching) {
      const selectedAssetRevised = {
        ...selectedAsset,
        network: selectedAsset.network[0],
      };
      setData(selectedAssetRevised);
    }
  }, [isAssetLoading, selectedAsset, singleNftData, isLoading, isFetching]);

  const deployerContent = useMemo(() => {
    if (deployer && !isAssetLoading && !isLoading && !isFetching && Object.keys(data).length > 0) {
      if (deployer?.deployer_account) {
        const infoTransferable = data?.transferable ? 'Transferable. ' : 'Non-transferable. ';
        const infoMaxNFT = data?.maxSupply
          ? `${data?.maxSupply} NFT${data?.maxSupply > 1 ? 's' : ''} Max` : 'No Max NFTs';
        return (
          <div className={`${styles.description} ${data?.contractCount > 1 ? 'mt-2' : ''}`}>
            Deployed by
            {' '}
            {deployer.deployer_account}
            {' '}
            with
            {' '}
            <span
              role="presentation"
              onClick={() => {
                dispatch(getIsSearchModalOpen(true));
                dispatch(setContractDeployer(deployer?.deployer_address));
              }}
            >
              {shortenAddress(deployer.deployer_address)}
            </span>
            {' '}
            on
            {' '}
            {format(new Date(deployer.deployed_at), 'MMM dd, yyyy')}
            {' '}
            <InfoSmall data-for="CustomNFTInfo" data-tip />
            <Tooltip
              id="CustomNFTInfo"
              info={infoTransferable + infoMaxNFT}
            />

          </div>
        );
      }
      return (
        <div className={`${styles.description} ${data?.contractCount > 1 ? 'mt-2' : ''}`}>
          {deployer?.show_first ? 'First deployed by' : 'Deployed by'}
          {' '}
          <span
            role="presentation"
            onClick={() => {
              dispatch(getIsSearchModalOpen(true));
              dispatch(setContractDeployer(deployer?.deployer_address));
            }}
          >
            {shortenAddress(deployer.deployer_address)}
          </span>
          {' '}
          on
          {' '}
          {format(new Date(deployer?.deployed_at), 'MMM dd, yyyy')}
        </div>
      );
    }
    return null;
  }, [deployer, dispatch, isAssetLoading, isLoading, isFetching, data]);

  const descriptionContent = useMemo(() => {
    if (data?.description && Object.keys(data).length > 0) {
      return (
        <div className={`${styles.row} ${styles.desription_length} ${deployer ? 'mt-2' : ''}`}>
          <p className={styles.description}>
            {data.description.length > 85 ? !readMoreIndicator ? (
              <>
                {data.description.slice(0, 85)}
                ...
                {' '}
                <span
                  role="presentation"
                  onClick={() => setReadMoreIndicator(true)}
                >
                  Read More
                </span>
              </>
            ) : (
              <>
                {data.description}
                {' '}
                <span
                  role="presentation"
                  onClick={() => setReadMoreIndicator(false)}
                >
                  Read Less
                </span>
              </>
            ) : data.description}
          </p>
        </div>
      );
    }
    return null;
  }, [data?.description, deployer, readMoreIndicator, data]);

  const handleCopyClick = useCallback((network) => {
    navigator.clipboard.writeText(data?.id)
      .then(() => {
        showSuccessMessage(`${supportedChains[network].tooltip} address was copied to your clipboard`);
      });
  }, [data?.id]);

  const iconContent = useMemo(() => {
    if (data?.network) {
      const network = Array.isArray(data.network) ? data.network[0] : data.network;
      return (
        <ChainLogo
          chain={network}
          tooltip="Click to copy address"
          onClick={() => handleCopyClick(data.network)}
        />
      );
    }
  }, [data?.network, handleCopyClick]);

  return (
    <div className={`${styles.nftWrapper} ${styles.defaultHeight}`}>
      {data.slug && data.contractCount > 1 && <AddressesModal />}
      {width >= 640 && (
        <div className={styles.imageContainer}>
          {!data.logo
            ? <LoadingAvatar size={75} />
            : data.logo && !imgError
              ? (
                <img
                  src={data.logo}
                  width="75"
                  height="75"
                  alt="logo"
                  onError={() => setImgError(true)}
                />
              )
              : <DefaultIcon type="nft" size="75px" />}
        </div>
      )}
      <div className="d-flex flex-column">
        <div className={styles.row}>
          {width < 640 && (
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex align-items-end">
                <div className={styles.imageContainer}>
                  {!data.logo && isAssetLoading
                    ? <LoadingAvatar size={75} />
                    : data.logo && !imgError
                      ? (
                        <img
                          src={data.logo}
                          width="75"
                          height="75"
                          alt="thumb"
                          onError={() => setImgError(true)}
                        />
                      )
                      : <DefaultIcon type="nft" size="75px" />}
                </div>
                <div className="d-flex mx-3 gap-1 align-items-start">
                  {data.symbol
                    ? (
                      <p className={styles.token}>
                        {`$${data.symbol}`}
                      </p>
                    )
                    : null}
                </div>
              </div>
              {data.network
                ? (
                  <div className="d-flex gap-1 mt-3">
                    <div
                      className={`${styles.nft_pill} px-3 d-flex align-items-center align-self-start`}
                    >
                      NFT Collection
                    </div>
                    <div className={`${styles.separator_icon} mx-1`} />
                    {data?.transferable === false && (
                      <>
                        <div
                          className="d-flex align-content-center align-self-baseline"
                          data-for="transferable"
                          data-tip
                        >
                          <NonTransferableIcon />
                        </div>
                        <Tooltip
                          id="transferable"
                          info="This asset cannot be transferred"
                          position="unset"
                        />
                      </>
                    )}
                    <ChainLogo chain={data.network} />
                  </div>
                )
                : null}
            </div>
          )}
          {isAssetLoading || !data.name || Object.keys(data).length === 0
            ? (
              <div className="w-25">
                <LoadingLines align="start" />
              </div>
            )
            : (
              <div className="d-flex gap-2 align-items-center">
                <p
                  className={`${styles.title} ${data.name?.length > 10 && styles.titleAdaptive}`}
                >
                  {data.name}
                </p>
                {data.isVerified ? <Verification /> : null}
              </div>
            )}
          <div className={styles.flexAlignRight}>
            {!singleNftData?.isCustomContract && (
              <ToDashboardButton
                type="nft"
                data={nftInAsset}
                loading={isLoading || isFetching || isAssetLoading}
              />
            )}
            <AirdropButton
              data={nftInAsset}
              id={singleNftData?.id_obj}
              object={singleNftData}
              loading={isLoading || isFetching || isAssetLoading}
              display={singleNftData?.isCustomContract}
            />
          </div>
        </div>
        <div className={`${styles.row} ${styles.nftWrapper} d-flex`}>
          {width > 640 && (
            <>
              {data.symbol
                ? (
                  <p className={styles.token}>
                    {`$${data.symbol}`}
                  </p>
                )
                : null}
              {data.network
                ? (
                  <div className="d-flex align-items-center align-self-start gap-2">
                    <div
                      className={`${styles.nft_pill} px-3 d-flex align-items-center align-self-start`}
                    >
                      NFT Collection
                    </div>
                    <div className={`${styles.separator_icon} d-flex align-self-baseline mx-1`} />
                    {data?.transferable === false && (
                      <>
                        <div
                          className="d-flex align-content-center align-self-baseline"
                          data-for="transferable"
                          data-tip
                        >
                          <NonTransferableIcon />
                        </div>
                        <Tooltip
                          id="transferable"
                          info="This asset cannot be transferred"
                          position="unset"
                        />
                      </>
                    )}
                    <div className="d-flex" role="presentation">
                      {iconContent}
                    </div>
                  </div>
                )
                : null}
              {data.links
                ? (
                  <div className={`${styles.icons} ${styles.flexAlignRight}`}>
                    <TitleSocials links={data.links} />
                  </div>
                )
                : null}
            </>
          )}
        </div>
        {
          data.slug && data.contractCount > 1 ? (
            <div className={`${styles.collection} mb-1`}>
              A sub-collection of
              {' '}
              <span
                role="presentation"
                className="text-decoration-underline cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#exModal"
                onClick={() => dispatch(setAssetSlug({
                  slug: data.slug,
                  name: data.collectionsName,
                  logo: data.logo,
                  symbol: data.symbol,
                  network: data.network,
                }))}
              >
                {data.collectionsName}
              </span>
            </div>
          ) : null
        }
        {deployerContent}
        {descriptionContent}
        {data.links
          ? (
            <div className={styles.row}>
              {width < 640 && (
                <div className={`${styles.icons} ${styles.flexAlignRight}`}>
                  <TitleSocials links={data.links} />
                </div>
              )}
            </div>
          )
          : null}
      </div>
    </div>
  );
};

export default SingleNFTTitle;
