import React from 'react';
import { ReactComponent as AlignLeft } from '../../../../../../assets/icons/forms/align_button_left_raw.svg';
import { ReactComponent as AlignCenter } from '../../../../../../assets/icons/forms/align_button_center_raw.svg';
import { ReactComponent as AlignRight } from '../../../../../../assets/icons/forms/align_button_right_raw.svg';

export const alignmentOptions = [
  {
    value: 'left',
    elem: <AlignLeft />,
  },
  {
    value: 'center',
    elem: <AlignCenter />,
  },
  {
    value: 'right',
    elem: <AlignRight />,
  },
];
