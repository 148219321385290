import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import { ReactComponent as Copy } from '../../../../../assets/icons/copy.svg';
import { ReactComponent as Warning } from '../../../../../assets/icons/flows/gray_warning.svg';
import styles from './WebHookESNodeSettings.module.scss';
import Tooltip from '../../../../../components/ui/Tooltip';
import { showSuccessMessage } from '../../../../../components/base/Notifications';
import EditableTitle from '../../../../../components/base/EditableTitle';

const WebHookESNodeSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [titleValue, setTitleValue] = useState('');

  const link = import.meta.env.VITE_APP_WEBHOOK_ENTRY_SOURCE_LINK;
  const flowId = pathname.split('/')[2];

  const {
    handleSubmit, setValue, reset,
  } = useForm();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const hookInfo = { ...submitData };
    if (titleValue) {
      hookInfo.name = titleValue;
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...hookInfo, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setTitleValue(configList[index].name);
        setValue('name', configList[index].name);
        setValue('url', configList[index].url);
      } else {
        setTitleValue('');
      }
    }
  }, [activeSettings.node_id, configList, reset, setValue]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="New webhook source"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <div className={styles.endpoint}>
            <div className={`${styles.block_header} mb-1`}>
              Endpoint to call:
            </div>
            <div className={`${styles.link} d-flex justify-content-between align-items-center mb-1`}>
              <span>{flowId ? `${link}${flowId}/` : `${link}id`}</span>
              <div data-for="copy_1" data-tip>
                <Copy
                  width="32px"
                  height="32px"
                  className="cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(flowId ? `${link}${flowId}/` : `${link}id`);
                    showSuccessMessage('Link was copied to your clipboard');
                  }}
                />
              </div>
              <Tooltip id="copy_1" info="Copy to clipboard" position="unset" />
            </div>
            {disableChangeStatus
              ? null : (
                <div className="d-flex gap-2 align-items-center">
                  <Warning />
                  <span className={styles.id_warning}>
                    id will appear when you save Flow as a draft or Run the flow
                  </span>
                </div>
              )}
          </div>
          <div>
            <div className={`${styles.block_header} mb-1`}>
              Request body example:
            </div>
            <div className={styles.body}>
              <div className={styles.row}>[</div>
              <div className={`${styles.row} ps-2`}>&quot;0xaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa&quot;,</div>
              <div className={`${styles.row} ps-2`}>&quot;0xbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb&quot;,</div>
              <div className={`${styles.row} ps-2`}>&quot;0xccccccccccccccccccccccccccccccccccccccc&quot;</div>
              <div className={styles.row}>]</div>
              <span className="d-flex w-100 justify-content-end">
                <div data-for="copy_2" data-tip>
                  <Copy
                    width="32px"
                    height="32px"
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(`[
                        "0xaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                        "0xbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb",
                        "0xccccccccccccccccccccccccccccccccccccccc"
                        ]`);
                      showSuccessMessage('The request body was copied to your clipboard');
                    }}
                  />
                </div>
                <Tooltip id="copy_2" info="Copy to clipboard" position="unset" />
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex gap-4 mt-4">
          <button
            type="button"
            className={`${styles.btn_cancel}`}
            onClick={() => dispatch(clearActiveSettings())}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="regular-button w-100"
            disabled={disableChangeStatus}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default WebHookESNodeSettings;
