import React, { useEffect, useState } from 'react';
import uniqueId from '../../../utils/uniqueId';
import Users from '../../../assets/icons/trackedWebsite/Users.svg';
import Whales from '../../../assets/icons/insights/Whales.svg';
import Veteran from '../../../assets/icons/insights/Veteran.svg';
import BuyingPower from '../../../assets/icons/insights/BuyingPower.svg';
import CumulatedPortfolioValue from '../../../assets/icons/trackedWebsite/CumulatedPortfolioValue.svg';
import WidgetCard from '../WidgetCard';
import {
  getDataWithPercent, getEthPrice, getFormatedNumber, getGrowth,
} from '../../../utils/values';

const TopStats = ({ data, isLoading }) => {
  const [widgetData, setWidgetData] = useState([]);
  useEffect(() => {
    if (data && !isLoading) {
      setWidgetData([
        {
          title: 'Wallet Count',
          value: getFormatedNumber(data?.wallet_count || 0),
          icon: Users,
          growth: getGrowth(
            data?.wallet_count,
            data?.previous_values?.wallet_count,
            getFormatedNumber(data?.previous_values?.wallet_count),
          ),
        },
        {
          title: 'Total Portfolio Value',
          value: getEthPrice(data?.total_portfolio_value, true),
          icon: CumulatedPortfolioValue,
          growth: getGrowth(
            data?.total_portfolio_value,
            data?.previous_values?.total_portfolio_value,
            getEthPrice(data?.previous_values?.total_portfolio_value),
          ),
        },
        {
          title: 'Total Buying Power',
          value: getEthPrice(data?.total_buying_power, true),
          icon: BuyingPower,
          growth: getGrowth(
            data?.total_buying_power,
            data?.previous_values?.total_buying_power,
            getEthPrice(data?.previous_values?.total_buying_power),
          ),
        },
        {
          title: 'Whales',
          value: getDataWithPercent(data?.count_whales, data?.wallet_count),
          icon: Whales,
          info: 'Wallets with more than 1M USD of portfolio value',
          growth: getGrowth(
            data?.count_whales,
            data?.previous_values?.count_whales,
            getFormatedNumber(data?.previous_values?.count_whales),
          ),
        },
        {
          title: 'Veterans',
          value: getDataWithPercent(data?.count_veterans, data?.wallet_count),
          icon: Veteran,
          info: 'Wallets which were first active before 2020',
          growth: getGrowth(
            data?.count_veterans,
            data?.previous_values?.count_veterans,
            getFormatedNumber(data?.previous_values?.count_veterans),
          ),
        },
      ]);
    }
  }, [data, isLoading]);

  return (
    <div className="d-flex justify-content-between gap-4">
      {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
        <WidgetCard
          key={uniqueId('website-widget')}
          icon={item.icon}
          info={item.info}
          growth={item.growth}
          title={item.title}
          value={item.value}
          isLoading={isLoading || item.isLoading}
        />
      ))}
    </div>
  );
};

export default TopStats;
