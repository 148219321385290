import React from 'react';
import { ReactComponent as EditFolderIcon } from '../../../assets/icons/folders/edit_folder.svg';
import styles from './editableFoldersList.module.scss';

const EditButton = ({ onClick }) => (
  <button type="button" onClick={onClick} className={styles.edit_button}>
    <EditFolderIcon />
  </button>
);

export default EditButton;
