import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Handle, Position } from 'react-flow-renderer';
import styles from './RandomSplitNode.module.scss';
import { ReactComponent as RandomSplit } from '../../../../../assets/icons/flows/random_icon.svg';
import { handle, handleInput } from '../../../../../utils/flows';
import { ReactComponent as Delete } from '../../../../../assets/icons/flows/delete.svg';
import { ReactComponent as Warning } from '../../../../../assets/icons/flows/warning.svg';
import { ReactComponent as Alert } from '../../../../../assets/icons/flows/alert_triangle.svg';
import { selectFlowStatus, selectRandomSplitValue } from '../../../../../store/reducers/flows';
import Tooltip from '../../../../../components/ui/Tooltip';
import uniqueId from '../../../../../utils/uniqueId';

const tooltipInfo = {
  warning: '% of addresses wouldn’t go further',
  alert: 'The sum of percentages on the connections should be less than 100 or equal to 100',
};

const RandomSplitNode = ({ onAlertMouseEnter, props }) => {
  const [onNodeHover, setOnNodeHover] = useState(false);
  const status = useSelector(selectFlowStatus);
  const value = useSelector(selectRandomSplitValue);
  const tooltipId = uniqueId('random');

  return (
    <div className="position-relative">
      {value[props.id]?.value && value[props.id].value !== 100
        ? (
          <div className={`${styles.tooltip} position-absolute`}>
            <div
              data-tip
              data-for={tooltipId}
              onMouseEnter={() => onAlertMouseEnter(props.id)}
            >
              {value[props.id].value > 100
                ? <Alert />
                : <Warning />}
            </div>
            <Tooltip
              fixWidth
              id={tooltipId}
              info={value[props.id].value > 100
                ? tooltipInfo.alert
                : `${100 - value[props.id].value} ${tooltipInfo.warning}`}
            />
          </div>
        )
        : null}
      <div
        className={`${styles.wrapper} d-flex justify-content-center align-items-center`}
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
      >
        <RandomSplit />
        {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
          ? (
            <div className={onNodeHover ? styles.delete : styles.delete_hide}>
              <Delete onClick={() => props.data.onRemove(props.id)} />
            </div>
          )
          : null}
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ ...handleInput, left: '-21px' }}
        id="randomSplitNode_input_a"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ ...handle, right: '-27px' }}
        id="randomSplitNode_output_b"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...handle, bottom: '-27px' }}
        id="randomSplitNode_output_c"
      />
    </div>
  );
};

export default RandomSplitNode;
