import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { ethers } from 'ethers';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import clonedeep from 'lodash.clonedeep';

import { ReactComponent as Info } from '../../../../../../assets/icons/info.svg';
import { ReactComponent as WhiteInfo } from '../../../../../../assets/icons/white_info.svg';
import styles from './CustomAirdrop.module.scss';
import Tooltip from '../../../../../../components/ui/Tooltip';
import { flowsApi } from '../../../../../../api/flows';
import Spinner from '../../../../../../components/base/Spinner';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import { formatAbi } from '../../../../../../utils/airdrop/abiUtils';
import { AddressTextarea, MultiSelectInput } from './components';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import { preventInvalidSymbols } from '../../../../../../utils/segments';
import { blockChainOptionsWithTestnets } from '../../../../../../components/base/ChainLogo/chains';

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const CustomAirdrop = ({
  selectedBlockchain,
  setSelectedBlockchain,
  contractAddress,
  setContractAddress,
  etherscanStatus,
  setEtherscanStatus,
  abi,
  setAbi,
  contractFunctions,
  setContractFunctions,
  selectedFunction,
  setSelectedFunction,
  selectedParameters,
  setSelectedParameters,
  disableChangeStatus,
  activeSettings,
  setIsAddress,
  isRetrieving,
  setIsRetrieving,
  setValue,
}) => {
  const dispatch = useDispatch();

  const [constContract, setConstContract] = useState(contractAddress);
  const [error, setError] = useState(null);
  const [selectedContractFunctionName, setSelectedContractFunctionName] = useState(selectedFunction?.name || null);
  const [abiError, setAbiError] = useState({ show: false, error: null });
  const [localIsRetrieving, setLocalIsRetrieving] = useState(isRetrieving);

  const getCoinLabel = useCallback((val) => <IconNearby val={val} />, []);

  const generateInfo = useCallback((type) => {
    if (selectedFunction && contractAddress) {
      switch (type) {
        case 'address':
          return 'Single address';
        case 'address[]':
          return 'List of addresses';
        case 'uint256':
          return 'Single uint256';
        case 'uint256[]':
          return 'List of uint256';
      }
    }
  }, [contractAddress, selectedFunction]);

  const generateOptions = useCallback((type) => {
    if (selectedFunction && contractAddress) {
      switch (type) {
        case 'address[]':
          return [
            { label: 'One value per incoming wallet', value: 'incoming values' },
          ];
        case 'uint256[]':
          return [
            { label: 'One value per incoming wallet', value: 'incoming values' },
          ];
        default:
          return [
            { label: 'Use incoming wallets', value: 'incoming' },
            { label: 'Custom value', value: 'custom' },
          ];
      }
    }
  }, [contractAddress, selectedFunction]);

  useEffect(() => () => {
    setSelectedBlockchain(null);
    setContractAddress(null);
    setEtherscanStatus(null);
    setAbi(null);
    setContractFunctions(null);
    setSelectedFunction(null);
    setSelectedParameters(null);
    setIsAddress(false);
  }, [activeSettings, dispatch]);

  useEffect(() => {
    if (selectedContractFunctionName && contractFunctions) {
      setSelectedFunction(
        contractFunctions.filter((func) => func.name === selectedContractFunctionName)[0],
      );
    }
  }, [selectedContractFunctionName, setSelectedFunction, contractFunctions]);

  const clearUserContractData = () => {
    setAbi(null);
    setEtherscanStatus(null);
    setContractFunctions(null);
    setSelectedFunction(null);
    setSelectedParameters([]);
    setConstContract(null);
    setSelectedContractFunctionName(null);
  };

  const setIsLoading = (value) => {
    setLocalIsRetrieving(value);
    setIsRetrieving(value);
  };

  const checkIsContract = async (address) => {
    clearUserContractData(null);
    if (address === '') {
      setIsLoading(false);
      setError(null);
      return;
    }
    if (ethers.utils.isAddress(address)) {
      setIsAddress(true);
      try {
        setIsLoading(true);
        setError(null);
        const response = await dispatch(
          flowsApi.endpoints.getContractABI.initiate({
            address,
            blockchain: blockChainOptionsWithTestnets
              .find((elem) => elem.network === selectedBlockchain)?.value,
          }),
        );
        if (response?.error) {
          if (response.error?.data?.result === 'Contract source code not verified') {
            setIsLoading(false);
            setEtherscanStatus('manual');
            throw new Error('Cannot retrieve ABI, please insert it manually.');
          } else {
            throw new Error('Unexpected error, please try later.');
          }
        }
        const result = formatAbi(response.data?.result, 'json');
        setAbi(response.data?.result);
        if (result.error) {
          throw new Error(result.error);
        }
        setContractFunctions(result.data);
        setIsLoading(false);
      } catch (err) {
        showErrorMessage(err.message);
        setError(null);
        setIsRetrieving(false);
      }
    } else {
      setIsAddress(false);
      setIsLoading(false);
      setError('Invalid address format');
    }
  };

  const etherscanContent = useMemo(() => {
    if (etherscanStatus === 'manual') {
      return (
        <div>
          <div className={`${styles.label}`}>Contract ABI</div>
          <textarea
            className={`form-control p-2 ${styles.textarea} w-100`}
            onChange={(e) => {
              setError(null);
              setAbi(e.target.value);
              setSelectedFunction(null);
              setSelectedContractFunctionName(null);
              setContractFunctions([]);
              const result = formatAbi(e.target.value);
              if (result.error) {
                setAbiError({ show: false, error: result.error });
              }
              if (result.data) {
                setAbiError({ show: false, error: result.error });
                setContractFunctions(result.data);
              }
            }}
            onPaste={(e) => {
              setError(null);
              setAbi(e.target.value);
              setSelectedContractFunctionName(null);
              setSelectedFunction(null);
              setContractFunctions([]);
              const result = formatAbi(e.target.value);
              if (result.error) {
                setAbiError({ show: false, error: result.error });
              }
              if (result.data) {
                setAbiError({ show: false, error: result.error });
                setContractFunctions(result.data);
              }
            }}
            value={Array.isArray(abi) ? JSON.stringify(abi) : abi || ''}
            onBlur={() => {
              if (!abi) {
                setAbiError({ show: false, error: abiError?.error });
                return;
              }
              if (abiError?.error) {
                setAbiError({ show: true, error: abiError.error });
              }
            }}
            // eslint-disable-next-line max-len
            placeholder={'["inputs":.If"internalType":"address"," name": "to","type".    "type": "address"I], "name" ."mintTo" "outouts".Il."stat": I. "statMutability": "nonpayable","type":"function"}]'}
            rows={4}
            disabled={disableChangeStatus}
          />
          {abiError?.show && <div className={`px-2 ${styles.error}`}>{abiError?.error}</div>}
        </div>
      );
    }
  }, [
    etherscanStatus, abi, setAbi,
    setContractFunctions, abiError, disableChangeStatus,
    setSelectedFunction, setSelectedContractFunctionName,
  ]);

  const selectedOptionValueContent = useCallback((inputName, type, id) => {
    if (selectedParameters) {
      if (selectedParameters.some((param) => param.selectValue.value === 'custom' && param.name === inputName)) {
        if (['address'].includes(type)) {
          return (
            <AddressTextarea
              limit={1}
              walletList={selectedParameters.filter((param) => param.id === id)[0]?.value || []}
              setWalletList={(val) => {
                const selectedParameterToChange = clonedeep(selectedParameters.filter((param) => param.id === id)[0]);
                selectedParameterToChange.value = val;
                selectedParameterToChange.type = type;
                const restParameters = selectedParameters.filter((param) => param.id !== id);
                restParameters.push(selectedParameterToChange);
                setSelectedParameters(restParameters);
              }}
              name="wallets"
              placeHolder={selectedParameters.filter(
                (param) => param.id === id,
              )[0]?.value?.length >= 1 ? 'You have reached the limit' : 'Paste any addresses'}
              disabled={disableChangeStatus}
            />
          );
        }
        return (
          <div className="mt-2">
            <input
              onWheel={(e) => e.target.blur()}
              onKeyPress={(e) => { preventInvalidSymbols(e); }}
              min={0}
              type="number"
              className="form-control"
              onChange={(e) => {
                if (e.target.value.length > 22) return;
                if (/^0/.test(e.target.value)) {
                  e.target.value = e.target.value.replace(/^0/, '');
                }
                const selectedParameterToChange = clonedeep(selectedParameters.filter((param) => param.id === id)[0]);
                selectedParameterToChange.value = e.target.value;
                selectedParameterToChange.type = type;
                const restParameters = selectedParameters.filter((param) => param.id !== id);
                restParameters.push(selectedParameterToChange);
                setSelectedParameters(restParameters);
              }}
              value={selectedParameters.filter((param) => param.id === id)[0]?.value || ''}
              disabled={disableChangeStatus}
            />
          </div>
        );
      } if (selectedParameters.some((param) => param.selectValue.value !== 'custom' && param.name === inputName)) {
        if (['address[]'].includes(type)) {
          return (
            <AddressTextarea
              limit={undefined}
              walletList={selectedParameters.filter((param) => param.id === id)[0]?.value || []}
              setWalletList={(val) => {
                const selectedParameterToChange = clonedeep(selectedParameters.filter((param) => param.id === id)[0]);
                selectedParameterToChange.value = val;
                selectedParameterToChange.type = type;
                const restParameters = selectedParameters.filter((param) => param.id !== id);
                restParameters.push(selectedParameterToChange);
                setSelectedParameters(restParameters);
              }}
              name="wallets"
              placeHolder="Paste any addresses"
              disabled={disableChangeStatus}
            />
          );
        }
        if (['uint256[]'].includes(type)) {
          return (
            <div className="mt-2">
              <MultiSelectInput
                fromAirdrop
                isDisabled={disableChangeStatus}
                values={selectedParameters.filter((param) => param.id === id)[0]?.value || []}
                setValues={(val) => {
                  const selectedParameterToChange = clonedeep(selectedParameters.filter((param) => param.id === id)[0]);
                  selectedParameterToChange.value = val;
                  selectedParameterToChange.type = type;
                  const restParameters = selectedParameters.filter((param) => param.id !== id);
                  restParameters.push(selectedParameterToChange);
                  setSelectedParameters(restParameters);
                }}
              />
            </div>
          );
        }
      }
    }
    return null;
  }, [selectedParameters, setSelectedParameters, disableChangeStatus]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tip_text}>
        Call a custom function on any contract, passing incoming wallet addresses to it.
        {' '}
        <span
          role="presentation"
          className={styles.link}
          onClick={() => window.open(
            'https://docs.absolutelabs.io/features/flows/channels/airdrop/custom-contract-call',
            '_blank',
          )}
        >
          Learn more
        </span>
      </div>
      <div>
        <div className={styles.label}>Select blockchain</div>
        <Select
          styles={inputStyles}
          key="blockchains"
          value={
            (() => {
              const value = blockChainOptionsWithTestnets.find(
                (blockchain) => blockchain.network === selectedBlockchain,
              );
              return value || null;
            })()
          }
          onChange={(val) => {
            if (selectedBlockchain === val.network) {
              return;
            }
            setConstContract('');
            clearUserContractData();
            setEtherscanStatus('');
            setSelectedBlockchain(val.network);
            setContractAddress('');
            setValue('blockchain', val.value);
          }}
          getOptionLabel={(val) => getCoinLabel(val)}
          name="blockchains"
          options={blockChainOptionsWithTestnets}
          isDisabled={disableChangeStatus}
        />
      </div>
      {selectedBlockchain && (
        <div>
          <div className={styles.label}>Contract address</div>
          <input
            name="contract_address"
            value={contractAddress}
            placeholder="0x123..."
            onChange={(e) => {
              if (!e.target.value) {
                setConstContract('');
              }
              clearUserContractData();
              setContractAddress(e.target.value.trim());
            }}
            onBlur={() => {
              if (constContract !== contractAddress || error) {
                checkIsContract(contractAddress);
                setConstContract(contractAddress);
              }
            }}
            className={`form-control ${styles.input} w-100`}
            disabled={disableChangeStatus}
          />
          {error && <div className={`px-2 ${styles.error}`}>{error}</div>}
        </div>
      )}
      {localIsRetrieving && (
        <div className={styles.loader}>
          <Spinner />
          <div className={styles.loading_text}>Retrieving function list...</div>
        </div>
      )}
      {etherscanContent}
      {contractFunctions && (
        <div>
          <div className={`d-flex gap-2 align-items-center  ${styles.label}`}>
            Function to call
            <div
              className={`${styles.info_img} cursor-pointer`}
              data-for="function"
              data-tip
            >
              <Info />
            </div>
            <Tooltip
              info="The contract
              function will be called with provided parameters, passing 0
              as value of the transaction."
              id="function"
              position="left"
              truncate={false}
            />
          </div>
          <Select
            maxMenuHeight={150}
            styles={inputStyles}
            key="contract_functions"
            placeholder="Select.."
            value={selectedContractFunctionName ? ({
              value: selectedContractFunctionName || selectedFunction?.name,
              label: selectedContractFunctionName || selectedFunction?.name,
            }) : null}
            onChange={(val) => {
              setSelectedContractFunctionName(val.value);
              setSelectedParameters([]);
            }}
            name="contract_functions"
            options={contractFunctions?.length
              ? contractFunctions.map((func) => ({ label: func.name, value: func.name }))
              : []}
            isDisabled={disableChangeStatus}
          />
          {!selectedFunction && etherscanStatus !== 'manual' && (
            <div
              className={`${styles.tip_text} ${styles.manual}`}
              role="presentation"
              onClick={() => {
                setAbi('');
                setEtherscanStatus('manual');
                setContractFunctions(null);
              }}
            >
              <span className={styles.link}>or, input a function definition</span>
            </div>
          )}
        </div>
      )}

      {selectedFunction && (
        <div>
          <div className={`${styles.label}`}>Values to pass</div>
          <div className={`py-3 px-2 ${styles.function_wrapper}`}>
            {selectedFunction?.inputs.map((input, index) => (
              <div key={input.name} className={styles.input_container}>
                <div className={`px-1 d-flex gap-2 align-items-center  ${styles.label}`}>
                  {input.name || '*No name given'}
                  <div
                    className={`${styles.info_img} cursor-pointer`}
                    data-for={input.name}
                    data-tip
                  >
                    <Info />
                  </div>
                  <Tooltip
                    info={generateInfo(input.type)}
                    id={input.name}
                    position="center"
                    truncate={false}
                  />
                </div>
                <Select
                  styles={inputStyles}
                  placeholder="Select.."
                  value={selectedParameters?.filter((param) => param.id === index)[0]?.selectValue || undefined}
                  onChange={(val) => {
                    const params = selectedParameters
                      .filter((param) => param?.name !== input.name);
                    params.push({
                      id: index, selectValue: val, name: input.name, type: input.type,
                    });
                    setSelectedParameters(params);
                  }}
                  options={generateOptions(input.type)}
                  isDisabled={disableChangeStatus}
                />
                {selectedOptionValueContent(input.name, input.type, index)}
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedParameters?.length > 0
      && selectedParameters.every((param) => param?.selectValue?.value === 'custom')
      && selectedParameters.length === selectedFunction?.inputs?.length
              && (
                <div className={`${styles.custom_value_indicator} mt-3 p-2 d-flex gap-2 `}>
                  <WhiteInfo className={styles.white_info} />
                  <div>
                    You need to use the incoming wallets as
                    value into at least one parameter.
                  </div>
                </div>
              )}
      {selectedParameters?.length > 0
      && selectedParameters.filter((param) => (param?.selectValue?.value === 'incoming'
      && ['address', 'uint256'].includes(param?.type))).length > 1
              && (
                <div className={`${styles.custom_value_indicator} p-2 d-flex gap-2 `}>
                  <WhiteInfo className={styles.white_info} />
                  <div>
                    You can choose &quot;Use incoming wallets&quot; only in one of the parameters
                  </div>
                </div>
              )}
    </div>
  );
};

export default CustomAirdrop;
