import React, { useEffect, useState } from 'react';
import NoResult from '../../../assets/images/NoResuls.svg';
import styles from './SearchResult.module.scss';

const SearchResult = ({ type, search }) => {
  const [text, setText] = useState('');
  const [subText, setSubText] = useState(null);

  useEffect(() => {
    if (search) {
      setText(
        <div className={styles.text}>
          {`Sorry, we did not find any ${type}. Please try again.`}
        </div>,
      );
      setSubText(
        <div className={styles.text_grey}>
          <p>Check your spelling.</p>
          <p>Use a different keyword.</p>
        </div>,
      );
    } else {
      setText(
        <div className={styles.text}>
          {`There is no ${type} to display.`}
        </div>,
      );
      setSubText(
        <div className={styles.text_grey}>
          <p>{`Choose a different folder or create a new ${type}`}</p>
        </div>,
      );
    }
  }, [search]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center gap-3 m-4 h-40">
      <div>
        <img src={NoResult} alt="NoData" width="500px" height="200px" />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center gap-1">
        {text}
        {subText}
      </div>
    </div>
  );
};

export default SearchResult;
