import React, { useEffect, useState } from 'react';
import styles from './UniqueModal.module.scss';
import Switch from '../../Forms/CreateForm/Components/ModalSettings/Switch';
import { ReactComponent as Info } from '../../../assets/icons/info_small.svg';

const EmailToggle = ({ uniqueField, setUniqueField }) => {
  const [toggle, setToggle] = useState(false);

  const handleSwitch = () => {
    if (toggle) {
      setUniqueField('wallet address');
    } else {
      setUniqueField('email');
    }
  };

  useEffect(() => {
    setToggle(uniqueField === 'email');
  }, [uniqueField]);

  return (
    <div className={styles.toggle_wrapper}>
      <Switch
        text="Use email address instead"
        checked={toggle}
        onChange={() => handleSwitch()}
      />
      {toggle && (
        <div className={`${styles.info} d-flex gap-2`}>
          <div>
            <Info />
          </div>
          <div>
            All imported fields will update the existing profile using the matching email address. If that email address is not in the platform today, a new profile will be created.
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailToggle;
