import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Snapshot } from '../../../../assets/icons/table/snapshot.svg';
import { getCreatedSegmentId } from '../../../../store/reducers/segment';
import tableStyles from '../../../../components/base/Table/Table.module.scss';

const SnapshotElem = ({
  item, setSnapshotItem, disabled = false,
}) => {
  const dispatch = useDispatch();

  const [isDisable, setIsDisable] = useState(false);

  const segmentId = useSelector(getCreatedSegmentId);

  useEffect(() => {
    if (segmentId === item.id) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [dispatch, item?.id, segmentId]);

  return (
    <div
      className={`
        ${!isDisable ? 'cursor-pointer' : 'pe-none opacity-50'}
        d-flex
        justify-content-start
        align-items-center
        gap-1
        text-truncate
        ${tableStyles.modal_row}`}
      onClick={() => (isDisable || disabled ? {} : setSnapshotItem({ name: item.name, id: item.id }))}
      role="presentation"
    >
      <Snapshot />
      Take a Snapshot
    </div>
  );
};

export default React.memo(SnapshotElem);
