import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ChainList from '../../../../base/ChainLogo/ChainList';
import { setSelectedAsset } from '../../../../../store/reducers/app';
import LogoDisplay from '../LogoDisplay';
import Verification from '../../../Verification';
import ItemPreview from '../../../ItemPreview';
import uniqueId from '../../../../../utils/uniqueId';
import { supportedBlockchains } from '../../../../../utils/supportedBlockchains';
import AddAssetsFromHeader from '../AddAssetsFromHeader';
import { getIsSearchModalOpen } from '../../../../../store/reducers/search';
import styles from './List.module.scss';

const List = ({
  searchItems, setShowModal, displayList, setDisplayList, refetchNftInAsset,
  refetchTokenInAsset,
}) => {
  const dispatch = useDispatch();

  const [categoryName, setCategoryName] = useState('');

  const handleUrl = useCallback((assetId, category, network) => {
    if (category === 'Addresses' || category === 'Profiles') {
      return `/profile/${assetId.id}`;
    }
    if (category === 'NFT Collections') {
      return `/nfts/${network}/${assetId.id}`;
    }
    if (category === 'Tokens') {
      return `/coins/${assetId.id}`;
    }
    return '#';
  }, []);

  const onUrlClick = useCallback((category, dispatchData) => {
    if (category === 'Addresses') {
      dispatch(setSelectedAsset({}));
    }
    if (category === 'NFT Collections') {
      dispatch(setSelectedAsset(dispatchData));
    }
    if (category === 'Tokens') {
      dispatch(setSelectedAsset(dispatchData));
    }
  }, [dispatch]);

  const handleCheckbox = useCallback((category) => {
    const newDisplayList = [...displayList];
    if (category === 'NFT Collections') newDisplayList[1] = true;
    if (category === 'Tokens') newDisplayList[2] = true;
    if (category === 'Profiles') newDisplayList[3] = true;
    setDisplayList(newDisplayList);
  }, [displayList, setDisplayList]);

  return useMemo(() => {
    if (searchItems?.data?.length) {
      return (
        <div className={styles.search_list_single_data_container}>
          <div className={styles.search_list_title_text}>{searchItems?.name}</div>
          <div
            className={`${styles.search_list_container} d-flex flex-column justify-content-center align-items-center`}
          >
            {searchItems.data.slice(0, (!categoryName ? 3 : searchItems.data.length))
              .map((items) => (
                <div
                  className={`
                  ${styles.search_list_data_wrapper}
                   d-flex flex-column justify-content-center cursor-pointer w-100
                   position-relative
                   `}
                  key={uniqueId('assetFromSearch')}
                  role="presentation"
                >
                  <div className={`${styles.data_body} d-flex gap-3`}>
                    <Link
                      to={handleUrl(
                        items.nameId,
                        searchItems.name,
                        items?.platforms ? items?.platforms[0] : null,
                      )}
                      className="d-flex gap-3 w-100 user-select-none"
                      role="presentation"
                      onClick={() => {
                        onUrlClick(
                          searchItems.name,
                          {
                            type: items.type,
                            name: items.name,
                            logo: items.img,
                            symbol: items.symbol,
                            network: items.platforms,
                          },
                        );
                        setShowModal(false);
                        dispatch(getIsSearchModalOpen(false));
                      }}
                    >
                      <div
                        className={`
                          ${styles.search_list_image_wrapper}
                          position-relative d-flex align-items-center justify-content-center`}
                        data-for={`preview_${items.nameId.id}`}
                        data-tip="show"
                      >
                        <LogoDisplay logo={items.img} asset={searchItems.name} type={items.type} />
                      </div>
                      <div
                        className="d-flex flex-column justify-content-center position-relative w-100"
                      >
                        <div
                          className={`
                        ${items.type === 'wallet' ? styles.profile : styles.search_list_data_title}
                        text-truncate d-flex
                        ${items.type === 'wallet' ? '' : 'flex-row'}
                        ${items.type !== 'wallet' ? 'align-items-center' : ''}
                        flex-wrap
                        `}
                        >
                          <div
                            data-for={`preview_${items.nameId.id}`}
                            data-tip="show"
                          >
                            <div className={`${styles.alias} d-flex justify-content-start text-truncate`}>
                              {items.alias}
                            </div>
                            <div
                              className={`
                              ${styles.name}
                              text-truncate
                              ${items.type === 'wallet' && !items.alias ? styles.name_bold : ''}
                            `}
                            >
                              {
                                items.name ? (
                                  items.name.length > 50 ? `${items.name.slice(0, 50)}...` : items.name
                                ) : items.id
                              }
                            </div>
                          </div>
                          <div className={styles.symbol}>
                            {items.symbol ? `($${items.symbol})` : ''}
                          </div>
                          {items.verified
                            ? <Verification className="ms-1" width="16px" height="16px" />
                            : null}
                        </div>
                        {items.transcript
                          ? <div className={styles.transcript}>{items.transcript}</div>
                          : null}
                        {items.type !== 'wallet' && (
                          <div className={`${styles.search_list_stats_container} d-flex align-items-center`}>
                            <p className={`${styles.search_list_stats_value} pb-1 m-0`}>
                              {items.value}
                              {' '}
                              <span>
                                {items.boldValue}
                              </span>
                            </p>
                            <p className={`${styles.search_list_stats_value} pb-1 m-0`}>
                              {items.floor}
                              {' '}
                              <span>
                                {items.boldFloor}
                              </span>
                            </p>
                            {items.boldHolders ? (
                              <p className={`${styles.search_list_stats_value} pb-1 m-0`}>
                                {items.holders}
                                {' '}
                                <span>
                                  {items.boldHolders}
                                </span>
                              </p>
                            ) : ''}
                          </div>
                        )}
                      </div>
                    </Link>
                    <div className="ms-auto">
                      <img src={items.token} alt="" />
                    </div>
                    {items?.platforms?.length ? (
                      <ChainList chains={supportedBlockchains(items.platforms)} />
                    ) : null}
                    {(items.type === 'nft' || items.type === 'token')
                    && (
                      <div className={`${styles.addButton} d-flex gap-1 align-items-center`}>
                        <AddAssetsFromHeader
                          type={items.type === 'nft' ? items.type : 'coin'}
                          data={items.onDashboard}
                          name={items.nameId.id}
                          network={items.platforms?.[0]}
                          refetchNftInAsset={refetchNftInAsset}
                          refetchTokenInAsset={refetchTokenInAsset}
                        />
                      </div>
                    )}
                  </div>
                  {items.type === 'nft' || items.type === 'wallet'
                    ? (
                      <ItemPreview
                        id={`preview_${items.nameId.id}`}
                        data={items}
                        offsetPrev={{ left: -200 }}
                      />
                    )
                    : null}
                  <div className="dropdown-divider w-100" />
                </div>
              ))}
            { !categoryName && (
              <div
                role="presentation"
                className={styles.search_list_view_all}
                onClick={() => {
                  setCategoryName(searchItems.name);
                  handleCheckbox(searchItems.name);
                }}
              >
                View all
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <>
      </>
    );
  }, [categoryName, handleCheckbox, handleUrl, searchItems, refetchTokenInAsset, refetchNftInAsset]);
};

export default List;
