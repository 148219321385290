import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectButtonColor,
  selectButtonTextColor,
  selectBackgroundColor,
} from '../../../../../store/reducers/forms';
import { colorConfigurations } from './config';
import { ReactComponent as BlueCheck } from '../../../../../assets/icons/forms/checkedblue.svg';
import styles from './colorScheme.module.scss';
import settingsStyles from '../ModalSettings/ModalSettings.module.scss';

const ColorScheme = ({ handleConfigChange }) => {
  const [selectedConfig, setSelectedConfig] = useState(colorConfigurations[0]);
  const selectedButtonColor = useSelector(selectButtonColor);
  const selectedButtonTextColor = useSelector(selectButtonTextColor);
  const selectedBackgroundColor = useSelector(selectBackgroundColor);

  useEffect(() => {
    const configFromList = colorConfigurations.find(
      (config) => config.buttonColour.toLowerCase() === selectedButtonColor.toLowerCase()
      && config.buttonTextColour.toLowerCase() === selectedButtonTextColor.toLowerCase()
      && config.backgroundColour.toLowerCase() === selectedBackgroundColor.toLowerCase(),
    );

    if (configFromList) {
      setSelectedConfig(configFromList);
    } else {
      setSelectedConfig(null);
    }
  }, [selectedButtonColor, selectedButtonTextColor, selectedBackgroundColor]);

  useEffect(() => {
    if (handleConfigChange && selectedConfig) {
      handleConfigChange(selectedConfig);
    }
  }, [selectedConfig]);

  const handleColorClick = (config) => {
    if (selectedConfig.color !== config.color) {
      setSelectedConfig(config);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={settingsStyles.title}>Color Scheme</div>
      <div className={styles.colors_list}>
        {colorConfigurations.map((config) => (
          <div
            key={config.color}
            role="button"
            tabIndex={0}
            onClick={() => handleColorClick(config)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleColorClick(config);
              }
            }}
            className={`${styles.color_box} 
          ${selectedConfig?.color === config.color ? styles.selected : ''}`}
            style={{ backgroundColor: config.color }}
            aria-label={`Select color ${config.color}`}
          >
            {selectedConfig?.color === config.color && <BlueCheck className={styles.blueCheckedIcon} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorScheme;
