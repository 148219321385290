import React from 'react';
import NodataImg from '../../../assets/images/no-data.svg';
import styles from './NoData.module.scss';

const NoData = ({ isOnChart }) => (
  <div className="d-flex flex-column align-items-center justify-content-center gap-3 m-4 h-100">
    <div>
      <img src={NodataImg} alt="NoData" width="121px" height="100px" />
    </div>
    <div className="d-flex flex-column align-items-center justify-content-center gap-1">
      <div className={styles.text}>There is no data to display</div>
      <div className={styles.text_grey}>
        {isOnChart ? 'Try changing your period of analysis' : ''}
      </div>
    </div>
  </div>
);

export default NoData;
