/* eslint-disable max-len */
import React from 'react';

const ArrowDown = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.25151 7L3 8.0617L10 14.5L17 8.0617L15.7485 7L10 12.5L4.25151 7Z" fill={color || '#90A0B7'} />
  </svg>
);

export default ArrowDown;
