import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import styles from './CircleChart.module.scss';
import Loader from '../../base/Loader';
import { options, tooltip } from './ChartOptions';
import Highlight from './Highlight';
import Legend from './Legend';
import NoData from '../../base/NoData';
import CardTitle from '../CardTitle';

const CircleChart = ({
  title,
  info,
  highlightText,
  highlightValue,
  data,
  isLoading,
  className,
  landscape = false,
  noChart = false,
  sort = 'none',
  growth = null,
}) => {
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    if (sort === 'asc') {
      setSortedData(data.sort((a, b) => a.percent - b.percent));
    } else if (sort === 'desc') {
      setSortedData(data.sort((a, b) => b.percent - a.percent));
    } else {
      setSortedData(data);
    }
  }, [data, sort]);

  return (
    <div className={`${styles.wrapper} ${landscape ? styles.landscape : ''} ${className}`}>
      <CardTitle title={title} info={info} />
      {isLoading ? (
        <div className={`${styles.loader} d-flex justify-content-center align-items-center p-5 h-75`}>
          <Loader />
        </div>
      ) : (
        sortedData.length === 0 ? (
          <div>
            <NoData />
          </div>
        ) : (
          <>
            <Highlight text={highlightText} value={highlightValue} growth={growth} />
            <div className={`${styles.chart_wrapper} 
          ${landscape ? styles.landscape : ''} 
          ${noChart ? styles.chart : ''}
        `}
            >
              {!noChart
          && (
            <Chart
              options={
                {
                  ...options,
                  labels: sortedData.map((item) => tooltip(item.value, item.label, item.percent, item.count)),
                }
              }
              series={sortedData.map((elem) => elem.percent)}
              type="donut"
              height={180}
              className={styles.chart}
            />
          )}
              <Legend data={sortedData} className={styles.legend} />
            </div>
          </>
        )
      )}
    </div>
  );
};

export default CircleChart;
