import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import CloseModalButton from '../../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../../components/hooks/app/useKeydown';
import { setFormUrl } from '../../../../store/reducers/forms';

export const ConfirmSaveFormModal = ({
  onCancel, onSubmit, formurl, status,
}) => {
  const dispatch = useDispatch();

  useKeydown('Escape', onCancel);

  const generateRandomString = () => {
    const chars = 'abcdefghjkmnpqrstuvwxyz23456789';
    let resulting = '';
    for (let i = 0; i < 5; i += 1) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      resulting += chars[randomIndex];
    }
    return resulting;
  };

  useEffect(() => {
    if (!formurl || formurl === '') {
      const gen = generateRandomString();
      dispatch(setFormUrl(gen));
    }
  }, []);

  const handleSave = () => {
    onSubmit(status, true);
    onCancel();
  };

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Publish Form
            </div>
          </div>
          <div className={`${styles.description} mb-3`}>
            <div className={`${styles.subtitle} d-flex justify-content-start`}>
              Once published, a form cannot be changed.
            </div>
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            <button
              type="button"
              className="outline-button border border-1"
              onClick={onCancel}
            >
              Go Back
            </button>
            <button
              type="button"
              className="regular-button"
              onClick={handleSave}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};
