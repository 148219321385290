import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import emitter from '../../../utils/emitter';
import FolderButton from './FolderButton';
import FolderSettingsButton from './FolderSettingsButton';
import { ReactComponent as AllIcon } from '../../../assets/icons/folders/all.svg';
import { ReactComponent as MyIcon } from '../../../assets/icons/folders/my.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/folders/folder.svg';
import { ReactComponent as ClosedIcon } from '../../../assets/icons/collapse_closed.svg';
import { ReactComponent as OpenedIcon } from '../../../assets/icons/collapse_opened.svg';
import styles from './listWithFolders.module.scss';
import useSetSearchParams from '../../../utils/useSetSearchParams';

const CollapsableFolderButton = ({
  folder, type, addSubfolder, renameFolder, deleteFolder, foldersRef,
}) => {
  const setParams = useSetSearchParams();
  const [icon, setIcon] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [containerOverflow, setContainerOverflow] = useState('hidden');
  const [searchParams] = useSearchParams();
  const selectedFolder = searchParams.get('folder') || 'all';
  const marginTopValue = isOpen ? '0%' : `${-11 * folder.sub_folders.length * 1.9}%`;

  useEffect(() => {
    const listener = (parentId, eventType) => {
      if (parentId === folder.id && type === eventType) {
        setIsOpen(true);
      }
    };

    emitter.on('open_folder', listener);

    return () => {
      emitter.off('open_folder', listener);
    };
  }, []);

  useEffect(() => {
    if (!folder) {
      setIcon(null);
    } else if (folder.id === 'all') {
      setIcon(<AllIcon />);
    } else if (folder.id === 'my') {
      setIcon(<MyIcon />);
    } else {
      setIcon(<FolderIcon />);
    }
  }, [folder]);

  const handleCollapse = (e) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
  };

  const handleSelection = () => {
    setParams({
      folder: folder.id,
      page: 1,
    });
  };

  useEffect(() => {
    if (folder.sub_folders.length > 0
        // eslint-disable-next-line eqeqeq
        && folder.sub_folders.some((sf) => sf.id == selectedFolder)) {
      setIsOpen(true);
    }
  }, [selectedFolder]);

  useEffect(() => {
    let timer = null;
    if (isOpen) {
      timer = setTimeout(() => {
        setContainerOverflow('visible');
      }, 300);
    } else if (timer) {
      clearTimeout(timer);
      setContainerOverflow('hidden');
    }

    return () => {
      clearTimeout(timer);
      setContainerOverflow('hidden');
    };
  }, [isOpen]);

  return (
    <>
      <button
        // eslint-disable-next-line eqeqeq
        className={`nav-button ${selectedFolder == folder.id ? 'nb-selected' : ''}`}
        type="button"
        onClick={() => handleSelection()}
        style={{ position: 'relative' }}
      >
        <div className={styles.icon}>{icon}</div>
        <div className={styles.buttonText}>{folder.name}</div>
        {folder.id !== 'all' && folder.id !== 'my' && (
          <FolderSettingsButton
            folder={folder}
            addSubfolder={addSubfolder}
            renameFolder={renameFolder}
            deleteFolder={deleteFolder}
            type={type}
            foldersRef={foldersRef}
          />
        )}
        <div role="button" tabIndex={0} className={styles.collapsableButton} onClick={handleCollapse}>
          {isOpen ? <OpenedIcon height={9} width={9} /> : <ClosedIcon height={9} width={9} />}
        </div>
      </button>

      <div style={{ overflow: containerOverflow }}>
        <div
          className={`${styles.subfolders} ${isOpen ? styles.opened : ''}`}
          style={{ marginTop: marginTopValue }}
        >
          {folder.sub_folders.map((sf) => (
            <FolderButton
              key={sf.id}
              folder={sf}
              type={type}
              renameFolder={renameFolder}
              deleteFolder={deleteFolder}
              foldersRef={foldersRef}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CollapsableFolderButton;
