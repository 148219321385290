import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setActiveSettings,
  clearActiveSettings,
} from '../../../../../store/reducers/forms';
import { ReactComponent as Delete } from '../../../../../assets/icons/forms/trash_raw.svg';
import { ReactComponent as Edit } from '../../../../../assets/icons/forms/edit_raw.svg';
import { ReactComponent as Drag } from '../../../../../assets/icons/forms/drag_raw.svg';
import Tooltip from '../../../../../components/ui/Tooltip';
import styles from './ActionButtons.module.scss';

const ActionButtons = ({
  id, type, activeFieldId, isHovered, height, onRemove, listeners, attributes,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className={`
      ${styles.wrapper} 
      ${isHovered ? styles.visible : styles.hidden}`}
      style={{ height }}
    >
      <div
        data-for="delete"
        data-tip
      >
        <div
          role="button"
          tabIndex={0}
          className={`${styles.delete} mr-2`}
          onClick={() => {
            onRemove(id);
            if (activeFieldId === id) {
              dispatch(clearActiveSettings());
            }
          }}
        >
          <Delete />
        </div>
      </div>
      <Tooltip
        id="delete"
        info="Delete field"
      />

      <div
        data-for="edit"
        data-tip
      >
        <div
          role="button"
          tabIndex={0}
          className={`${styles.edit} mr-2`}
          onClick={() => { dispatch(setActiveSettings({ type, field_id: id })); }}
        >
          <Edit />
        </div>
      </div>
      <Tooltip
        id="edit"
        info="Edit field"
      />

      <div
        data-for="drag"
        data-tip
      >
        <div className={`${styles.drag} mr-2`} {...listeners} {...attributes}>
          <Drag />
        </div>
      </div>
      <Tooltip
        id="drag"
        info="Drag to move"
      />
    </div>
  );
};

export default ActionButtons;
