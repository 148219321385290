import React from 'react';
import { ReactComponent as ProgressBar } from '../../../../../assets/icons/border.svg';
import { interestData } from '../../../../../utils/wallets';
import styles from './InterestCard.module.scss';

const InterestsCard = ({ name, score }) => {
  const handleColorBar = (value) => {
    if (value > 50) { return '#885AF8'; }
    if (value > 20) { return '#109CF1'; }
    return '#FFB946';
  };
  // const handleColorSVG = (value) => {
  //   if (value > 50) { return styles.purple; }
  //   if (value > 20) { return styles.blue; }
  //   return styles.orange;
  // };
  const handleBar = (value) => {
    const total = value > 100 ? 100 : value;
    return 124 - (124 * total) / 100;
  };

  return (
    <div className={`${styles.wrapper} d-flex gap-2 align-items-center p-3`}>
      <div className={`${styles.content} d-flex align-items-center justify-content-center position-relative`}>
        <div className={`${styles.wrapper_icons} position-absolute`}>
          <ProgressBar stroke={handleColorBar(score)} strokeDashoffset={handleBar(score)} />
        </div>
        <div className={`${styles.icon_content} d-flex align-items-center justify-content-center`}>
          <div className={`${styles.background} d-flex align-items-center justify-content-center`}>
            <div
              className="d-flex align-items-center justify-content-center"
            >
              {interestData[name]}
            </div>
          </div>
        </div>
      </div>
      {name}
    </div>
  );
};

export default InterestsCard;
