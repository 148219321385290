import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFilterOption } from '../../../store/reducers/coin';
import { coinApi } from '../../../api/coin';
import HoldersList from '../../../components/ui/HoldersList';
import styles from './Holders.module.scss';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';
import Distribution from './Distribution';
import PortfolioShare from './PortfolioShare';
import HistoricalHolders from './HistoricalHolders';

const Holders = ({
  isSupportedBlockchain,
}) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'token_qty');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const coinid = pathname.split('/')[2].toString();
  const filterOption = useSelector(selectFilterOption);
  const showPortfolioShare = !['lost', 'all'].includes(filterOption);
  const showDistribution = !['lost', 'all', 'new'].includes(filterOption);

  const {
    data,
    isLoading: isHoldersListLoading,
    isFetching: isHoldersListFetching,
  } = coinApi.useGetTokenHoldersListQuery({
    id: pathname.split('/')[2],
    des: desc ? '1' : '0',
    limit: +limit,
    order,
    page,
    audience: filterOption,
  });

  useEffect(() => {
    if (isHoldersListLoading || isHoldersListFetching) {
      setSearchParams(
        {
          ...Object.fromEntries([...searchParams]),
          page: 1,
          perPage: 25,
        },
      );
      setPage(1);
      setLimit(25);
    }
  }, [filterOption]);

  const [getTokenHolderListCSV, result] = coinApi.useGetTokenHolderListCSVMutation({ audience: filterOption });

  return (
    isSupportedBlockchain ? (
      <div className={styles.wrapper}>
        {(showPortfolioShare || showDistribution) && (
          <div className={`${styles.charts} d-flex flex-column flex-md-row gap-4`}>
            <div className="flex-grow-1">
              {showPortfolioShare && (
                <PortfolioShare coin={coinid} audience={filterOption} className="h-100 w-100" />
              )}
            </div>
            <div className="flex-grow-1">
              {showDistribution && (
                <Distribution coin={coinid} audience={filterOption} className="h-100 w-100" />
              )}
            </div>
          </div>
        )}
        <div className="d-flex flex-column pb-3 w-100">
          <HistoricalHolders coin={coinid} audience={filterOption} />
        </div>
        <HoldersList
          data={data}
          isLoading={isHoldersListLoading || isHoldersListFetching}
          limit={limit}
          setLimit={setLimit}
          setDesc={setDesc}
          setPage={setPage}
          setOrder={setOrder}
          page={page}
          desc={desc}
          order={order}
          result={result}
          downloadCSV={getTokenHolderListCSV}
          audience={filterOption}
        />
      </div>
    ) : <NotSupportedBlockchain />
  );
};

export default Holders;
