import React, { useState } from 'react';

import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import ChainLogo from '../../../../components/base/ChainLogo';
import sharedStyles from './Shared.module.scss';
import DefaultIcon from '../../../../components/ui/DefaultIcon';
import styles from '../inputs/Input.module.scss';

const CoinLabel = ({ val }) => {
  const [imgError, setImgError] = useState(false);
  return (
    <div className="d-flex cursor-pointer justify-content-between align-items-center">
      <div className={`d-flex align-items-top flex-wrap ${styles.wrapper}`}>
        <div className="me-1">
          {(Boolean(!val.additionalData) || imgError) ? (
            <DefaultIcon size="20px" type="token" />
          ) : (
            <img
              src={val.additionalData}
              onError={() => setImgError(true)}
              className="rounded-full"
              width="20"
              height="20"
              alt="coin"
            />
          )}
        </div>
        <div>
          <div className={`${styles.label} me-2 text-truncate d-flex`}>
            <span className="me-1">
              {val?.label}
            </span>
          </div>
          <div className={`${styles.suggest} d-flex gap-2 text-nowrap`}>
            <span className={styles.suggest}>
              {val?.market_cap || val?.market_cap === 0
                ? `${val.market_cap < 1 ? '<1' : convertToReadableFormat(val.market_cap)}`
                : '?'}
              {' '}
              mcap
            </span>
          </div>
        </div>
      </div>
      <div className={sharedStyles.indicatorWrapper}>
        <ChainLogo chain={val?.network} square medium />
      </div>
    </div>
  );
};

export default CoinLabel;
