import React from 'react';
import { get } from 'react-hook-form';
import styles from './CustomField.module.scss';
import { ReactComponent as Delete } from '../../../../../../assets/icons/close.svg';
import { LoadingLines } from '../../../../../../components/ui/modals/SearchModal/LoadingList';

const CustomField = ({
  elem, onDelete, index, register, customDatasetFields, disableChangeStatus, customFieldsLoading,
  errors,
}) => {
  const error = (name) => get(errors, name);

  const selectFiledType = (type) => {
    switch (type) {
      case 'contact_info':
        return (
          <div className={`col-6 ${styles.container} ${styles.container_divider}`}>
            <div className={styles.title}>
              Field value source
            </div>
            <select
              className={error(`additional_fields.${index}.value_type`) ? styles.error : ''}
              disabled={disableChangeStatus}
              defaultValue=""
              {...register(`additional_fields.${index}.value_type`, { required: true })}
            >
              <option hidden value="">Select</option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
              <option value="discord_id">Discord</option>
              <option value="twitter_id">Twitter</option>
              <option value="telegram_id">Telegram</option>
            </select>
          </div>
        );
      case 'profile_attributes':
        return (
          <div className={`col-6 ${styles.container} ${styles.container_divider}`}>
            <div className={styles.title}>
              Field value source
            </div>
            <select
              className={error(`additional_fields.${index}.value_type`) ? styles.error : ''}
              disabled={disableChangeStatus}
              defaultValue=""
              {...register(`additional_fields.${index}.value_type`, { required: true })}
            >
              <option hidden value="">Select</option>
              {customDatasetFields.map((field) => (
                <option key={field.column_name} value={field.column_name}>{field.column_name}</option>
              ))}
            </select>
          </div>
        );
      case 'static_value':
        return (
          <>
            <div className={`col-6 ${styles.container} ${styles.container_divider}`}>
              <div className={styles.title}>
                Value type
              </div>
              <select
                className={error(`additional_fields.${index}.value_type`) ? styles.error : ''}
                disabled={disableChangeStatus}
                defaultValue=""
                {...register(`additional_fields.${index}.value_type`, { required: true })}
              >
                <option hidden value="">Select</option>
                <option value="String">String</option>
                <option value="Number">Number</option>
                <option value="Boolean">Boolean</option>
              </select>
            </div>
            <div className={`col-6 ${styles.container} ${styles.container_divider}`}>
              <div className={styles.title}>
                Field value
              </div>
              {elem.value_type === 'Boolean'
                ? (
                  <select
                    className={error(`additional_fields.${index}.value`) ? styles.error : ''}
                    disabled={disableChangeStatus}
                    defaultValue=""
                    {...register(`additional_fields.${index}.value`, { required: true })}
                  >
                    <option hidden value="">Select</option>
                    <option value="True">True</option>
                    <option value="False">False</option>
                  </select>
                )
                : (
                  <input
                    className={error(`additional_fields.${index}.value`) ? styles.error : ''}
                    onWheel={(e) => e.target.blur()}
                    disabled={disableChangeStatus}
                    type={elem.value_type === 'Number' ? 'number' : 'text'}
                    {...register(`additional_fields.${index}.value`, { required: true })}
                  />
                )}
            </div>
          </>
        );
      default: return null;
    }
  };
  return (
    <div className={`${styles.wrapper} w-100`}>
      <Delete
        className={`${styles.delete} ${disableChangeStatus ? styles.blocked : ''} cursor-pointer position-absolute`}
        onClick={() => !disableChangeStatus && onDelete()}
      />
      {!customFieldsLoading ? (
        <>
          <div className="row">
            <div className={`col-6 ${styles.container}`}>
              <div className={styles.title}>
                Field name
              </div>
              <input
                className={error(`additional_fields.${index}.name`) ? styles.error : ''}
                disabled={disableChangeStatus}
                type="text"
                maxLength={50}
                {...register(`additional_fields.${index}.name`, { required: true })}
              />
            </div>
            <div className={`col-6 ${styles.container}`}>
              <div className={styles.title}>
                Field type
              </div>
              <select
                className={error(`additional_fields.${index}.type`) ? styles.error : ''}
                disabled={disableChangeStatus}
                defaultValue=""
                {...register(`additional_fields.${index}.type`, { required: true })}
              >
                <option hidden value="">Select</option>
                <option value="contact_info">Contact info</option>
                <option value="static_value">Static value</option>
                {customDatasetFields.length
                  ? <option value="profile_attributes">Profile attributes</option>
                  : null}
                {customFieldsLoading
                  ? (
                    <option value="loader" disabled>
                      Profile attributes (Loading...)
                    </option>
                  )
                  : null}
              </select>
            </div>
          </div>
          <div className="row">
            {selectFiledType(elem.type)}
          </div>
        </>
      ) : (
        <LoadingLines rows={1} />
      )}
    </div>
  );
};

export default CustomField;
