import React from 'react';
import uniqueId from '../../../utils/uniqueId';
import { ReactComponent as Info } from '../../../assets/icons/info_small_raw.svg';
import Tooltip from '../Tooltip';
import styles from './CardTitle.module.scss';

const CardTitle = ({ title, info }) => {
  const infoId = uniqueId('card_info');

  return (
    <div className={styles.title_wrapper}>
      <div>
        <span className={styles.title}>{title}</span>
        <div className={`${styles.divider}`} />
      </div>
      <div>
        <div className={`${styles.info_wrapper} ${info ? '' : styles.hidden}`} data-for={infoId} data-tip>
          <Info color="#90A0B7" height="20px" width="20px" />
        </div>
        <Tooltip id={infoId} info={info} />
      </div>
    </div>
  );
};

export default CardTitle;
