import React, { useEffect, useState, cloneElement } from 'react';
import { renderers } from '../../Fields';
import { ReactComponent as Delete } from '../../../../../assets/icons/forms/trash_raw.svg';
import styles from './OverlayField.module.scss';

const OverlayField = ({
  field, overlay, isPointerOutsideBuilder, fieldData, ...rest
}) => {
  const { type } = field;
  const [component, setComponent] = useState(null);

  useEffect(() => {
    setComponent(renderers[type] || (() => (
      <div>
        No renderer found for
        {' '}
        {type}
      </div>
    )));
  }, [type]);

  useEffect(() => {
    document.body.style.cursor = overlay ? 'grabbing' : 'default';

    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [overlay]);

  return (
    <div className={styles.field}>
      <div className={`
        ${overlay ? styles.overlay : ''} 
        ${overlay && isPointerOutsideBuilder ? styles.remove : ''}`}
      >
        {component && cloneElement(component, { field, ...rest })}
      </div>
      {overlay && isPointerOutsideBuilder
      && (
        <div className={styles.remove_icon}>
          <Delete />
        </div>
      )}
    </div>
  );
};

export default OverlayField;
