import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { coinApi } from '../../../../api/coin';
import { getAssetSlug, setAssetSlug, setSelectedAsset } from '../../../../store/reducers/app';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import styles from './AddressesModal.module.scss';
import Loader from '../../../base/Loader';
import ChainLogo from '../../../base/ChainLogo';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import uniqueId from '../../../../utils/uniqueId';

const AddressesModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalData = useSelector(getAssetSlug);

  const {
    data,
    isLoading,
    isFetching,
  } = coinApi.useGetAddressesCollectionQuery(modalData?.slug, {
    skip: !modalData?.slug,
  });

  const closedModal = () => setTimeout(() => dispatch(setAssetSlug(null)), 100);

  const content = useMemo(() => {
    if (isLoading || isFetching) {
      return (
        <div className="d-flex align-items-center justify-content-center m-5">
          <Loader />
        </div>
      );
    }
    if (data) {
      const handleLink = (network, name) => {
        dispatch(setSelectedAsset({
          type: 'nft',
          name: name || modalData?.name || '',
          logo: modalData.logo,
          symbol: modalData.symbol?.toUpperCase(),
          network,
        }));
      };
      return (
        <div className={styles.wrapper_addresses}>
          {data.slice().sort((a, b) => (a.holder_count > b.holder_count ? -1 : 1)).map((elem) => (
            <Link
              key={uniqueId('collection_address')}
              className={styles.wrap}
              to={`/nfts/${elem.blockchain}/${elem.contract_address}`}
            >
              <div
                data-bs-dismiss="modal"
                aria-label="Close"
                role="presentation"
                className={`d-flex flex-column ${styles.addresses} cursor-pointer`}
                onClick={() => handleLink(elem.blockchain, elem.contract_address, elem.contract_name)}
              >
                <div className="d-flex justify-content-between">
                  <div className={`${styles.address_name} d-flex gap-2 align-items-baseline`}>
                    <span className="text-truncate">{elem.contract_name || modalData?.name || null}</span>
                    <span className="text-truncate">{elem.contract_symbol ? `($${elem.contract_symbol})` : null}</span>
                  </div>
                  <ChainLogo chain={elem.blockchain} />
                </div>
                <div className="d-flex gap-3 mt-1">
                  <div className="d-flex gap-1">
                    <span className={styles.field}>Contract address:</span>
                    <span className={styles.value}>
                      {`${elem.contract_address.slice(0, 6)}...${elem.contract_address.slice(-4)}`}
                    </span>
                  </div>
                  <div className="d-flex gap-1">
                    <span className={styles.field}>Holders:</span>
                    <span className={styles.value}>{ethFormat(elem.holder_count)}</span>
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
            </Link>
          ))}
        </div>
      );
    }
    return (
      <div className={`${styles.title} d-flex align-items-center justify-content-center m-5`}>
        No data
      </div>
    );
  }, [data, dispatch, isFetching, isLoading, modalData?.logo, modalData?.name, modalData?.symbol, navigate]);

  return (
    <div
      className="modal fade"
      id="exModal"
      tabIndex="-1"
      aria-labelledby="exModalLabel"
      aria-hidden="true"
      onClick={() => closedModal()}
    >
      <div
        role="presentation"
        className="modal-dialog modal-dialog-centered"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content border-0 d-flex">
          <div className="d-flex justify-content-end px-3 pt-3">
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => closedModal()}
            />
          </div>
          <div className={`${styles.modal_wrapper} pb-3`}>
            <div className={`${styles.title} text-truncate mb-1`}>{modalData?.name || null}</div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressesModal;
