import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ReactComponent as Case } from '../../../assets/icons/case.svg';
import { ReactComponent as Age } from '../../../assets/icons/age.svg';
import { ReactComponent as Activity } from '../../../assets/icons/activity.svg';
import { ReactComponent as Value } from '../../../assets/icons/value.svg';
import { ControlledList } from '../../../components/ui/modals/SearchModal/LoadingList';
import styles from './Indicators.module.scss';
import AgeDate from '../../../components/ui/Table/AgeDate';

const Indicators = ({ data, isWalletLoading }) => (
  <div className={`${styles.wrapper} w-100`}>
    <div className="row">
      <div className="col-lg-3 d-flex justify-content-between">
        { isWalletLoading ? (
          <div className={`${styles.wrapper_widget} d-flex`}>
            <ControlledList />
          </div>
        ) : (
          <div className="d-flex">
            <div className={styles.icon}>
              <Case />
            </div>
            <div>
              <div className={styles.title}>
                Total Portfolio Value
              </div>
              <div className={styles.value}>
                {data?.total_value}
              </div>
            </div>
          </div>
        )}
        <div className={styles.divider} />
      </div>
      <div className="col-lg-3 d-flex justify-content-between">
        { isWalletLoading ? (
          <div className={`${styles.wrapper_widget} d-flex position-relative`}>
            <ControlledList />
          </div>
        )
          : (
            <div className="d-flex">
              <div className={styles.icon}>
                <Value />
              </div>
              <div>
                <div className={styles.title}>
                  Buying Power
                </div>
                <div className={styles.value}>
                  {data?.buying_power}
                </div>
              </div>
            </div>
          )}
        <div className={styles.divider} />
      </div>
      <div className="col-lg-3 d-flex justify-content-between">
        { isWalletLoading ? (
          <div className={`${styles.wrapper_widget} d-flex position-relative`}>
            <ControlledList />
          </div>
        )
          : (
            <div className="d-flex">
              <div className={styles.icon}>
                <Age />
              </div>
              <div>
                <div className={styles.title}>
                  Wallet Age
                </div>
                <div className={styles.value}>
                  {data?.age ? <AgeDate date={data.age} defaultText="No Transactions" isAge /> : 'No Transactions'}
                </div>
              </div>
            </div>
          )}
        <div className={styles.divider} />
      </div>
      <div className="col-lg-3 d-flex">
        { isWalletLoading ? (
          <div className={`${styles.wrapper_widget} d-flex position-relative`}>
            <ControlledList />
          </div>
        )
          : (
            <>
              <div className={styles.icon}>
                <Activity />
              </div>
              <div>
                <div className={styles.title}>
                  Last Activity
                </div>
                <div className={styles.value}>
                  {data?.activity
                    ? `${formatDistanceToNow(new Date(data.activity), { addSuffix: true })}`
                    : 'No Transactions'}
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  </div>
);

export default React.memo(Indicators);
