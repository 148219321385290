import React from 'react';
import styles from './ErrorsModal.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import { ReactComponent as Warning } from '../../../../assets/icons/flows/gray_warning.svg';
import CloseModalButton from '../components/CloseModalButton';

const ErrorsModal = ({ message, errors, onCancel }) => (
  <div className="modal fade" id="errorsModal" tabIndex="-1" aria-labelledby="errorsModalLabel" aria-hidden="false">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content border-0 p-4">
        <CloseModalButton onCancel={onCancel} />
        <div className={styles.header}>
          <div className={`${styles.title} d-flex`}>
            Cannot activate Flow
          </div>
        </div>
        <div className={`${styles.description} d-flex w-100 mt-3`}>
          <span>
            {message}
          </span>
        </div>
        <div>
          {errors.map((elem) => (
            <div key={uniqueId('errors')} className={styles.error}>
              <div>
                <Warning />
              </div>
              {elem.detail}
            </div>
          ))}
        </div>
        <div className={`${styles.footer} my-4 d-flex justify-content-end`}>
          <button type="button" className="regular-button border border-1" data-bs-dismiss="modal">
            Return to editing
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorsModal;
