import React, { useEffect, useMemo, useState } from 'react';
import TitleComponent from '../../../../components/base/TitleComponent';
import Loader from '../../../../components/base/Loader';
import Table from '../../../../components/base/Table';
import styles from './MultiChainActivity.module.scss';
import NoData from '../../../../components/base/NoData';
import ChainLogo from '../../../../components/base/ChainLogo';
import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import AgeDate from '../../../../components/ui/Table/AgeDate';

const MultiChainActivity = ({ multiChainData, isLoading }) => {
  const [orderBy, setOrdering] = useState('share');
  const [desc, setDesc] = useState(true);
  const [tableData, setData] = useState([]);

  const headers = [
    { title: 'Blockchain', field: 'chain' },
    { title: 'First Activity', field: 'first_activity' },
    { title: 'Last Activity', field: 'last_activity' },
    { title: 'Number of Transactions', field: 'transactions' },
    { title: 'Native Token Balance', field: 'native_balance' },
    { title: 'Total Portfolio Value', field: 'total_value' },
    { title: 'Share of Portfolio', field: 'share' },
  ];

  const tokenUnit = (chain) => {
    switch (chain) {
      case 'ethereum':
        return 'ETH';
      case 'near':
        return 'NEAR';
      case 'polygon':
        return 'MATIC';
      default:
        return 'ETH';
    }
  };

  const processMultiChainData = (data) => data.map((chainData) => ({
    chain: (
      <div className="d-flex align-items-center gap-2">
        <ChainLogo chain={chainData.chain} square />
        <span className="ml-2">{chainData.chain.charAt(0).toUpperCase() + chainData.chain.slice(1)}</span>
      </div>
    ),
    first_activity: <AgeDate date={chainData.first_activity} defaultText="N/A" />,
    last_activity: <AgeDate date={chainData.last_activity} defaultText="N/A" />,
    transactions: chainData.transactions,
    native_balance: chainData.native_balance < 0
      ? '?'
      : `${convertToReadableFormat(chainData.native_balance)} ${tokenUnit(chainData.chain)}`,
    total_value: chainData.total_value < 0
      ? '?'
      : `$${convertToReadableFormat(chainData.total_value)}`,
    share: `${(chainData.share && chainData.share > 0.01) ? chainData.share : '0'}%`,
  }));

  useEffect(() => {
    if (multiChainData.length > 0 && !isLoading) {
      setData(multiChainData);
    }
  }, [multiChainData, isLoading]);

  useEffect(() => {
    if (orderBy !== '' && multiChainData.length > 0) {
      const sortedData = [...multiChainData].sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return desc ? 1 : -1;
        }
        if (a[orderBy] > b[orderBy]) {
          return desc ? -1 : 1;
        }
        return 0;
      });
      setData(sortedData);
    }
  }, [orderBy, desc, multiChainData]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
      );
    }
    if (tableData.length > 0) {
      return (
        <div className={styles.portfolio_distribution}>
          <div className="d-flex flex-column align-self-start w-100">
            <Table
              data={processMultiChainData(tableData)}
              headers={headers}
              isLoading={isLoading}
              ordering={orderBy}
              setOrdering={setOrdering}
              desc={desc}
              setDesc={setDesc}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        <NoData />
      </div>
    );
  });

  return (
    <div className={`${styles.wrapper} d-flex flex-column h-100`}>
      <TitleComponent>Multi-chain Activity</TitleComponent>
      <div className="d-flex flex-column justify-content-center mt-3">
        {content}
      </div>
    </div>
  );
};

export default MultiChainActivity;
