import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import styles from './Select.module.scss';
import { toISODate } from '../../../../utils/toISODate';

const DateSelect = ({ option, pushSelectedOptionToArray, edit }) => {
  const {
    value, name, type,
  } = option;

  const [formattedDate, setFormattedDate] = useState();

  useEffect(() => {
    if (Array.isArray(value)) {
      const y = value[0].value.substring(0, 4);
      const m = value[0].value.substring(5, 7);
      const d = value[0].value.substring(8, 10);
      setFormattedDate(new Date(y, m - 1, d));
    }
  }, [value]);

  return (
    <div className="w-100">
      <DatePicker
        className={`form-control ${styles.select}`}
        selected={formattedDate || null}
        placeholderText="Select a Date"
        onChange={(date) => {
          if (date) {
            pushSelectedOptionToArray(toISODate(date), name, type);
          }
        }}
        value={value ? new Date(value) : null}
        disabled={!edit}
      />
    </div>
  );
};

export default DateSelect;
