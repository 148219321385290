import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useKeydown from '../../../../../components/hooks/app/useKeydown';
import RoundSpinner from '../../../../../components/base/RoundSpinner';
import CloseModalButton from '../../../../../components/ui/modals/components/CloseModalButton';
import { getUser } from '../../../../../store/reducers/user';
import { ReactComponent as Upload } from '../../../../../assets/icons/upload.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info.svg';
import { ReactComponent as Trash } from '../../../../../assets/icons/trash_asset.svg';
import Tooltip from '../../../../../components/ui/Tooltip';
import { webSDKApi } from '../../../../../api/webSDK';
import { showErrorMessage, showSuccessMessage } from '../../../../../components/base/Notifications';
import styles from './WebSDKCreationModal.module.scss';

const WebSDKCreationModal = ({
  onCancel,
  loading,
}) => {
  const navigate = useNavigate();
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState(null);
  const [urlImage, setUrlImage] = useState('');
  const inputRef = useRef(null);
  const user = useSelector(getUser);
  const {
    register, handleSubmit,
    setValue,
  } = useForm();

  const [createWebSDK, createWebSDKResult] = webSDKApi.useCreateWebSDKMutation();

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setUrlImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setUrlImage(URL.createObjectURL(e.dataTransfer.files[0]));
      setFile(e.dataTransfer.files[0]);
    }
  };

  const clearAsset = () => {
    setUrlImage('');
    setFile(null);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onSubmit = (onSubmitData) => {
    const formData = new FormData();
    if (file) {
      Object.keys(onSubmitData).forEach((elem) => {
        if (onSubmitData[elem]) {
          if (elem === 'logo') {
            formData.append(elem, file);
          } else {
            formData.append(elem, onSubmitData[elem]);
          }
        }
      });
    } else {
      Object.keys(onSubmitData).forEach((elem) => {
        if (onSubmitData[elem] && elem !== 'logo') {
          formData.append(elem, onSubmitData[elem]);
        }
      });
      formData.append('logo', '');
    }
    formData.append('user', user.id);
    createWebSDK(formData);
  };

  useEffect(() => {
    if (createWebSDKResult.isSuccess) {
      showSuccessMessage('Website was successfully registered');
      onCancel();
      navigate(`/tracked-website/${createWebSDKResult.data.id}`);
    }
    if (createWebSDKResult.isError) {
      showErrorMessage(createWebSDKResult.isError?.error?.data?.detail
          || 'Something went wrong. Try another website name ');
    }
  }, [createWebSDKResult, navigate, onCancel]);

  const assetsContent = useMemo(() => {
    if (file) {
      return (
        <div className={styles.label_file_showing}>
          <div className="d-flex align-items-center w-75 gap-2">
            <div className={styles.img_asset}>
              <img src={urlImage} alt="img" width="50px" height="50px" />
            </div>
            <span className="text-truncate">{file?.name || ''}</span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              type="button"
              className={`${styles.upload_button} cursor-pointer`}
              onClick={() => clearAsset()}
            >
              <Trash />
            </button>
            <label {...register('logo')} htmlFor="input-file-upload">
              <input
                id="input-file-upload"
                ref={inputRef}
                type="file"
                accept=".jpg,.png,.gif"
                className={styles.input_file_upload}
                onChange={handleChange}
              />
              <button
                type="button"
                className={`${styles.upload_button} ${styles.upload_color}`}
                onClick={onButtonClick}
              >
                <Upload />
              </button>
            </label>
          </div>
        </div>
      );
    }

    return (
      <label
        htmlFor="input-file-upload"
        className={styles.label_file_upload}
      >
        <input
          id="input-file-upload"
          ref={inputRef}
          type="file"
          accept=".jpg,.png,.gif"
          className={styles.input_file_upload}
          onChange={handleChange}
        />
        <button
          type="button"
          className={styles.upload_button}
          onClick={onButtonClick}
        >
          <div className="d-flex align-items-center justify-content-center gap-1">
            <Upload />
            <span className="w-100 p-3">Drag & Drop Image or click to upload</span>
          </div>
        </button>
      </label>
    );
  }, [file]);

  useKeydown('Escape', onCancel);

  return (
    <form
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      {createWebSDKResult.isLoading || createWebSDKResult.isFetching
        ? <RoundSpinner position="position-absolute" theme="light" />
        : null}
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div
          className={`${styles.body} modal-content position-relative border-0`}
          onDragEnter={handleDrag}
        >
          {loading
            ? <RoundSpinner />
            : null}
          <CloseModalButton onCancel={onCancel} />
          {dragActive
              && (
                <div
                  className={styles.drag_file_element}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <div className="d-flex align-items-center justify-content-center gap-2 h-100 w-100">
                    <div className={styles.upload_img} />
                    Drop file anywhere to upload
                  </div>
                </div>
              )}

          {/* Website name */}
          <div className={`${styles.title} d-flex justify-content-start`}>
            Track a Website
          </div>
          <div className={`${styles.scroll} d-flex flex-column w-100`}>
            <div className="d-flex flex-column w-100 gap-1 mb-3">
              <div className={styles.title_input}>
                <span>Website name</span>
                <span
                  style={{ alignSelf: 'start' }}
                  className="px-1"
                  data-for="website_name"
                  data-tip="show"
                >
                  <InfoIcon />
                </span>
                <Tooltip
                  id="website_name"
                  info={(
                    <div className={styles.tooltip_info}>
                      <div>
                        The name that will appear in your insights dashboard
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className={styles.link_area}>
                <input
                  {...register('website_name', { required: true })}
                  maxLength={50}
                  onChange={(e) => {
                    setValue('website_name', e.target.value);
                  }}
                  placeholder="Enter name"
                  type="text"
                  className="w-100 p-2"
                />
              </div>
            </div>
            {/* Website logo */}
            <div className="d-flex flex-column w-100 gap-1 mb-3">
              <div className={styles.input_optional}>
                <span>Upload logo</span>
                <span
                  style={{ alignSelf: 'start' }}
                  className="px-1"
                  data-for="file_format"
                  data-tip="show"
                >
                  <InfoIcon />
                </span>
                <Tooltip
                  id="file_format"
                  info={(
                    <div className={styles.tooltip_info}>
                      <div>
                        File types supported:
                      </div>
                      <div>JPG,PNG,GIF</div>
                    </div>
                  )}
                />
                <span className={styles.option_word}>Optional</span>
              </div>
              {assetsContent}
            </div>

            {/* Website URL */}
            <div className="d-flex flex-column w-100 gap-1 mb-3">
              <div className={styles.title_input}>
                Website domain
                <span
                  style={{ alignSelf: 'start' }}
                  className="px-1"
                  data-for="domains"
                  data-tip="show"
                >
                  <InfoIcon />
                </span>
                <Tooltip
                  id="domains"
                  info={(
                    <div className={styles.tooltip_info}>
                      <div>
                        Comma-separated list of domains to track. All subdomains will also be tracked.
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className={styles.link_area}>
                <input
                  {...register('domains', { required: true })}
                  maxLength={1000}
                  onChange={(e) => {
                    setValue('domains', e.target.value);
                  }}
                  placeholder="Enter domain"
                  type="text"
                  className="w-100 p-2"
                />
              </div>
              <div className={`${styles.footer} d-flex justify-content-end`}>
                <button
                  type="submit"
                  className="regular-button mt-3"
                >
                  Track
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default WebSDKCreationModal;
