import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNowStrict } from 'date-fns';
import { getCurrentFolder, getSelectedItems, setSelectedItems } from '../../../store/reducers/folders';
import { flowsApi } from '../../../api/flows';
import { debounce } from '../../../utils/debounce';
import emitter from '../../../utils/emitter';
import { ReactComponent as Trash } from '../../../assets/icons/table/trash.svg';
import { ReactComponent as DisabledTrash } from '../../../assets/icons/disabled_trash.svg';
import { ReactComponent as Copy } from '../../../assets/icons/table/copy.svg';
import { ReactComponent as ManageFolder } from '../../../assets/icons/manage_folder.svg';
import { ReactComponent as AllIcon } from '../../../assets/icons/box.svg';
import { ReactComponent as RunningIcon } from '../../../assets/icons/status_running.svg';
import { ReactComponent as DraftIcon } from '../../../assets/icons/status_draft.svg';
import { ReactComponent as StoppedIcon } from '../../../assets/icons/status_stopped.svg';
import { ReactComponent as ScheduledIcon } from '../../../assets/icons/status_scheduled.svg';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import Tooltip from '../../../components/ui/Tooltip';
import MainTable from '../../../components/base/MainTable';
import Settings from '../../../assets/images/dashboard/settings.png';
import Checkbox from '../../../components/ui/Checkbox';
import NameFieldDetails from '../../../components/ui/NameFieldDetails';
import PopupMenuButton from '../../../components/ui/PopupMenuButton';
import TableHeader from '../../../components/ui/Table/TableHeader';
import NameField from '../../../components/ui/NameField';
import SearchResult from '../../../components/ui/SearchResult';
import ListFilter from '../../../components/ui/ListFilter';
import listStyles from '../../../components/ui/ListWithFolders/list.module.scss';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import TableCheckbox from '../../../components/base/MainTable/Checkbox';
import useSetSearchParams from '../../../utils/useSetSearchParams';
import { setFlowStatusList } from '../../../store/reducers/flows';
import useAddToFolder from '../../../utils/useAddToFolder';

const FlowsList = ({
  data, count, refetch, ordering, desc, page, isLoading, limit,
  manageFolders, search, status,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setParams = useSetSearchParams();

  const [clickFlow, setClickFlow] = useState();
  const [copy, setCopy] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [deleteTeamFlow, deleteResult] = flowsApi.useDeleteFlowMutation();

  const [copyTeamFlow, copyResult] = flowsApi.useCopyFlowMutation();
  const [allChecked, setAllChecked] = useState(false);
  const currentFolder = useSelector((state) => getCurrentFolder(state, 'flows'));
  const selection = useSelector((state) => getSelectedItems(state, 'flows'));

  const addItemToFolder = useAddToFolder(
    {
      onSuccess: () => {
        emitter.emit('refetch_flows');
      },
      onError: () => {
        showErrorMessage('The flow was copied, but could not be added to your folder');
      },
    },
  );

  useEffect(() => {
    if (data) {
      setAllChecked(data.length === selection.length);
    }
  }, [data, selection]);

  const debounceValue = useMemo(() => debounce((val) => {
    setParams({ search: val, page: 1 });
  }, 0), [setParams]);

  const handleSearch = (val) => {
    debounceValue(val);
  };

  const checkAllFlows = (checked) => {
    let tempSelection = [];
    if (checked) {
      tempSelection = data.map((item) => (
        {
          id: item.id,
          type: 'flows',
        }
      ));
    }
    dispatch(setSelectedItems({ type: 'flows', value: tempSelection }));
  };

  const header = [
    {
      id: 0,
      title: (
        <div
          className={listStyles.checkbox_title}
        >
          <Checkbox
            checked={allChecked}
            onChange={(checked) => checkAllFlows(checked)}
          />
        </div>
      ),
      width: 48,
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'created_at',
      title: 'Date of Creation',
    },
    {
      field: 'modified_by',
      title: 'Last Editor',
    },
    {
      title: (
        <div className="d-flex justify-content-center">
          Actions
        </div>
      ),
    },
  ];

  const handleDate = (date) => date.slice(0, 10);

  const onResultReceive = useCallback((response, type) => {
    if (response.isSuccess) {
      showSuccessMessage(`Flow was successfully ${type}`);
      if (type === 'copied') {
        setCopy(null);
      }
      if (type === 'deleted') {
        setClickFlow(null);
      }
      if (data?.length === 1 && page > 1) {
        setParams({ page: page - 1 });
      } else {
        refetch();
      }
      response.reset();
    }
    if (response.isError) {
      showErrorMessage(Object.keys(response.error.data).length !== 0 && response.error.data.detail);
      response.reset();
    }
  }, [data?.length, page, refetch]);

  const handleManageFolders = (item) => {
    if (manageFolders) {
      manageFolders(item.id, 'flows');
    }
  };

  useEffect(() => {
    if (copyResult.status === 'fulfilled') {
      const newId = copyResult.data.id;
      if (currentFolder.id !== 'all' && currentFolder.id !== 'my') {
        addItemToFolder(
          {
            itemId: newId,
            itemType: 'flows',
            folderId: currentFolder.id,
            folderType: 'flows',
          },
        );
      }
    }
  }, [copyResult]);

  useEffect(() => {
    onResultReceive(copyResult, 'copied');
  }, [copyResult, onResultReceive]);

  useEffect(() => {
    onResultReceive(deleteResult, 'deleted');
  }, [deleteResult, onResultReceive]);

  useEffect(() => {
    if (data) {
      const temp = data.map((elem) => ({
        checked: (
          <div
            className={listStyles.checkbox_title}
          >
            <TableCheckbox
              id={elem.id}
              type="flows"
              itemType="flows"
            />
          </div>
        ),
        name: (
          <div className={listStyles.name_field_wrapper}>
            <NameField
              id={elem.id}
              text={elem.name}
              search={search}
              link={`/add-flow/${elem.id}`}
              tooltip={elem.name}
            />
            <NameFieldDetails
              creator={elem.created_by}
              updatedAt={elem.updated_at
                ? `${formatDistanceToNowStrict(new Date(elem.updated_at), { addSuffix: true })}`
                : '?'}
            />
          </div>
        ),
        status: (<span className="text-capitalize">{elem.status}</span>),
        created_at: <span className={listStyles.created_at_field}>{handleDate(elem.created_at)}</span>,
        modified_by: elem.modified_by,
        button: (
          <div className={`ms-auto ${tableStyles.popover_wrapper} text-center position-relative cursor-pointer`}>
            <img
              src={Settings}
              className="cursor-pointer"
              height="25"
              width="25"
              alt="settings"
            />
            <div className={`${tableStyles.invisible_dropdown} position-absolute top-0 right-0`}>
              <div className={`${tableStyles.popover_content} position-absolute`}>
                <PopupMenuButton onClick={() => setCopy({ name: elem.name, id: elem.id })}>

                  <Copy />
                  Copy
                </PopupMenuButton>

                <PopupMenuButton onClick={() => handleManageFolders(elem)}>
                  <ManageFolder color="#90A0B7" />
                  Manage folders
                </PopupMenuButton>

                {elem.status === 'running' || elem.status === 'scheduled'
                  ? (
                    <>
                      <Tooltip
                        fixWidth
                        id={`delete_info_${elem.id}`}
                        info={`You can’t delete the “${elem.status}” flow`}
                      />
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        data-for={`delete_info_${elem.id}`}
                        data-tip
                      >
                        <DisabledTrash />
                        Delete
                      </div>
                    </>
                  ) : (
                    <PopupMenuButton onClick={() => {
                      setClickFlow({
                        name: elem.name,
                        id: elem.id,
                      });
                    }}
                    >
                      <Trash />
                      Delete
                    </PopupMenuButton>
                  )}
              </div>
            </div>
          </div>),
      }));
      setTableData(temp);

      const statusTemp = data.map((elem) => ({
        id: elem.id,
        status: elem.status,
      }));
      dispatch(setFlowStatusList(statusTemp));
    }
  }, [data, dispatch]);

  const setFilter = (id) => {
    switch (id) {
      case 'running':
      case 'draft':
      case 'scheduled':
      case 'stopped':
        setParams({ status: id, page: 1 });
        break;
      case 'all':
      default:
        setParams({ status: '', page: 1 });
        break;
    }
  };

  return (
    <div className={`${listStyles.wrapper} asset-section d-flex flex-column justify-content-between`}>
      <div>
        { copy && (
          <ConfirmModal
            title="Confirm copy"
            description={`Are you sure you want to copy the “${copy.name}” flow with its configurations?`}
            buttonName="Copy"
            onSubmit={() => copyTeamFlow(copy.id)}
            onCancel={() => setCopy(null)}
            loading={copyResult.isLoading || copyResult.isFetching}
          />
        )}
        { clickFlow && (
          <ConfirmModal
            title="Confirm delete"
            description={`Are you sure you want to delete “${clickFlow?.name?.length > 30
              ? `${clickFlow?.name.slice(0, 30)}...` : clickFlow?.name}”?`}
            buttonName="Delete"
            onSubmit={() => deleteTeamFlow(clickFlow.id)}
            onCancel={() => setClickFlow(null)}
            loading={deleteResult.isLoading || deleteResult.isFetching}
          />
        )}

        <TableHeader
          count={count}
          page={page}
          limit={limit}
          searchValue={search}
          handleSearch={handleSearch}
          type="flows"
        >
          <button
            className={`regular-button ${listStyles.header_button}`}
            type="button"
            onClick={() => {
              navigate(
                '/add-flow',
                currentFolder.id !== 'all' && currentFolder.id !== 'my'
                  ? { state: { folder: currentFolder.id } }
                  : { state: null },
              );
            }}
          >
            Create a Flow
          </button>
        </TableHeader>

        <ListFilter
          filters={[
            {
              id: 'all',
              label: 'All',
              icon: <AllIcon color="#323C47" />,
              selected: status === '',
            },
            {
              id: 'running',
              label: 'Running',
              icon: <RunningIcon color="#323C47" />,
              selected: status === 'running',
            },
            {
              id: 'draft',
              label: 'Draft',
              icon: <DraftIcon color="#323C47" />,
              selected: status === 'draft',
            },
            {
              id: 'stopped',
              label: 'Stopped',
              icon: <StoppedIcon color="#323C47" />,
              selected: status === 'stopped',
            },
            {
              id: 'scheduled',
              label: 'Scheduled',
              icon: <ScheduledIcon color="#323C47" />,
              selected: status === 'scheduled',
            },
          ]}
          setFilter={setFilter}
        />

        <div className="w-100">
          <MainTable
            total={count}
            data={tableData}
            desc={desc}
            ordering={ordering}
            page={page}
            limit={limit}
            headers={header}
            isLoading={isLoading}
            min={10}
            nothingComponent={<SearchResult type="flow" search={search} />}
          />
        </div>
      </div>
    </div>
  );
};

export default FlowsList;
