import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isCustomDataEdit } from '../../../../store/reducers/app';
import Tooltip from '../../../../components/ui/Tooltip';
import { ReactComponent as Edit } from '../../../../assets/icons/edit_info.svg';
import { ReactComponent as Accept } from '../../../../assets/icons/check.svg';
import { ReactComponent as Close } from '../../../../assets/icons/flows/close.svg';
import {
  regExpEmail, regExpDiscord, regExpTwitter, regExpPhone, regExpTelegram,
} from '../../../../utils/regExp';
import styles from './EditField.module.scss';

const EditField = ({
  elem, register, setFocus, setValue, watch, unregister, setOnAccept,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(false);
  const [fieldInfo, setFieldInfo] = useState(elem.info);
  const [prevValue, setPrevValue] = useState(elem.info);

  const unknown = (
    <div className={styles.unknown}>
      unknown
    </div>
  );

  const isValid = (value) => {
    if (elem.id === 'email') {
      if (!regExpEmail.test(value) && value !== '') {
        setError(true);
      } else {
        setError(false);
      }
    }
    if (elem.id === 'twitter_id') {
      if ((!regExpTwitter.test(value) || value.includes('/')) && value !== '') {
        setError(true);
      } else {
        setError(false);
      }
    }
    if (elem.id === 'discord_id') {
      if ((!regExpDiscord.test(value) || value.includes('/')) && value !== '') {
        setError(true);
      } else {
        setError(false);
      }
    }
    if (elem.id === 'telegram_id') {
      if ((!regExpTelegram.test(value) || value.includes('/')) && value !== '') {
        setError(true);
      } else {
        setError(false);
      }
    }
    if (elem.id === 'phone') {
      if (!regExpPhone.test(value) && value !== '') {
        setError(true);
      } else {
        setError(false);
      }
    }
  };

  useEffect(() => {
    if (editMode) {
      setFocus(elem.id);
    }
  }, [editMode, elem.id, elem.info, setFocus]);

  return (
    <div className={`d-flex flex-column align-content-center justify-content-between ${styles.hover}`}>
      {editMode
        ? (
          <div className="d-flex justify-content-between">
            <input
              {...register(elem.id)}
              value={fieldInfo || ''}
              onChange={(e) => setFieldInfo(e.target.value)}
              className="shadow-none form-control"
              maxLength={320}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && (elem.info === prevValue)) {
                  unregister(elem.id);
                  e.preventDefault();
                }
              }}
            />
            <div className="d-flex align-items-center gap-2">
              <div
                className="cursor-pointer"
                role="presentation"
                onClick={() => {
                  const trimFieldInfo = fieldInfo.replace(/\s+/g, '');
                  if (elem.info === trimFieldInfo) {
                    setError(false);
                    unregister(elem.id);
                    setPrevValue(trimFieldInfo);
                  } else {
                    setValue(elem.id, trimFieldInfo);
                    isValid(watch(elem.id));
                  }
                  setOnAccept(true);
                  setEditMode(false);
                }}
              >
                <Accept />
              </div>
              <div className={`${styles.divider} d-flex align-items-center`} />
              <div
                className={`${styles.close} cursor-pointer`}
                role="presentation"
                onClick={() => {
                  const trimFieldInfo = prevValue.replace(/\s+/g, '');
                  if (elem.info === trimFieldInfo) {
                    unregister(elem.id);
                  }
                  setFieldInfo(trimFieldInfo);
                  if (elem.info !== trimFieldInfo) {
                    setValue(elem.id, trimFieldInfo);
                  }
                  setEditMode(false);
                }}
              >
                <Close />
              </div>
            </div>
          </div>
        )
        : (
          <div className="d-flex w-100 justify-content-between">
            <div
              role="presentation"
              className={`text-truncate ${elem.id === 'twitter_id'
                ? 'cursor-pointer' : null} ${elem.isClick && fieldInfo
              && !regExpTwitter.test(fieldInfo) && !fieldInfo.includes('/')
                ? styles.click
                : ''}`}
              data-for={fieldInfo}
              data-tip
              onClick={() => (elem.isClick
                && fieldInfo.length > 0
                && regExpTwitter.test(fieldInfo)
                && !fieldInfo.includes('/')
                ? window.open(`https://twitter.com/${fieldInfo}`, '_blank')
                : {})}
            >
              {fieldInfo || unknown}
            </div>
            {elem.id ? (
              <div
                role="presentation"
                className={`${styles.edit} d-flex align-items-center cursor-pointer`}
                onClick={() => {
                  setEditMode(true);
                  dispatch(isCustomDataEdit(false));
                }}
              >
                <Edit />
              </div>
            ) : null}
          </div>
        )}
      {elem?.info?.length > 50 ? (
        <Tooltip info={elem.info.length > 200 ? `${elem.info.slice(0, 200)}...` : elem.info} id={fieldInfo} />
      ) : null}
      {error && (<div className={styles.validation}>{elem.message}</div>)}
    </div>
  );
};

export default memo(EditField);
