import React from 'react';
import { useNavigate } from 'react-router-dom';
import PopupMenuButton from '../../PopupMenuButton';
import { ReactComponent as CreateForm } from '../../../../assets/icons/form.svg';

const AddFormButton = ({ folderId }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    navigate('/add-form', { state: { folder: folderId } });
  };

  return (
    <PopupMenuButton onClick={(e) => handleClick(e)}>
      <CreateForm color="#90A0B7" />
      Create form
    </PopupMenuButton>
  );
};

export default AddFormButton;
