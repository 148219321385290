import React, { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { coinApi } from '../../../api/coin';
import { selectFilterOption } from '../../../store/reducers/coin';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';
import styles from './InvestorProfile.module.scss';
import AiBlock from '../../../components/ui/AiBlock';
import TopStats from '../../../components/ui/TopStats';
import LastActivity from '../../../components/ui/DonutCharts/LastActivity';
import MultiChainActivity from '../../../components/ui/DonutCharts/MultiChainActivity';
import PortfolioValue from '../../../components/ui/DonutCharts/PortfolioValue';
import BuyingPower from '../../../components/ui/DonutCharts/BuyingPower';
import WalletAge from '../../../components/ui/DonutCharts/WalletAge';
import TypicalPortfolioDistribution from '../../../components/ui/DonutCharts/TypicalPortfolioDistribution';
import useWebSocket from '../../../components/hooks/app/useWebSocket';

const InvestorProfile = ({
  holders,
  symbol,
  networks,
  isSupportedBlockchain,
  audienceProfileData,
  isAudienceProfileLoading,
  user,
  userLoading,
}) => {
  const { pathname } = useLocation();
  const filterOption = useSelector(selectFilterOption);
  const [aiOverview, setAiOverview] = useState({});
  const [shouldConnect, setShouldConnect] = useState(false);

  const { data, fullMessage, isConnected } = useWebSocket(
    shouldConnect ? 'ws/ft/audience-profile/ai-overview/' : null,
    shouldConnect ? { coin_id: pathname.split('/')[2], audience: filterOption } : null,
    !shouldConnect,
    pathname.split('/')[2],
    filterOption,
  );

  useEffect(() => {
    if (!userLoading
      && user
      && user.ai_explanations
      && filterOption !== 'all') {
      setShouldConnect(true);
    }
  }, [userLoading]);

  useEffect(() => {
    if (isConnected) {
      setShouldConnect(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (data.length > 0 && !fullMessage) {
      setAiOverview((prev) => ({
        ...prev,
        data,
      }));
    } else if (fullMessage) {
      setAiOverview((prev) => ({
        ...prev,
        fullJSONMessage: fullMessage,
      }));
    } else {
      setAiOverview({
        data: null,
        fullJSONMessage: null,
      });
    }
  }, [data, fullMessage]);

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = coinApi.useGetTokenNotableInvestmentsQuery({
    id: pathname.split('/')[2],
    limit: 6,
    audience: filterOption,
  }, {
    skip: !pathname?.includes('coin'),
  });

  const {
    currentData: sharedInterestsData,
    isLoading: sharedInterestsIsLoading,
    isFetching: sharedInterestsIsFetching,
  } = coinApi.useGetGroupInterestQuery({
    address: pathname.split('/')[2],
    audience: filterOption,
  }, {
    skip: !pathname?.includes('coin'),
  });

  const subTitle = {
    current: 'Current Holders',
    new: 'Newcomers',
    lost: 'Churned Holders',
    relevant: 'Relevant Holders',
  };

  return useMemo(() => {
    if (!isSupportedBlockchain) {
      return <NotSupportedBlockchain />;
    }
    return (
      <div className={`${styles.wrapper}`}>
        {(!userLoading && user && user?.ai_explanations && filterOption !== 'all') && (
          <div className="row mb-4">
            <div className="col-12">
              <AiBlock
                data={aiOverview}
                linkText="Read more"
                title={`Deep-Dive on ${subTitle[filterOption]} -`}
                wBackground
                isProfile
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <TopStats data={audienceProfileData} isLoading={isAudienceProfileLoading} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <PortfolioValue
              activityData={audienceProfileData?.portfolio_value_distribution}
              previousHighlight={audienceProfileData?.previous_values?.portfolio_value_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mt-4 mt-md-0">
            <BuyingPower
              activityData={audienceProfileData?.buying_power_distribution}
              previousHighlight={audienceProfileData?.previous_values?.buying_power_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mt-4 mt-lg-0">
            <WalletAge
              activityData={audienceProfileData?.wallet_age_distribution}
              previousHighlight={audienceProfileData?.previous_values?.wallet_age_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mt-4 mt-lg-0">
            <TypicalPortfolioDistribution
              activityData={audienceProfileData?.typical_portfolio_distribution}
              previousHighlight={audienceProfileData?.previous_values?.typical_portfolio_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <NotableInvestments
              text="tokens"
              symbol={symbol}
              isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
              data={notableInvestments?.tokens}
              holders={holders}
              info="Tokens most commonly held by holders of this token"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mt-4 mt-md-0">
            <NotableInvestments
              text="NFTs"
              symbol={symbol}
              isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
              data={notableInvestments?.nfts}
              holders={holders}
              info="NFTs most commonly held by holders of this token"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
            <SharedInterests
              symbol={symbol}
              data={sharedInterestsData}
              isLoading={sharedInterestsIsLoading}
              isFetching={sharedInterestsIsFetching}
              info="Topics of interest for holders of this token"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
            <LastActivity
              activityData={audienceProfileData?.last_activity_distribution}
              previousHighlight={audienceProfileData?.previous_values?.last_activity_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
          {networks.length === 1
          && (networks[0].toLowerCase().includes('near') || networks[0].toLowerCase().includes('flow'))
            ? null
            : (
              <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                <MultiChainActivity
                  activityData={audienceProfileData?.multi_chain_activity}
                  previousHighlight={audienceProfileData?.previous_values?.multi_chain_activity}
                  isLoading={isAudienceProfileLoading}
                  walletCount={audienceProfileData?.wallet_count}
                  className="h-100"
                />
              </div>
            )}
        </div>
      </div>
    );
  }, [
    holders,
    isNotableInvestmentsFetching,
    isNotableInvestmentsLoading,
    isSupportedBlockchain,
    notableInvestments?.nfts,
    notableInvestments?.tokens,
    sharedInterestsData,
    sharedInterestsIsFetching,
    sharedInterestsIsLoading,
    symbol,
    audienceProfileData,
    isAudienceProfileLoading,
    aiOverview,
  ]);
};

export default InvestorProfile;
