import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Loader from '../../../../components/base/Loader';
import NoData from '../../../../components/base/NoData';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import CircleChart from '../../../../components/ui/CircleChart';
import LogoDisplay from '../../../../components/ui/modals/SearchModal/LogoDisplay';

const UserTabContainer = ({
  title, isLoading, data, total, walletFilterOption = 'any_wallet_status',
}) => {
  const languageNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const [visitorNum, setVisitorNum] = useState();

  useEffect(() => {
    setVisitorNum(title === 'Web3 Adoption' && data?.find((o) => (o.value === 'wallets_connected'))
      ? (data.filter((elem) => elem.value === 'wallets_connected')[0].visitor_count
      + data.filter((elem) => elem.value === 'wallets_not_connected')[0].visitor_count)
      : 0);
  }, [title, data, visitorNum]);

  const handleContent = (text) => {
    switch (text) {
      case 'Languages':
        return 'Most Frequent Language';
      case 'Wallet Types':
        return 'Most Frequent Wallet';
      case 'Chains':
        return 'Most Frequent Blockchain';
      case 'Web3 Adoption':
        return 'Visitors with wallets';
      default:
        return 'Most Frequent';
    }
  };

  const seriesDataCount = useCallback(() => {
    if (data && data.length && total) {
      let result = [];
      if (title === 'Web3 Adoption') {
        const filteredData = walletFilterOption === 'no_wallet_detected'
          ? data.filter((category) => category.value === 'no_wallet_detected')
          : data.filter((category) => category.value !== 'no_wallet_detected');
        const sumOfFilteredData = Object.values(filteredData).reduce((acc, value) => acc + value.visitor_count, 0);
        result = filteredData.map((elem) => ({
          label: elem.value === 'wallets_connected' ? 'Connected' : 'Not Connected',
          percent: sumOfFilteredData !== 0 ? ethFormat((elem.visitor_count / sumOfFilteredData) * 100) : 0,
          count: elem.visitor_count,
        }));
      } else if (title === 'Languages') {
        result = data.filter((elem) => elem.value).map((elem) => ({
          value: elem.value,
          label: languageNamesInEnglish.of(elem.value),
          percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
          count: elem.visitor_count,
        }));
      } else if (title === 'Countries') {
        result = data.filter((elem) => elem.value).map((elem) => ({
          value: regionNamesInEnglish.of(elem.value),
          label: (
            <span className="d-flex align-items-center gap-2 w-50">
              <LogoDisplay
                logo={`/trackedWebsite/countryFlags/${elem.value.toLowerCase()}.svg`}
                size="15px"
                asset="address"
                position="static"
              />
              <span className="text-capitalize">
                {regionNamesInEnglish.of(elem.value)}
              </span>
            </span>
          ),
          percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
          count: elem.visitor_count,
        }));
      } else {
        result = data.filter((elem) => elem.value).map((elem) => ({
          value: elem.value,
          label: (title === 'Wallet Types'
            ? (
              <span className="d-flex align-items-center gap-2 w-50">
                <LogoDisplay
                  logo={`/trackedWebsite/walletIcons/wallet_${elem.value.toLowerCase()}.png`}
                  size="15px"
                  asset="address"
                  position="static"
                />
                <span className="text-capitalize">
                  {elem.value}
                </span>
              </span>
            ) : elem.value),
          percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
          count: elem.visitor_count,
        }));
      }
      if (title !== 'Web3 Adoption') {
        const sumValues = data.filter((elem) => elem.value).reduce((acc, elem) => acc + elem.visitor_count, 0);
        const safeCount = total - sumValues >= 0 ? total - sumValues : 0;
        result.push({
          label: 'Other',
          fullName: 'Other',
          percent: total !== 0 ? ethFormat(((safeCount) / total) * 100) : 0,
          count: safeCount,
        });
      }
      return result;
    }
    return [];
  }, [data, title, total]);

  const highlightValue = useCallback(() => {
    if (data && data.length) {
      let result;
      if (title === 'Web3 Adoption') {
        const walletDetected = data.find((category) => category.value === 'wallets_connected').visitor_count
            + data.find((category) => category.value === 'wallets_not_connected').visitor_count;
        result = `${walletDetected.toLocaleString()} (${ethFormat(total !== 0 ? (walletDetected / total) * 100 : 0)}%)`;
      } else if (title === 'Languages') {
        const elem = data[0];
        result = `${languageNamesInEnglish.of(elem.value)} (${ethFormat(total !== 0
          ? (elem.visitor_count / total) * 100 : 0)}%)`;
      } else {
        const elem = data[0];
        result = `${elem.value} (${ethFormat(total !== 0 ? (elem.visitor_count / total) * 100 : 0)}%)`;
      }
      return result;
    }
    return null;
  }, [data, title, total, languageNamesInEnglish]);

  const containerContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="p-5">
          <Loader />
        </div>
      );
    }
    return (
      data && data.length && total
        ? (
          <CircleChart
            title={title}
            highlightText={handleContent(title)}
            highlightValue={highlightValue()}
            data={seriesDataCount().map((elem) => elem)}
            isLoading={isLoading}
            className="h-100"
          />
        )
        : (
          <div className="d-flex justify-content-center align-items-center">
            <NoData />
          </div>
        )
    );
  }, [data, isLoading, seriesDataCount, title, total]);

  return (
    <div className="col-sm-12 col-md-6 col-lg-3">
      {containerContent}
    </div>

  );
};

export default UserTabContainer;
