/* eslint-disable max-len */
import React from 'react';

const Snapshot = ({ hovered }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill={hovered ? '#0f9cf1' : 'white'} />
    <path d="M12.084 17.7896C12.084 16.4791 13.1464 15.4167 14.4569 15.4167V15.4167C15.3085 15.4167 16.0947 14.9604 16.5172 14.221L16.834 13.6667C17.0151 13.3497 17.1057 13.1912 17.2191 13.0629C17.4574 12.7932 17.7767 12.608 18.1291 12.5348C18.2967 12.5 18.4792 12.5 18.8444 12.5H21.1569C21.5221 12.5 21.7046 12.5 21.8722 12.5348C22.2246 12.608 22.5439 12.7932 22.7822 13.0629C22.8956 13.1912 22.9862 13.3497 23.1673 13.6667L23.4841 14.221C23.9066 14.9604 24.6928 15.4167 25.5444 15.4167V15.4167C26.8549 15.4167 27.9173 16.4791 27.9173 17.7896V23.0833C27.9173 24.4835 27.9173 25.1835 27.6448 25.7183C27.4051 26.1887 27.0227 26.5712 26.5523 26.8109C26.0175 27.0833 25.3174 27.0833 23.9173 27.0833H16.084C14.6839 27.0833 13.9838 27.0833 13.449 26.8109C12.9786 26.5712 12.5962 26.1887 12.3565 25.7183C12.084 25.1835 12.084 24.4835 12.084 23.0833V17.7896Z" stroke={hovered ? 'white' : '#334D6E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="19.9993" cy="21.2493" r="3.33333" stroke={hovered ? 'white' : '#334D6E'} strokeWidth="1.5" />
    <circle cx="25.209" cy="18.125" r="0.625" stroke={hovered ? 'white' : '#334D6E'} strokeWidth="1.25" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke={hovered ? '#0f9cf1' : '#C2CFE0'} />
  </svg>
);

export default Snapshot;
