import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { audiencesApi } from '../../../api/audiences';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import { LoadingLines } from '../../../components/ui/modals/SearchModal/LoadingList';
import RoundSpinner from '../../../components/base/RoundSpinner';
import Loader from '../../../components/base/Loader';
import Tooltip from '../../../components/ui/Tooltip';
import uniqueId from '../../../utils/uniqueId';
import { ReactComponent as AddList } from '../../../assets/icons/list_plus.svg';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { ReactComponent as Delete } from '../../../assets/icons/close.svg';
import styles from './InYourAudiences.module.scss';
import { setSelectedAsset } from '../../../store/reducers/app';

const InYourAudiences = ({
  segments, isSegmentLoading,
  customLists, isCustomListLoading, refetch, address, isSuccess, walletAddress,
}) => {
  const dispatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [customListName, setCustomListName] = useState('');
  const [suggestedList, setSuggestedList] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: customListsData,
    isLoading: isCustomListsDataLoading,
  } = audiencesApi.useGetTeamSegmentsQuery({
    limit: 100000, itemType: 'custom list',
  }, {
    skip: !showAddModal,
  });

  const [createCustomListAddresses, createCustomListAddressesResult] = audiencesApi.useSubmitCustomListMutation();
  const [addCustomListAddresses, addCustomListAddressesResult] = audiencesApi.useAddCustomListAddressesMutation();
  const [deleteAddressFromCustomList, result] = audiencesApi.useDeleteWalletAddressFromCustomListMutation();

  const isWalletAddress = (test) => {
    const pattern = /^0x[a-fA-F0-9]{40}$/;
    return pattern.test(test);
  };
  const alid = !isWalletAddress(address) ? address : null;

  const isWalletPathName = (pathName) => {
    const pattern = /^\/profile\/0x[a-fA-F0-9]{40}$/;
    return pattern.test(pathName);
  };

  const segmentContent = useMemo(() => {
    if (isSegmentLoading) {
      return (
        <div className="w-50">
          <LoadingLines />
        </div>
      );
    }
    if (segments) {
      return (
        segments?.data.filter((elem) => elem.segment_name).length
          ? (
            <div className="d-flex flex-wrap w-100 gap-2 my-2">
              {segments?.data.map((elem) => (
                <div
                  role="presentation"
                  className={`${styles.pill} d-flex align-items-center gap-2 p-2 cursor-pointer`}
                  key={uniqueId('pill_list')}
                  onClick={() => navigate(`/segment/${elem.segment_id}`)}
                >
                  <div className={`${styles.pill_name}`}>
                    {elem.segment_name}
                  </div>
                </div>
              ))}
            </div>
          )
          : <div className={styles.soon}>This profile doesn&apos;t match any segment you have</div>
      );
    }
    return <div className={styles.soon}>This profile doesn&apos;t match any segment you have</div>;
  }, [isSegmentLoading, segments, navigate]);

  const pillsContent = useMemo(() => {
    if (isCustomListLoading) {
      return (
        <div className="w-50">
          <LoadingLines />
        </div>
      );
    }
    if (customLists) {
      return (
        <div className="d-flex flex-wrap w-100 gap-2 my-2">
          {customLists.map((elem) => (
            <div className={`${styles.pill} d-flex align-items-center gap-2 p-2`} key={uniqueId('pill_list')}>
              <div
                role="presentation"
                className={`${styles.pill_name} cursor-pointer`}
                onClick={() => {
                  dispatch(setSelectedAsset({
                    name: elem.name,
                  }));
                  navigate(`/custom-list/${elem.id}`);
                }}
              >
                {elem.name}
              </div>
              <Delete
                className="cursor-pointer"
                onClick={() => deleteAddressFromCustomList({
                  id: elem.id,
                  alid: alid || null,
                })}
              />
            </div>
          ))}
        </div>
      );
    }
    return null;
  }, [address, customLists, deleteAddressFromCustomList, dispatch, isCustomListLoading, navigate]);

  const modalContent = useMemo(() => {
    if (isCustomListsDataLoading) {
      return (
        <div className="my-4">
          <Loader />
        </div>
      );
    }
    if (suggestedList.length) {
      return (
        <div className="d-flex flex-column gap-2">
          <span className={styles.title_group}>Select an existing list or create new one</span>
          <div className={styles.wrapper_list}>
            {suggestedList.map((elem) => (
              <div
                key={uniqueId('name_list')}
                className={styles.hover}
              >
                <button
                  className={`${styles.name_list_wrapper} text-truncate my-2 w-100`}
                  type="button"
                  onClick={() => addCustomListAddresses({
                    id: elem.id,
                    addresses: {
                      address: walletAddress,
                      alid: alid || null,
                    },
                  })}
                >
                  {elem.name}
                </button>
                <div className={styles.divider} />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }, [addCustomListAddresses, address, isCustomListsDataLoading, suggestedList]);

  useEffect(() => {
    if (customListsData?.results && isSuccess) {
      const dataName = customLists ? customLists.map((elem) => elem.name) : [];
      const notUsingAddressLists = customListsData.results.filter((elem) => !dataName.includes(elem.name));
      const filterName = notUsingAddressLists
        .filter((elem) => elem.name.toLowerCase().includes(customListName.toLowerCase()));
      setSuggestedList(filterName);
    } else {
      setSuggestedList([]);
    }
  }, [customListName, customListsData?.results, customLists, isSuccess]);

  useEffect(() => {
    if (result.isSuccess) {
      showSuccessMessage('Address was successfully deleted');
      refetch();
      result.reset();
      setShowAddModal(false);
    }
    if (result.isError) {
      showErrorMessage(Object.keys(result.error.data).length !== 0 && 'Something went wrong');
      result.reset();
    }
  }, [refetch, result]);

  useEffect(() => {
    if (createCustomListAddressesResult.isSuccess) {
      showSuccessMessage('List was successfully created');
      if (isWalletPathName(location.pathname) && createCustomListAddressesResult.data.alid) {
        navigate(`/profile/${createCustomListAddressesResult.data.alid}`);
      } else {
        refetch();
      }
      createCustomListAddressesResult.reset();
      setShowAddModal(false);
    }
    if (createCustomListAddressesResult.isError) {
      if (createCustomListAddressesResult.error.status === 400) {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length !== 0
          && 'List with the same name already exists');
      } else {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length
          !== 0 && 'Something went wrong');
      }
      createCustomListAddressesResult.reset();
    }
  }, [createCustomListAddressesResult, refetch]);

  useEffect(() => {
    if (addCustomListAddressesResult.isSuccess) {
      showSuccessMessage('Address was successfully added to List');
      if (isWalletPathName(location.pathname) && addCustomListAddressesResult.data.alid) {
        navigate(`/profile/${addCustomListAddressesResult.data.alid}`);
      } else {
        refetch();
      }
      addCustomListAddressesResult.reset();
      setShowAddModal(false);
    }
    if (addCustomListAddressesResult.isError) {
      if (addCustomListAddressesResult.error.status === 400) {
        showErrorMessage(Object.keys(addCustomListAddressesResult.error.data).length !== 0
          && 'List with the same address already exists');
      } else {
        showErrorMessage(Object.keys(addCustomListAddressesResult.error.data).length !== 0 && 'Something went wrong');
      }
      addCustomListAddressesResult.reset();
    }
  }, [addCustomListAddressesResult, refetch]);

  return (
    <div className={`${styles.wrapper} gap-3 d-flex flex-column`}>
      <div className={styles.title}>In Your Audiences</div>
      <div className="d-flex flex-column gap-2">
        <div className={styles.title_list}>Lists</div>
        {pillsContent}
        <div className="d-flex gap-2 align-items-center position-relative">
          <span className={styles.add_list}>{customLists ? 'Add to list' : 'Not in any List'}</span>
          <div
            className="cursor-pointer"
            data-for="list"
            data-tip
            role="presentation"
            onClick={() => {
              setShowAddModal(!showAddModal);
              setCustomListName('');
            }}
          >
            <AddList />
          </div>
          <Tooltip id="list" info="Add to list" position="center" />
          {showAddModal && (
            <div className={`${styles.modal_wrapper} position-absolute d-flex flex-column gap-2`}>
              <div className={`${styles.search} d-flex`}>
                <div className="d-flex">
                  <Search />
                </div>
                <input
                  type="text"
                  autoComplete="off"
                  maxLength={50}
                  placeholder="Search for Lists"
                  onChange={(e) => setCustomListName(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <span className={styles.title_group}>Create new list</span>
                <button
                  className={`${styles.name_wrapper} text-truncate`}
                  type="button"
                  disabled={!customListName}
                  onClick={() => createCustomListAddresses({
                    name: customListName,
                    address: walletAddress,
                    alid: alid || null,
                  })}
                >
                  {customListName}
                </button>
              </div>
              {modalContent}
            </div>
          )}
        </div>
      </div>
      <div className={`${styles.divider} w-100`} />
      <div className="d-flex flex-column gap-2">
        <div className={styles.title_list}>Segments</div>
        {segmentContent}
      </div>
      {showAddModal && (
        <div
          className={`${styles.mod} w-100 h-100 start-0 top-0 position-fixed`}
          onClick={() => setShowAddModal(false)}
          role="presentation"
        />
      )}
      {result.isLoading || result.isFetching
      || addCustomListAddressesResult.isLoading || addCustomListAddressesResult.isFetching
      || createCustomListAddressesResult.isLoading || createCustomListAddresses.isFetching
        ? <RoundSpinner />
        : null}
    </div>
  );
};

export default React.memo(InYourAudiences);
