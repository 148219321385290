import { Chain } from '@wagmi/core'

export const amoy = {
  id: 80002,
  name: 'Amoy',
  network: 'amoy',
  nativeCurrency: {
    decimals: 18,
    name: 'Amoy',
    symbol: 'MATIC',
  },
  rpcUrls: {
    public: { http: ['https://polygon-amoy-bor-rpc.publicnode.com'] },
    default: { http: ['https://polygon-amoy-bor-rpc.publicnode.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'Amoy scan', url: 'https://amoy.polygonscan.com/' },
    default: { name: 'Amoy scan', url: 'https://amoy.polygonscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
} as const satisfies Chain;

export const base = {
  id: 8453,
  name: 'Base',
  network: 'base',
  nativeCurrency: {
    decimals: 18,
    name: 'Base',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.base.org'] },
    default: { http: ['https://mainnet.base.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'Basescan', url: 'https://basescan.org' },
    default: { name: 'Basescan', url: 'https://basescan.org' },
  },
} as const satisfies Chain;

export const baseSepolia = {
  id: 84532,
  name: 'Base Sepolia',
  network: 'base-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Base',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://base-sepolia-rpc.publicnode.com'] },
    default: { http: ['https://sepolia.base.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'sepolia_explorer', url: 'https://sepolia-explorer.base.org' },
    default: { name: 'sepolia_explorer', url: 'https://sepolia-explorer.base.org' },
  },
} as const satisfies Chain;

export const linea = {
  id: 59144,
  name: 'Linea',
  network: 'linea',
  nativeCurrency: {
    decimals: 18,
    name: 'Linea',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.linea.build'] },
    default: { http: ['https://rpc.linea.build'] },
  },
  blockExplorers: {
    lineascan: { name: 'Lineascan', url: 'https://lineascan.build/' },
    default: { name: 'Lineascan', url: 'https://lineascan.build/' },
  },
} as const satisfies Chain;

export const lineaSepolia = {
  id: 59141,
  name: 'Linea Sepolia',
  network: 'linea-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Linea',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.sepolia.linea.build'] },
    default: { http: ['https://rpc.sepolia.linea.build'] },
  },
  blockExplorers: {
    etherscan: { name: 'Lineascan', url: 'https://sepolia.lineascan.build/' },
    default: { name: 'Lineascan', url: 'https://sepolia.lineascan.build/' },
  },
} as const satisfies Chain;