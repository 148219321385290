import React from 'react';
import { ReactComponent as InfoSmall } from '../../../assets/icons/info_small.svg';
import { ControlledList } from '../modals/SearchModal/LoadingList';
import styles from './WidgetCard.module.scss';
import uniqueId from '../../../utils/uniqueId';
import Tooltip from '../Tooltip';
import GrowthIndicator from '../GrowthIndicator';

const Separator = () => <span className={styles.separator}>/</span>;

const WidgetCard = ({
  isLoading, icon, info, title, value, growth,
}) => {
  const tooltipId = uniqueId('widget');

  const renderGrowth = () => {
    if (!growth || (growth.hideZero && growth.percent === '0')) return null;
    if (Array.isArray(growth)) {
      const growths = growth.map((val) => {
        if (val.hideZero && val.percent === '0') return null;
        return <GrowthIndicator percent={val.percent} oldValue={val.previousValue} />;
      });
      return (
        <div className={styles.growths}>
          {growths.map((component, index) => (
            <React.Fragment key={uniqueId('growth')}>
              {component}
              {index < growths.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </div>
      );
    }
    if (growth.hideZero && growth.percent === '0') return null;
    return <GrowthIndicator percent={growth.percent} oldValue={growth.previousValue} />;
  };

  if (isLoading) {
    return (
      <div className={`${styles.wrapper} ${styles.loading}`}>
        <ControlledList />
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={icon} alt="logo" width="32px" height="32px" />
      </div>
      {info ? (
        <>
          <div
            className={`${styles.info}`}
            data-for={tooltipId}
            data-tip
          >
            <InfoSmall />
          </div>
          <Tooltip info={info} id={tooltipId} position="top" />
        </>
      ) : null}
      <div className={styles.content_wrapper}>
        <span className={styles.title}>{title}</span>
        <div className={styles.value_wrapper}>
          <span className={styles.value}>{value}</span>
          {renderGrowth()}
        </div>
      </div>
    </div>
  );
};

export default WidgetCard;
