import { useEffect } from 'react';

const useKeydown = (key, func) => {
  const downHandler = (event) => {
    if (event.key === key) {
      func();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);
};

export default useKeydown;
