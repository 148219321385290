import React, {
  useEffect,
  useState,
  useMemo, useCallback,
} from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import styles from './FormNodeSettings.module.scss';
import EditableTitle from '../../../../../components/base/EditableTitle';
import { formsApi } from '../../../../../api/forms';
import { ReactComponent as Info } from '../../../../../assets/icons/info.svg';
import Tooltip from '../../../../../components/ui/Tooltip';
import { debounce } from '../../../../../utils/debounce';

const inputStyles = {
  option: (style) => ({
    ...style,
    cursor: 'pointer',
    padding: '13px 12px',
    border: '1px solid #F1F4F8',
  }),
  placeholder: (style) => ({
    ...style,
    color: '#90A0B7',
    fontSize: '14px',
    lineHeight: '20px',
  }),
};

const CustomControl = (props) => {
  const propsToSet = { ...props };
  const { selectProps, ...restProps } = propsToSet;
  const modifiedSelectProps = propsToSet.hasValue ? {
    ...selectProps,
    selectProps: {
      ...selectProps.selectProps,
      head: true,
    },
  } : selectProps;
  return (
    <components.Control {...restProps} selectProps={modifiedSelectProps}>
      {propsToSet.children}
    </components.Control>
  );
};

const FormNodeSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();

  const [userInput, setUserInput] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [selectedItem, setSelectedItem] = useState();
  const [suggestedList, setSuggestedList] = useState([]);

  const { handleSubmit } = useForm();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = () => {
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({
      ...selectedItem,
      node_name: titleValue,
      user_input: userInput,
      node_id: activeSettings.node_id,
    }));
    dispatch(clearActiveSettings());
  };

  const {
    data,
    isLoading,
    isFetching,
  } = formsApi.useGetFormsQuery({
    limit: 0,
    additionalParams: '&as_flow_entry_source=true',
  });

  const getData = (e) => {
    if (data && data?.results?.length) {
      if (e) {
        const tempArr = [...data.results].filter((elem) => elem.name.toLowerCase().includes(e.toLowerCase()));
        return tempArr.map((elem) => ({ ...elem, value: elem.name, label: elem.name }));
      }
    }
  };
  const loadData = useCallback(debounce((val) => getData(val), 10), [getData]);

  useEffect(() => {
    if (data && data?.results?.length) {
      setSuggestedList(data.results);
    }
  }, [data]);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setSelectedItem(configList[index]);
        setUserInput(configList[index].user_input);
        setTitleValue(configList[index].node_name);
      }
    }
  }, [activeSettings.node_id, configList]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="New form source"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              onSave={() => setUserInput(true)}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <div className={styles.select}>
            <div className={styles.select_title}>
              Choose form to track
              <Info
                className="ms-2"
                data-for="form_info_tooltip"
                data-tip
              />
              <Tooltip
                id="form_info_tooltip"
                info="Form submissions can only trigger the Flow based on a Wallet address or email address field."
                maxWidth={242}
              />
            </div>
            <AsyncSelect
              className="w-100 mb-3"
              styles={inputStyles}
              maxMenuHeight={300}
              loadOptions={(e) => loadData(e)}
              components={{ Control: CustomControl }}
              selectProps={selectedItem}
              onChange={(e) => {
                if (!userInput) {
                  setTitleValue(e.value);
                }
                setSelectedItem(e);
              }}
              isLoading={isLoading || isFetching}
              placeholder="Search for forms"
              value={selectedItem}
              defaultOptions={suggestedList?.map((elem) => ({ ...elem, value: elem.name, label: elem.name })) || []}
              isDisabled={disableChangeStatus}
            />
          </div>
        </div>
        <div>
          <div className="d-flex gap-4 mt-4">
            <button
              type="button"
              className={`${styles.btn_cancel}`}
              onClick={() => dispatch(clearActiveSettings())}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button w-100"
              disabled={!selectedItem || disableChangeStatus}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormNodeSettings;
