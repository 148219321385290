/* eslint-disable max-len */
import React from 'react';

const ExportCSV = ({ hovered }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill={hovered ? '#0f9cf1' : 'white'} />
    <path d="M13.75 23C13.75 22.5858 13.4142 22.25 13 22.25C12.5858 22.25 12.25 22.5858 12.25 23H13.75ZM13 24H12.25H13ZM27.75 23C27.75 22.5858 27.4142 22.25 27 22.25C26.5858 22.25 26.25 22.5858 26.25 23H27.75ZM19.4143 23.5315C19.1556 23.8549 19.208 24.3269 19.5315 24.5857C19.8549 24.8444 20.3269 24.792 20.5857 24.4685L19.4143 23.5315ZM24.5857 19.4685C24.8444 19.1451 24.792 18.6731 24.4685 18.4143C24.1451 18.1556 23.6731 18.208 23.4143 18.5315L24.5857 19.4685ZM19.4143 24.4685C19.6731 24.792 20.1451 24.8444 20.4685 24.5857C20.792 24.3269 20.8444 23.8549 20.5857 23.5315L19.4143 24.4685ZM16.5857 18.5315C16.3269 18.208 15.8549 18.1556 15.5315 18.4143C15.208 18.6731 15.1556 19.1451 15.4143 19.4685L16.5857 18.5315ZM19.25 24C19.25 24.4142 19.5858 24.75 20 24.75C20.4142 24.75 20.75 24.4142 20.75 24H19.25ZM20.75 13C20.75 12.5858 20.4142 12.25 20 12.25C19.5858 12.25 19.25 12.5858 19.25 13H20.75ZM12.25 23V24H13.75V23H12.25ZM12.25 24C12.25 26.0711 13.9289 27.75 16 27.75V26.25C14.7574 26.25 13.75 25.2426 13.75 24H12.25ZM16 27.75H24V26.25H16V27.75ZM24 27.75C26.0711 27.75 27.75 26.0711 27.75 24H26.25C26.25 25.2426 25.2426 26.25 24 26.25V27.75ZM27.75 24V23H26.25V24H27.75ZM20.5857 24.4685L24.5857 19.4685L23.4143 18.5315L19.4143 23.5315L20.5857 24.4685ZM20.5857 23.5315L16.5857 18.5315L15.4143 19.4685L19.4143 24.4685L20.5857 23.5315ZM20.75 24V13H19.25V24H20.75Z" fill={hovered ? 'white' : '#334D6E'} />
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke={hovered ? '#0f9cf1' : '#C2CFE0'} />
  </svg>
);

export default ExportCSV;
