import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import styles from './AttributesSearch.module.scss';
import ArrowDown from '../../../components/svgIcons/ArrowDown';
import useOutsideClick from '../../../components/hooks/app/useOutsideClick';
import Loader from '../../../components/base/Loader';

const AttributesSearch = ({
  attributesFields, isLoading, data, setData, index, disabled, dataTypesByAttribute,
  mappingErrors, setMappingErrors,
}) => {
  const [searchModalActive, setSearchModalActive] = useState(false);
  const [word, setWord] = useState('');
  const [suggestedList, setSuggestedList] = useState([]);

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => {
    setSearchModalActive(false);
    setWord('');
  });

  useEffect(() => {
    const obj = {};
    if (word) {
      Object.keys(attributesFields).forEach((elem) => {
        obj[elem] = attributesFields[elem]
          .filter((attribute) => attribute[Object.keys(attribute)[0]].toLowerCase().includes(word.toLowerCase()));
      });
      setSuggestedList(obj);
    } else {
      setSuggestedList(attributesFields);
    }
  }, [word]);

  useEffect(() => {
    setSuggestedList(attributesFields);
  }, [attributesFields]);

  return (
    <div className="position-relative">
      <div
        role="presentation"
        onClick={() => !disabled && setSearchModalActive(true)}
        className={`
          ${styles.selected}
          ${data.map((elem) => elem.attribute).filter((elem) => elem && elem === data[index].attribute).length > 1
      ? styles.error : ''}
          ${mappingErrors.filter((elem) => elem.column === data[index].column && !elem.attribute).length === 1
      ? styles.error : ''}
          d-flex justify-content-between align-items-center cursor-pointer`}
      >
        {data[index].attribute
          ? (
            <div className="text-truncate">
              {data[index].attribute}
            </div>
          )
          : (
            <div className={styles.attribute_placeholder}>
              Create new attribute
            </div>
          )}
        <div className={styles.arrow}>
          <ArrowDown />
        </div>
      </div>
      {data.map((elem) => elem.attribute.toLowerCase())
        .filter((elem) => elem && elem === data[index].attribute.toLowerCase()).length > 1
        ? <span className={styles.multiple}>Field name used multiple times</span>
        : null}
      {mappingErrors.filter((elem) => elem.column === data[index].column && !elem.attribute).length === 1
        ? <span className={styles.multiple}>Mapping is missing attribute</span>
        : null}
      {searchModalActive && (
        <div
          className={`${styles.modal_wrapper}
          position-absolute d-flex flex-column gap-2`}
          ref={wrapperRef}
        >
          <div className={`${styles.search} d-flex`}>
            <div className="d-flex">
              <Search />
            </div>
            <input
              type="text"
              autoComplete="off"
              maxLength={50}
              placeholder="Search for attribute"
              onChange={(e) => setWord(e.target.value)}
              autoFocus
            />
          </div>
          <div className="d-flex flex-column gap-2 cursor-pointer">
            <span className={styles.title_group}>Create new attribute</span>
            <button
              className={`${styles.name_wrapper} text-truncate`}
              type="button"
              onClick={() => {
                const tempArr = [...data];
                tempArr[index].attribute = word;
                setData(tempArr);
                if (mappingErrors.filter((row) => row.column === data[index].column).length === 1) {
                  setMappingErrors(mappingErrors.filter((row) => row.column !== data[index].column));
                }
              }}
            >
              {word}
            </button>
          </div>
          {isLoading
            ? <Loader />
            : Object.keys(suggestedList).map((elem) => (
              <div key={elem}>
                {suggestedList[elem].length
                  ? (
                    <div className={styles.attribute_title}>
                      {elem.split('_').join(' ')}
                    </div>
                  )
                  : null}
                {suggestedList[elem].map((attribute) => (
                  <div
                    key={Object.keys(attribute)[0]}
                    className={styles.list_item}
                    role="presentation"
                    onClick={() => {
                      const tempArr = [...data];
                      tempArr[index].attribute = attribute[Object.keys(attribute)[0]];
                      tempArr[index].type = attribute[Object.keys(attribute)[0]] in dataTypesByAttribute
                        ? dataTypesByAttribute[attribute[Object.keys(attribute)[0]]]
                        : undefined;
                      tempArr[index].mapping = false;
                      setData(tempArr);
                      setSearchModalActive(false);
                      if (mappingErrors.filter((row) => row.column === data[index].column).length === 1) {
                        setMappingErrors(mappingErrors.filter((row) => row.column !== data[index].column));
                      }
                    }}
                  >
                    {attribute[Object.keys(attribute)[0]]}
                  </div>
                ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AttributesSearch;
