import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formsApi } from '../../../api/forms';
import { getCurrentFolder, getSelectedItems, setSelectedItems } from '../../../store/reducers/folders';
import { debounce } from '../../../utils/debounce';
import emitter from '../../../utils/emitter';
import { ReactComponent as Trash } from '../../../assets/icons/table/trash.svg';
import { ReactComponent as Copy } from '../../../assets/icons/table/copy.svg';
import { ReactComponent as ManageFolder } from '../../../assets/icons/manage_folder.svg';
import { ReactComponent as AllIcon } from '../../../assets/icons/box.svg';
import { ReactComponent as RunningIcon } from '../../../assets/icons/status_running.svg';
import { ReactComponent as DraftIcon } from '../../../assets/icons/status_draft.svg';
import { ReactComponent as StoppedIcon } from '../../../assets/icons/status_stopped.svg';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import MainTable from '../../../components/base/MainTable';
import Settings from '../../../assets/images/dashboard/settings.png';
import Checkbox from '../../../components/ui/Checkbox';
import NameFieldDetails from '../../../components/ui/NameFieldDetails';
import PopupMenuButton from '../../../components/ui/PopupMenuButton';
import TableHeader from '../../../components/ui/Table/TableHeader';
import NameField from '../../../components/ui/NameField';
import ListFilter from '../../../components/ui/ListFilter';
import SearchResult from '../../../components/ui/SearchResult';
import listStyles from '../../../components/ui/ListWithFolders/list.module.scss';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import TableCheckbox from '../../../components/base/MainTable/Checkbox';
import useSetSearchParams from '../../../utils/useSetSearchParams';
import useAddToFolder from '../../../utils/useAddToFolder';

const FormsList = ({
  data, count, refetch, ordering, desc, page, isLoading, limit,
  manageFolders, search, status,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setParams = useSetSearchParams();

  const [clickForm, setClickForm] = useState();
  const [copy, setCopy] = useState(null);
  const [close, setClose] = useState();
  const [tableData, setTableData] = useState(null);

  const [deleteTeamForm, deleteResult] = formsApi.useDeleteFormMutation();
  const [copyTeamForm, copyResult] = formsApi.useCopyFormMutation();
  const [closeTeamForm, closeResult] = formsApi.useCloseFormMutation();
  const [allChecked, setAllChecked] = useState(false);
  const currentFolder = useSelector((state) => getCurrentFolder(state, 'forms'));
  const selection = useSelector((state) => getSelectedItems(state, 'forms'));

  const addItemToFolder = useAddToFolder(
    {
      onSuccess: () => {
        emitter.emit('refetch_forms');
      },
      onError: () => {
        showErrorMessage('The form was copied, but could not be added to your folder');
      },
    },
  );

  useEffect(() => {
    if (data) {
      setAllChecked(data.length === selection.length);
    }
  }, [data, selection]);

  const debounceValue = useMemo(() => debounce((val) => {
    setParams({ search: val, page: 1 });
  }, 0), [setParams]);

  const handleSearch = (val) => {
    debounceValue(val);
  };

  const checkAllForms = (checked) => {
    let tempSelection = [];
    if (checked) {
      tempSelection = data.map((item) => (
        {
          id: item.id,
          type: 'forms',
        }
      ));
    }
    dispatch(setSelectedItems({ type: 'forms', value: tempSelection }));
  };

  const header = [
    {
      id: 0,
      title: (
        <div
          className={listStyles.checkbox_title}
        >
          <Checkbox
            checked={allChecked}
            onChange={(checked) => checkAllForms(checked)}
          />
        </div>
      ),
      width: 48,
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'submissions_count',
      title: 'Forms submitted',
    },
    {
      field: 'created_at',
      title: 'Date of Creation',
    },
    {
      title: (
        <div className="d-flex justify-content-center">
          Actions
        </div>
      ),
    },
  ];

  const handleDate = (date) => date.slice(0, 10);

  const handleCopyURL = (id) => {
    let baseUrl = 'https://dev.wrm.link/';
    if (window.location.hostname === 'dev.absolutelabs.app') {
      baseUrl = 'https://dev.wrm.link/';
    } else if (window.location.hostname === 'localhost') {
      baseUrl = 'https://localhost:3000/';
    } else if (window.location.hostname === 'staging.absolutelabs.app') {
      baseUrl = 'https://staging.wrm.link/';
    } else {
      baseUrl = 'https://wrm.link/';
    }
    const url = baseUrl + id;
    navigator.clipboard.writeText(url)
      .then(() => {
        showSuccessMessage('Form URL was copied to your clipboard');
      })
      .catch((err) => {
        showErrorMessage('Failed to copy URL: ', err);
      });
  };

  const onResultReceive = useCallback((response, type) => {
    if (response.isSuccess) {
      showSuccessMessage(`Form was successfully ${type}`);
      if (type === 'copied') {
        setCopy(null);
      }
      if (type === 'deleted') {
        setClickForm(null);
      }
      if (type === 'closed') {
        setClose(null);
      }
      if (data?.length === 1 && page > 1) {
        setParams({ page: page - 1 });
      } else {
        refetch();
      }
      response.reset();
    }
    if (response.isError) {
      showErrorMessage(Object.keys(response.error.data).length !== 0 && response.error.data.detail);
      response.reset();
    }
  }, [data?.length, page, refetch]);

  const handleManageFolders = (item) => {
    if (manageFolders) {
      manageFolders(item.id, 'forms');
    }
  };

  useEffect(() => {
    if (copyResult.status === 'fulfilled') {
      const newId = copyResult.data.id;
      if (currentFolder.id !== 'all' && currentFolder.id !== 'my') {
        addItemToFolder(
          {
            itemId: newId,
            itemType: 'forms',
            folderId: currentFolder.id,
            folderType: 'forms',
          },
        );
      }
    }
  }, [copyResult]);

  useEffect(() => {
    onResultReceive(closeResult, 'closed');
  }, [closeResult, onResultReceive]);

  useEffect(() => {
    onResultReceive(copyResult, 'copied');
  }, [copyResult, onResultReceive]);

  useEffect(() => {
    onResultReceive(deleteResult, 'deleted');
  }, [deleteResult, onResultReceive]);

  const getStatus = (curStatus) => {
    if (curStatus === 'Active') return 'Running';
    if (curStatus === 'Paused' || curStatus === 'Completed') return 'Stopped';
    return curStatus;
  };

  useEffect(() => {
    if (data) {
      const temp = data.map((elem) => ({
        checked: (
          <div
            className={listStyles.checkbox_title}
          >
            <TableCheckbox
              id={elem.id}
              type="forms"
              itemType="forms"
            />
          </div>
        ),
        name: (
          <div className={listStyles.name_field_wrapper}>
            <NameField
              id={elem.id}
              text={elem.name}
              search={search}
              link={elem.status.toLowerCase() === 'draft'
                ? `/add-form/${elem.id}`
                : `/form-analytics/${elem.id}`}
              tooltip={elem.name}
            />
            <NameFieldDetails
              creator={elem.creator}
            />
          </div>
        ),
        status: getStatus(elem.status),
        submissions_count: elem.submissions_count,
        created_at: <span className={listStyles.created_at_field}>{handleDate(elem.created_at)}</span>,
        button: (
          <div className={`ms-auto ${tableStyles.popover_wrapper} text-center position-relative cursor-pointer`}>
            <img
              src={Settings}
              className="cursor-pointer"
              height="25"
              width="25"
              alt="settings"
            />
            <div className={`${tableStyles.invisible_dropdown} position-absolute top-0 right-0`}>
              <div className={`${tableStyles.popover_content} position-absolute`}>
                {elem.status === 'Active' ? (
                  <PopupMenuButton onClick={() => handleCopyURL(elem.url)}>
                    CopyURL
                  </PopupMenuButton>
                ) : (
                  null
                )}

                <PopupMenuButton onClick={() => setCopy({ name: elem.name, id: elem.id })}>
                  <Copy />
                  Copy
                </PopupMenuButton>

                <PopupMenuButton onClick={() => handleManageFolders(elem)}>
                  <ManageFolder color="#90A0B7" />
                  Manage folders
                </PopupMenuButton>

                <PopupMenuButton onClick={() => setClickForm({
                  name: elem.name,
                  id: elem.id,
                })}
                >
                  <Trash />
                  Delete
                </PopupMenuButton>

                {
                  elem.status === 'Active'
                    ? (
                      <PopupMenuButton onClick={() => setClose({
                        name: elem.name,
                        id: elem.id,
                      })}
                      >
                        Close
                      </PopupMenuButton>
                    ) : null
                }
              </div>
            </div>
          </div>),
      }));
      setTableData(temp);
    }
  }, [data]);

  const setFilter = (id) => {
    switch (id) {
      case 'running':
      case 'draft':
      case 'stopped':
        setParams({ status: id, page: 1 });
        break;
      case 'all':
      default:
        setParams({ status: '', page: 1 });
        break;
    }
  };

  return (
    <div className={`${listStyles.wrapper} asset-section d-flex flex-column justify-content-between`}>
      <div>
        { copy && (
          <ConfirmModal
            title="Confirm copy"
            description={`Are you sure you want to copy the “${copy.name}” form with its configurations?`}
            buttonName="Copy"
            onSubmit={() => copyTeamForm(copy.id)}
            onCancel={() => setCopy(null)}
            loading={copyResult.isLoading || copyResult.isFetching}
          />
        )}
        { clickForm && (
          <ConfirmModal
            title="Confirm delete"
            description={`Are you sure you want to delete “${clickForm?.name?.length > 30
              ? `${clickForm?.name.slice(0, 30)}...` : clickForm?.name}”?`}
            buttonName="Delete"
            onSubmit={() => deleteTeamForm(clickForm.id)}
            onCancel={() => setClickForm(null)}
            loading={deleteResult.isLoading || deleteResult.isFetching}
          />
        )}
        { close && (
          <ConfirmModal
            title="Confirm close"
            description={`Are you sure you want to close “${close.name.length > 30
              ? `${close.name.slice(0, 30)}...` : close.name}”?`}
            buttonName="Close"
            onSubmit={() => closeTeamForm(close.id)}
            onCancel={() => setClose(null)}
            loading={closeResult.isLoading || closeResult.isFetching}
          />
        )}

        <TableHeader
          count={count}
          page={page}
          limit={limit}
          searchValue={search}
          handleSearch={handleSearch}
          type="forms"
        >
          <button
            className={`regular-button ${listStyles.header_button}`}
            type="button"
            onClick={() => {
              navigate(
                '/add-form',
                currentFolder.id !== 'all' && currentFolder.id !== 'my'
                  ? { state: { folder: currentFolder.id } }
                  : { state: null },
              );
            }}
          >
            Create a Form
          </button>
        </TableHeader>

        <ListFilter
          filters={[
            {
              id: 'all',
              label: 'All',
              icon: <AllIcon color="#323C47" />,
              selected: getStatus(status).toLowerCase() === '',
            },
            {
              id: 'running',
              label: 'Running',
              icon: <RunningIcon color="#323C47" />,
              selected: getStatus(status).toLowerCase() === 'running',
            },
            {
              id: 'draft',
              label: 'Draft',
              icon: <DraftIcon color="#323C47" />,
              selected: getStatus(status).toLowerCase() === 'draft',
            },
            {
              id: 'stopped',
              label: 'Stopped',
              icon: <StoppedIcon color="#323C47" />,
              selected: getStatus(status).toLowerCase() === 'stopped',
            },
          ]}
          setFilter={setFilter}
        />

        <div className="w-100">
          <MainTable
            total={count}
            data={tableData}
            desc={desc}
            ordering={ordering}
            page={page}
            limit={limit}
            headers={header}
            isLoading={isLoading}
            min={10}
            nothingComponent={<SearchResult type="form" search={search} />}
          />
        </div>
      </div>
    </div>
  );
};

export default FormsList;
