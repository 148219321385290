/* eslint-disable camelcase */
import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';

import NotFoundPng from '../../assets/images/not-found/not-found-2.png';
import { clearState } from '../../store/reducers/app';
import styles from './NotFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    const listener = ({ action }) => {
      if (action === 'POP') {
        dispatch(clearState());
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [dispatch, navigator]);

  return (
    <div
      className="d-flex bg-white flex-column justify-content-center align-items-center my-auto vh-100 gap-2"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Not Found
          {' '}
          - Absolute Labs Platform
        </title>
      </Helmet>
      <img src={NotFoundPng} className={styles.image_adaptive} alt="img" />
      <div className="title-h2-bold">Something went wrong</div>
      <div className="title-h5-thin">The page you are trying to load does not exist</div>
      <button
        className="regular-button mt-3"
        type="button"
        onClick={() => {
          dispatch(clearState());
          navigate('/');
        }}
      >
        Go to Dashboard
      </button>
    </div>
  );
};

export default NotFound;
