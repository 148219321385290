import React, { useRef } from 'react';
import RoundSpinner from '../../../../../components/base/RoundSpinner';
import { ReactComponent as Upload } from '../../../../../assets/icons/forms/upload_image.svg';
import { ReactComponent as Trash } from '../../../../../assets/icons/forms/trash.svg';
import styles from './pictureSelector.module.scss';
import settingsStyles from '../ModalSettings/ModalSettings.module.scss';

const PictureSelector = ({
  title, url, fileName, uploadResult, handleFileUpload, removeImage, fullWidth = false, resetImage = false,
}) => {
  const inputRef = useRef();

  const shortenFileName = (name) => {
    let maxLength = 4;
    if (fullWidth) {
      maxLength = 20;
    }
    if (name.length <= maxLength) return name;
    return `${name.substr(0, (maxLength - 1))}...${name.slice(-5, -4)}`;
  };

  const handleRemoveImage = (e) => {
    e.stopPropagation();
    removeImage();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className={styles.wrapper} style={fullWidth ? { maxWidth: 'none' } : null}>
      <div className={settingsStyles.title}>{title}</div>
      <button
        type="button"
        className={`${styles.input_wrapper} ${url ? styles.smaller_padding : ''}`}
        onClick={handleClick}
      >
        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          onChange={handleFileUpload}
          className={styles.input}
          id="formImageUpload"
        />

        {uploadResult.isLoading ? (
          <>
            Loading...
            <RoundSpinner />
          </>
        ) : !url ? (
          <>
            <span className={styles.text}>Upload</span>
            <Upload />
          </>
        ) : (
          <>
            <img src={url} alt="Preview" className={`${styles.image_preview}`} />
            <span>{shortenFileName(fileName)}</span>
            <Trash onClick={handleRemoveImage} />
            <Upload />
          </>
        )}
      </button>
    </div>
  );
};

export default PictureSelector;
