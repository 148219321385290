import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../ChannelsNode.module.scss';
import { ReactComponent as Twitter } from '../../../../../assets/icons/flows/twitter.svg';
import { ReactComponent as Edit } from '../../../../../assets/icons/flows/edit.svg';
import { ReactComponent as Alert } from '../../../../../assets/icons/flows/alert.svg';
import {
  clearActiveSettings,
  deleteSettings,
  selectConfigsList,
  selectErrors, selectFlowStatus,
  // setActiveSettings,
} from '../../../../../store/reducers/flows';
import { ReactComponent as Delete } from '../../../../../assets/icons/flows/delete.svg';
import { handle, handleInput } from '../../../../../utils/flows';
import Tooltip from '../../../../../components/ui/Tooltip';

const TwitterNode = ({ ...props }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [completed, setCompleted] = useState(false);
  const [onNodeHover, setOnNodeHover] = useState(false);

  const configList = useSelector(selectConfigsList);
  const errors = useSelector(selectErrors);
  const status = useSelector(selectFlowStatus);

  const filteredErrors = () => errors.length && errors.filter((elem) => elem.node_id === props.id);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(props.id);
      if (index >= 0) {
        setData(configList[index]);
      }
    }
  }, [configList, props.id]);

  return (
    <>
      <div
        className={`
        ${styles.wrapper}
        d-flex
        flex-column
        justify-content-center
        `}
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
      >
        <div className={`${styles.title} d-flex align-items-center justify-content-between gap-2 w-100`}>
          <div className="d-flex align-items-center gap-2">
            {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
              ? (
                <>
                  {!completed || filteredErrors().length
                    ? (
                      <>
                        <div
                          className={styles.alert}
                          data-for={`${props.id}_error`}
                          data-tip
                        >
                          <Alert />
                        </div>
                        {filteredErrors().length
                          ? (
                            <Tooltip
                              id={`${props.id}_error`}
                              info={`${filteredErrors()[0].detail}`}
                            />
                          )
                          : null}
                      </>
                    )
                    : null}
                  <div className={onNodeHover ? styles.delete : styles.delete_hide}>
                    <Delete onClick={() => {
                      props.data.onRemove(props.id);
                      dispatch(deleteSettings(props.id));
                      dispatch(clearActiveSettings());
                    }}
                    />
                  </div>
                </>
              )
              : null}
            <Twitter />
            <div className={styles.description}>
              {(data && data.name) || 'Twitter'}
            </div>
          </div>
          {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
            ? (
              <div className="cursor-pointer">
                <Edit />
              </div>
            )
            : null}
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ ...handleInput, left: '-10px' }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ ...handle, right: '-16px' }}
        id="twitterNode_output"
      />
    </>
  );
};

export default TwitterNode;
