import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import styles from './FormsBuilder.module.scss';
import {
  setActiveSettings,
  selectActiveSettings,
  clearActiveSettings,
} from '../../../../../store/reducers/forms';
import { ReactComponent as Expand } from '../../../../../assets/icons/expand.svg';
import ActionButtons from '../ActionButtons';
import OverlayField from '../OverlayField';

const SortableField = (props) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [indicatorHeight, setIndicatorHeight] = useState('auto');
  const activeSettings = useSelector(selectActiveSettings);
  const fieldRef = useRef();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const {
    id, index, field, fieldData, onRemove, isPointerOutsideBuilder,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    removable,
  } = useSortable({
    id,
    data: {
      index,
      id,
      field,
    },
    removable: true,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    isDragging,
    removable,
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setIndicatorHeight(fieldRef.current?.scrollHeight || 0);
    });

    if (fieldRef.current) {
      observer.observe(fieldRef.current);
    } else {
      observer.disconnect();
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={setNodeRef}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ActionButtons
        id={id}
        type={field.type}
        activeFieldId={activeSettings?.field_id}
        isHovered={isHovered}
        height={indicatorHeight}
        onRemove={onRemove}
        attributes={attributes}
        listeners={listeners}
      />

      <div
        className={`
          ${styles.indicator_hovered_field} 
          ${field.id === activeSettings?.field_id
      ? styles.visible_with_transition
      : styles.hidden_with_transition}
        `}
        style={{ height: indicatorHeight }}
      />

      <div
        ref={fieldRef}
        role="presentation"
        className={`${styles.parent} flex-grow-1`}
        {...listeners}
        {...attributes}
        onClick={() => {
          dispatch(clearActiveSettings());
          dispatch(setActiveSettings({ type: field.type, field_id: id }));
        }}
      >
        {isDragging
          ? <div className={styles.spacer}>{' '}</div>
          : <OverlayField field={field} fieldData={fieldData} isPointerOutsideBuilder={isPointerOutsideBuilder} />}
      </div>
    </div>
  );
};

const FormBuilder = (props) => {
  const { fields, onRemove, isPointerOutside } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDroppable({
    id: 'canvas_droppable',
    data: {
      parent: null,
      isContainer: true,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={`${fields.length === 0 ? 'canvas-empty' : 'canvas'}`}
      style={style}
      {...attributes}
      {...listeners}
    >
      {fields.length === 0 ? (
        <div className={`empty-message ${styles.form_placeholder}`}>
          <Expand />
          Drop your form elements by dragging from the left bar
        </div>
      ) : (
        <div className={`canvas-fields ${styles.fields}`}>
          {fields.map((field, index) => (
            <SortableField
              key={field.id}
              id={field.id}
              index={index}
              field={field}
              fieldData={field.data}
              onRemove={onRemove}
              isPointerOutsideBuilder={isPointerOutside}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default FormBuilder;
