import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import DappUsageTable from './DappUsageTable';
import { profilesApi } from '../../../api/profiles';

const DappUsage = ({ address }) => {
  const { name } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_transactions_last_30d');
  const [limit, setLimit] = useState(25);

  const {
    data: dappData,
    isLoading: isDappDataLoading,
    isFetching: isDappDataFetching,
  } = profilesApi.useGetWalletDappUsageDataQuery({
    address: ethers.utils.isAddress(name) ? `?address=${name}` : `?alid=${name}&address=${address}`,
    limit,
    offset: (page - 1) * limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
  }, { skip: !ethers.utils.isAddress(name) && !address });

  return (
    <DappUsageTable
      data={dappData}
      isLoading={isDappDataLoading || isDappDataFetching}
      setPage={setPage}
      setDesc={setDesc}
      setOrder={setOrder}
      setLimit={setLimit}
      page={page}
      limit={limit}
      desc={desc}
      orderBy={orderBy}
    />
  );
};

export default DappUsage;
