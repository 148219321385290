import React from 'react';

import { ReactComponent as Headline } from '../../../../../assets/icons/forms/headline.svg';
import { ReactComponent as Paragraph } from '../../../../../assets/icons/forms/paragraph.svg';
import { ReactComponent as Image } from '../../../../../assets/icons/forms/image.svg';
import { ReactComponent as Button } from '../../../../../assets/icons/forms/button.svg';
import { ReactComponent as ConnectWallet } from '../../../../../assets/icons/forms/connect-wallet.svg';
import { ReactComponent as Email } from '../../../../../assets/icons/forms/email.svg';
import { ReactComponent as Phone } from '../../../../../assets/icons/forms/phone.svg';
import { ReactComponent as Twitter } from '../../../../../assets/icons/forms/twitter.svg';
import { ReactComponent as Discord } from '../../../../../assets/icons/forms/discord.svg';
import { ReactComponent as Name } from '../../../../../assets/icons/forms/name.svg';
import { ReactComponent as Language } from '../../../../../assets/icons/forms/language.svg';
import { ReactComponent as Country } from '../../../../../assets/icons/forms/country.svg';
import { ReactComponent as Telegram } from '../../../../../assets/icons/forms/telegram.svg';
import { ReactComponent as Instagram } from '../../../../../assets/icons/forms/instagram.svg';
import { ReactComponent as OptIn } from '../../../../../assets/icons/forms/optin.svg';
import { ReactComponent as Text } from '../../../../../assets/icons/forms/text.svg';

export const staticFields = [
  {
    name: 'Headline',
    component: <Headline />,
    behavior: 'headlineField',
    type: 'headline',
    title: 'Text Input',
  },
  {
    name: 'Paragraph',
    component: <Paragraph />,
    behavior: 'paragraphField',
    type: 'paragraph',
    title: 'Text Input',
  },
  {
    name: 'Image',
    component: <Image />,
    behavior: 'imageField',
    type: 'image',
    title: 'Text Input',
  },
  {
    name: 'Button',
    component: <Button />,
    behavior: 'buttonField',
    type: 'button',
    title: 'Text Input',
  },
];

export const contactFields = [
  {
    name: 'Connect Wallet',
    component: <ConnectWallet />,
    behavior: 'connectWalletField',
    type: 'connectWallet',
  },
  {
    name: 'Email',
    component: <Email />,
    behavior: 'emailField',
    type: 'email',
  },
  {
    name: 'Twitter',
    component: <Twitter />,
    behavior: 'twitterField',
    type: 'twitter',
  },
  {
    name: 'Instagram',
    component: <Instagram />,
    behavior: 'instagramField',
    type: 'instagram',
  },
  {
    name: 'Discord',
    component: <Discord />,
    behavior: 'discordField',
    type: 'discord',
  },
  {
    name: 'Telegram',
    component: <Telegram />,
    behavior: 'telegramField',
    type: 'telegram',
  },
  {
    name: 'Phone number',
    component: <Phone />,
    behavior: 'phoneField',
    type: 'phone',
  },
  {
    name: 'Name',
    component: <Name />,
    behavior: 'firstNameField',
    type: 'name',
  },
];

export const otherFields = [
  {
    name: 'Language',
    component: <Language />,
    behavior: 'languageField',
    type: 'language',
  },
  {
    name: 'Country',
    component: <Country />,
    behavior: 'countryField',
    type: 'country',
  },
  {
    name: 'Opt In',
    component: <OptIn />,
    behavior: 'optInField',
    type: 'optIn',
  },
];

export const questionFields = [
  {
    name: 'Text',
    component: <Text />,
    behavior: 'questionTextField',
    type: 'questionText',
  },
];
