import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { coinApi } from '../../../api/coin';
import styles from './Holders.module.scss';
import HoldersList from '../../../components/ui/HoldersList';
import Distribution from './Distribution';
import PortfolioShare from './PortfolioShare';
import HistoricalHolders from './HistoricalHolders';
import { selectFilterOption } from '../../../store/reducers/coin';

const Holders = ({ address, blockchain }) => {
  const { network } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterOption = useSelector(selectFilterOption);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'token_qty');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);

  const [getNFTHolderListCSV, result] = coinApi.useGetNftHolderListCSVMutation({ audience: filterOption });
  const showPortfolioShare = !['lost', 'all'].includes(filterOption);
  const showDistribution = !['lost', 'all', 'new'].includes(filterOption);

  const {
    data,
    isLoading: isHoldersListLoading,
    isFetching: isHoldersListFetching,
  } = coinApi.useGetNFTsHoldersListQuery({
    address,
    des: desc ? '1' : '0',
    limit: +limit,
    order,
    page,
    blockchain: network || 'ethereum',
    audience: filterOption,
  });

  useEffect(() => {
    if (isHoldersListLoading || isHoldersListFetching) {
      setSearchParams(
        {
          ...Object.fromEntries([...searchParams]),
          page: 1,
          perPage: 25,
        },
      );
      setPage(1);
      setLimit(25);
    }
  }, [filterOption]);

  return (
    <div className={styles.wrapper}>
      {(showPortfolioShare || showDistribution) && (
        <div className={`${styles.charts} d-flex flex-column flex-md-row gap-4`}>
          <div className="flex-grow-1">
            {showPortfolioShare && (
              <PortfolioShare address={address} network={blockchain} audience={filterOption} className="h-100 w-100" />
            )}
          </div>
          <div className="flex-grow-1">
            {showDistribution && (
              <Distribution address={address} network={blockchain} audience={filterOption} className="h-100 w-100" />
            )}
          </div>
        </div>
      )}
      <div className="d-flex flex-column pb-3 w-100">
        <HistoricalHolders address={address} network={blockchain} audience={filterOption} />
      </div>
      <HoldersList
        data={data}
        isLoading={isHoldersListLoading || isHoldersListFetching}
        limit={limit}
        setLimit={setLimit}
        setDesc={setDesc}
        setPage={setPage}
        setOrder={setOrder}
        page={page}
        desc={desc}
        order={order}
        result={result}
        downloadCSV={getNFTHolderListCSV}
        nft
        audience={filterOption}
      />
    </div>
  );
};

export default Holders;
