import React from 'react';
import { ReactComponent as Intelligence } from '../../assets/icons/interests/artificial_intelligence.svg';
import { ReactComponent as Augmented } from '../../assets/icons/interests/augmented.svg';
import { ReactComponent as BigData } from '../../assets/icons/interests/big_data.svg';
import { ReactComponent as Charity } from '../../assets/icons/interests/charity.svg';
import { ReactComponent as Communication } from '../../assets/icons/interests/communication.svg';
import { ReactComponent as CEX } from '../../assets/icons/interests/cex.svg';
import { ReactComponent as DEX } from '../../assets/icons/interests/DEX.svg';
import { ReactComponent as Defi } from '../../assets/icons/interests/defi.svg';
import { ReactComponent as Derivatives } from '../../assets/icons/interests/derivatives.svg';
import { ReactComponent as Education } from '../../assets/icons/interests/education.svg';
import { ReactComponent as Energy } from '../../assets/icons/interests/energy.svg';
import { ReactComponent as Entertainment } from '../../assets/icons/interests/entertainment.svg';
import { ReactComponent as Fractionalized } from '../../assets/icons/interests/fractionalized_nft.svg';
import { ReactComponent as Gambling } from '../../assets/icons/interests/Gambling.svg';
import { ReactComponent as Gaming } from '../../assets/icons/interests/gaming.svg';
import { ReactComponent as Governance } from '../../assets/icons/interests/governance.svg';
import { ReactComponent as Healthcare } from '../../assets/icons/interests/healthcare.svg';
import { ReactComponent as Infrastructure } from '../../assets/icons/interests/infrastructure.svg';
import { ReactComponent as Insurance } from '../../assets/icons/interests/insurance.svg';
import { ReactComponent as IOT } from '../../assets/icons/interests/iot.svg';
import { ReactComponent as Launchpad } from '../../assets/icons/interests/Launchpad.svg';
import { ReactComponent as Borrowing } from '../../assets/icons/interests/Borrowing.svg';
import { ReactComponent as Marketing } from '../../assets/icons/interests/Marketing.svg';
import { ReactComponent as Meme } from '../../assets/icons/interests/memes.svg';
import { ReactComponent as Metaverse } from '../../assets/icons/interests/metaverse.svg';
import { ReactComponent as Music } from '../../assets/icons/interests/Music.svg';
import { ReactComponent as Options } from '../../assets/icons/interests/Options.svg';
import { ReactComponent as Earn } from '../../assets/icons/interests/earn.svg';
import { ReactComponent as Privacy } from '../../assets/icons/interests/Privacy Coins.svg';
import { ReactComponent as Estate } from '../../assets/icons/interests/real Estate.svg';
import { ReactComponent as Retail } from '../../assets/icons/interests/Retail.svg';
import { ReactComponent as Sports } from '../../assets/icons/interests/Sports.svg';
import { ReactComponent as Science } from '../../assets/icons/interests/Technology & science.svg';
import { ReactComponent as Tourism } from '../../assets/icons/interests/Tourism.svg';
import { ReactComponent as Virtual } from '../../assets/icons/interests/Virtual Reality.svg';
import { ReactComponent as Wallets } from '../../assets/icons/interests/wallets.svg';
import { ReactComponent as Aggregator } from '../../assets/icons/interests/Yield aggregator.svg';
import { ReactComponent as Farming } from '../../assets/icons/interests/yield_farming.svg';
import { ReactComponent as Collectibles } from '../../assets/icons/interests/Collectibles.svg';
import { ReactComponent as DomainNames } from '../../assets/icons/interests/Domain Names.svg';
import { ReactComponent as Art } from '../../assets/icons/interests/ART.svg';
import { ReactComponent as Photography } from '../../assets/icons/interests/Photography.svg';
import { ReactComponent as TradingCards } from '../../assets/icons/interests/Trading Cards.svg';
import { ReactComponent as Membership } from '../../assets/icons/interests/Membership.svg';
import { ReactComponent as Email } from '../../assets/icons/alias/email.svg';
import { ReactComponent as Discord } from '../../assets/icons/alias/discrod.svg';
import { ReactComponent as Twitter } from '../../assets/icons/alias/twitter.svg';
import { ReactComponent as Name } from '../../assets/icons/alias/name.svg';
import { ReactComponent as Telegram } from '../../assets/icons/alias/telegram.svg';
import { ReactComponent as Opensea } from '../../assets/icons/alias/opensea.svg';
import { ReactComponent as AMM } from '../../assets/icons/interests/amm.svg';
import { ReactComponent as Business } from '../../assets/icons/interests/business.svg';
import { ReactComponent as DePIN } from '../../assets/icons/interests/depin.svg';
import { ReactComponent as Environment } from '../../assets/icons/interests/environment.svg';
import { ReactComponent as Identity } from '../../assets/icons/interests/identity.svg';
import { ReactComponent as MoveToEarn } from '../../assets/icons/interests/MoveToEarn.svg';
import { ReactComponent as Oracles } from '../../assets/icons/interests/oracles.svg';
import { ReactComponent as PaymentSolutions } from '../../assets/icons/interests/payment_solutions.svg';
import { ReactComponent as Perpetuals } from '../../assets/icons/interests/perpetuals.svg';
import { ReactComponent as PredictionMarkets } from '../../assets/icons/interests/predict_markets.svg';
import { ReactComponent as RealWorldAssets } from '../../assets/icons/interests/rwa.svg';
import { ReactComponent as Restaking } from '../../assets/icons/interests/restaking.svg';
import { ReactComponent as SocialFi } from '../../assets/icons/interests/socialFi.svg';
import { ReactComponent as Storage } from '../../assets/icons/interests/storage.svg';

export const interestData = {
  AI: <Intelligence />,
  Art: <Art />,
  'Augmented Reality': <Augmented />,
  'Automated Market Maker (AMM)': <AMM />,
  'Big Data': <BigData />,
  Business: <Business />,
  'Card Games': <TradingCards />,
  'Centralized Exchanges': <CEX />,
  Charity: <Charity />,
  Collectibles: <Collectibles />,
  Communication: <Communication />,
  DePIN: <DePIN />,
  'Decentralized Exchanges': <DEX />,
  'Decentralized Finance (DeFi)': <Defi />,
  Derivatives: <Derivatives />,
  Education: <Education />,
  Energy: <Energy />,
  Entertainment: <Entertainment />,
  Environment: <Environment />,
  'Fractionalized NFTs': <Fractionalized />,
  Gambling: <Gambling />,
  Gaming: <Gaming />,
  Governance: <Governance />,
  Healthcare: <Healthcare />,
  Identity: <Identity />,
  Infrastructure: <Infrastructure />,
  Insurance: <Insurance />,
  'Internet of Things (IOT)': <IOT />,
  Launchpads: <Launchpad />,
  'Lending/Borrowing': <Borrowing />,
  Marketing: <Marketing />,
  Memberships: <Membership />,
  Memes: <Meme />,
  Metaverse: <Metaverse />,
  'Move To Earn': <MoveToEarn />,
  Music: <Music />,
  Options: <Options />,
  Oracles: <Oracles />,
  PFPs: <Collectibles />,
  'Payment Solutions': <PaymentSolutions />,
  Perpetuals: <Perpetuals />,
  Photography: <Photography />,
  'Play To Earn': <Earn />,
  'Prediction Markets': <PredictionMarkets />,
  'Privacy Coins': <Privacy />,
  'Real Estate': <Estate />,
  'Real World Assets (RWA)': <RealWorldAssets />,
  Restaking: <Restaking />,
  Retail: <Retail />,
  SocialFi: <SocialFi />,
  Sports: <Sports />,
  Storage: <Storage />,
  'Technology & Science': <Science />,
  Tourism: <Tourism />,
  'Virtual Reality': <Virtual />,
  Wallets: <Wallets />,
  'Yield Aggregator': <Aggregator />,
  'Yield Farming': <Farming />,
  'Domain Names': <DomainNames />,
};

export const aliasData = {
  email: <Email />,
  name: <Name />,
  twitter_id: <Twitter />,
  discord_id: <Discord />,
  telegram_id: <Telegram />,
  marketplace_name: <Opensea />,
};

export const aliasTooltipInfo = {
  email: 'Email Address',
  name: 'First and Last Name',
  twitter_id: 'Twitter ID',
  discord_id: 'Discord ID',
  telegram_id: 'Telegram ID',
  marketplace_name: 'OpenSea Name',
};
