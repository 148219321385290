import React, { useMemo } from 'react';
import { DateSelect, DefaultSelect } from '../selects';
import {
  countriesOptions,
  languagesOptions,
} from '../../../../utils/webSDK';
import MultiSelect from '../selects/MultiSelect';
import AsyncMultiSelect from '../selects/AsyncMultiSelect';
import NumericInput from '../selects/NumericInput';

const IsNotValue = 'is-not';
const IsNotValues = ['is', 'is-not'];
const isBeforeAfterValues = ['is-before', 'is-after'];
const isMoreLessValues = ['is-more-than', 'is-less-than'];

const WebActivityControlForm = ({
  data,
  edit,
  pushSelectedOptionToArray,
  getFormLabel,
  loadWebsiteOptions,
  loadWalletTypeOptions,
  loadSourceOptions,
  loadBlockchainOptions,
}) => {
  const optionFilter = (_, index) => {
    switch (data.name) {
      case 'visitors_country':
      case 'visitors_language':
      case 'visitors_wallet_type':
      case 'visitors_source':
      case 'visitors_blockchain':
        if (index > 2) {
          const isNotOption = data.options[2].value;
          if (!isNotOption || (isNotOption && !IsNotValues.includes(isNotOption[0].value))) {
            return false;
          }
        }
        break;
      case 'visitors_first_seen':
      case 'visitors_last_seen':
        if (index === 3) {
          const isBeforeAfterOption = data.options[2].value;
          if (!isBeforeAfterOption
            || (isBeforeAfterOption && !isBeforeAfterValues.includes(isBeforeAfterOption[0].value))) {
            return false;
          }
        }
        break;
      case 'number_of_sessions':
        if (index === 3) {
          const isMoreLessOption = data.options[2].value;
          if (!isMoreLessOption
            || (isMoreLessOption && !isMoreLessValues.includes(isMoreLessOption[0].value))) {
            return false;
          }
        }
        break;
    }
    return true;
  };

  const anyOfAllOfFilter = (option, index) => {
    if (option.options.length === 2
      && option.options[0].value === 'any-of'
      && option.options[1].value === 'all-of'
      && index > 0) {
      const previousOption = data.options[index - 1];
      if (previousOption.value && previousOption.value[0].value === IsNotValue) {
        return {
          ...option,
          options: option.options.filter((opt) => opt.value !== 'all-of'),
        };
      }
    }
    return option;
  };

  const formatOptionLabel = (optionName, label) => {
    if (optionName !== 'any-of-or-all-of-websites') return label;

    if (label === 'Any of') {
      return 'Any websites in';
    } if (label === 'All of') {
      return 'All websites in';
    }
    return label;
  };

  return useMemo(
    () => data.options.filter((value, index) => optionFilter(value, index)).map((option, index) => {
      if (index === 0 && option.value === undefined) {
        pushSelectedOptionToArray('any-of', option.name, option.type);
      }
      if (option.type === 'select') {
        return (
          <DefaultSelect
            key={option.name}
            option={anyOfAllOfFilter(option, index)}
            pushSelectedOptionToArray={pushSelectedOptionToArray}
            edit={edit}
            formatOptionLabel={({ label }) => formatOptionLabel(option.name, label)}
          />
        );
      }

      if (option.type === 'input') {
        return (
          <NumericInput
            key={option.name}
            onChange={(val) => {
              if (val.length <= 18) {
                pushSelectedOptionToArray(val, option.name, option.type);
              }
            }}
            disabled={!edit}
          />
        );
      }

      if (option.type === 'date-picker') {
        return (
          <DateSelect
            key={option.name}
            option={option}
            pushSelectedOptionToArray={pushSelectedOptionToArray}
            edit={edit}
          />
        );
      }

      if (option.type === 'search-country') {
        return (
          <MultiSelect
            key={option.name}
            disabled={!edit}
            value={option.value}
            options={countriesOptions}
            onChange={(val) => {
              pushSelectedOptionToArray(val, option.name, option.type);
            }}
          />
        );
      }

      if (option.type === 'search-language') {
        return (
          <MultiSelect
            key={option.name}
            disabled={!edit}
            value={option.value}
            options={languagesOptions}
            onChange={(val) => {
              pushSelectedOptionToArray(val, option.name, option.type);
            }}
          />
        );
      }

      if (option.type === 'search-web-site') {
        return (
          <AsyncMultiSelect
            key={option.name}
            disabled={!edit}
            options={loadWebsiteOptions}
            value={option.value}
            getLabel={getFormLabel}
            onChange={(val) => {
              pushSelectedOptionToArray(val, option.name, option.type);
            }}
            placeholder="Select websites..."
          />
        );
      }

      if (option.type === 'search-wallet-type') {
        return (
          <AsyncMultiSelect
            key={option.name}
            disabled={!edit}
            value={option.value}
            options={loadWalletTypeOptions}
            getLabel={getFormLabel}
            onChange={(val) => {
              pushSelectedOptionToArray(val, option.name, option.type);
            }}
          />
        );
      }

      if (option.type === 'search-source') {
        return (
          <AsyncMultiSelect
            key={option.name}
            disabled={!edit}
            value={option.value}
            options={loadSourceOptions}
            getLabel={getFormLabel}
            onChange={(val) => {
              pushSelectedOptionToArray(val, option.name, option.type);
            }}
          />
        );
      }

      if (option.type === 'search-blockchain') {
        return (
          <AsyncMultiSelect
            key={option.name}
            disabled={!edit}
            value={option.value}
            options={loadBlockchainOptions}
            getLabel={getFormLabel}
            onChange={(val) => {
              pushSelectedOptionToArray(val, option.name, option.type);
            }}
          />
        );
      }

      return null;
    }),
    [
      data,
      pushSelectedOptionToArray,
      edit,
      loadWebsiteOptions,
      loadWalletTypeOptions,
      loadSourceOptions,
      loadBlockchainOptions,
      getFormLabel,
    ],
  );
};

export default WebActivityControlForm;
