import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import { debounce } from 'lodash';
import { lengthOf18andstop, onPaste } from '../../../../utils/segments';

const NumericInput = ({ onChange, disabled }) => {
  const debouncedChangeHandler = useCallback(
    debounce((val) => {
      if (onChange) {
        onChange(val);
      }
    }, 300),
    [],
  );

  return (
    <NumericFormat
      className="form-control w-100"
      allowNegative={false}
      onPaste={onPaste}
      placeholder="Input a value"
      onValueChange={(values) => {
        const { value } = values;
        debouncedChangeHandler(value);
      }}
      onBlur={(e) => e.target.blur()}
      onKeyDown={(e) => lengthOf18andstop(e)}
      isAllowed={(values) => {
        const { floatValue, formattedValue } = values;
        return Number.isInteger(floatValue) || formattedValue === '';
      }}
      valueIsNumericString
      disabled={disabled}
    />
  );
};

export default NumericInput;
