import React from 'react';
import { ReactComponent as Loading } from '../../../assets/icons/anaytics/default layout.svg';
import { ReactComponent as Data } from '../../../assets/icons/anaytics/data.svg';
import { ReactComponent as CheckMark } from '../../../assets/icons/anaytics/check_mark.svg';
import { ReactComponent as ChartMini } from '../../../assets/icons/anaytics/mimi chart.svg';
import { ReactComponent as Chart } from '../../../assets/icons/anaytics/chart.svg';
import { ReactComponent as Tab } from '../../../assets/icons/anaytics/tab.svg';
import { ReactComponent as Dashboard } from '../../../assets/icons/anaytics/Dashboard.svg';
import { ReactComponent as ShadowDashboard } from '../../../assets/icons/anaytics/dashboard shadow.svg';
import { ReactComponent as ShadowChartMini } from '../../../assets/icons/anaytics/mini chart shadow.svg';
import { ReactComponent as ShadowTab } from '../../../assets/icons/anaytics/tab shadow.svg';
import { ReactComponent as Human } from '../../../assets/icons/anaytics/human.svg';
import { ReactComponent as User1 } from '../../../assets/icons/anaytics/User1.svg';
import { ReactComponent as User2 } from '../../../assets/icons/anaytics/User2.svg';
import { ReactComponent as ShadowUser } from '../../../assets/icons/anaytics/User_shadow.svg';
import { ReactComponent as ShadowChart } from '../../../assets/icons/anaytics/chart shadow.svg';
import { ReactComponent as Gears } from '../../../assets/icons/anaytics/gears.svg';
import styles from './SegmentAnalyticsLoader.module.scss';

const SegmentAnalyticsLoader = () => (
  <div className="d-flex flex-column gap-2 align-items-center justify-content-center w-100">
    <div className={`${styles.wrapper} position-relative`}>
      <Loading />
      <div className={`${styles.dashboard} position-absolute`}>
        <Dashboard />
      </div>
      <div className={`${styles.gears} position-absolute`}>
        <Gears />
      </div>
      <div className={`${styles.shadow_dashboard} position-absolute`}>
        <ShadowDashboard />
      </div>
      <div className={`${styles.tab} position-absolute`}>
        <Tab />
      </div>
      <div className={`${styles.shadow_tab} position-absolute`}>
        <ShadowTab />
      </div>
      <div className={`${styles.human} position-absolute`}>
        <Human />
      </div>
      <div className={`${styles.user1} position-absolute`}>
        <User1 />
      </div>
      <div className={`${styles.user2} position-absolute`}>
        <User2 />
      </div>
      <div className={`${styles.shadow_user1} position-absolute`}>
        <ShadowUser />
      </div>
      <div className={`${styles.shadow_user2} position-absolute`}>
        <ShadowUser />
      </div>
      <div className={`${styles.chart} position-absolute`}>
        <Chart />
      </div>
      <div className={`${styles.shadow_chart} position-absolute`}>
        <ShadowChart />
      </div>
      <div className={`${styles.chart_mini} position-absolute`}>
        <ChartMini />
      </div>
      <div className={`${styles.shadow_chart_mini} position-absolute`}>
        <ShadowChartMini />
      </div>
      <div className={`${styles.check} position-absolute`}>
        <CheckMark />
      </div>
      <div className={`${styles.data} position-absolute w-25`}>
        <Data />
      </div>
    </div>
    <div className={`d-flex align-items-center justify-content-center w-50 ${styles.text}`}>
      Please hold while we generate your report…
    </div>
  </div>
);

export default SegmentAnalyticsLoader;
