import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styles from './AiReport.module.scss';
import CloseModalButton from '../modals/components/CloseModalButton';
import AiInsight from '../../../assets/icons/insights/Ai_Insight.svg';
import useKeydown from '../../hooks/app/useKeydown';
import { coinApi } from '../../../api/coin';
import { showErrorMessage, showSuccessMessage } from '../../base/Notifications';
import { trackSentAIReportEmail } from '../../../utils/mixpanel/mixpanelEvents';
import CustomLink from './CustomLink';

export const AiReport = ({
  onCancel, data, title, isFullMessageReceived, user,
}) => {
  const [isSending, setIsSending] = useState(false);
  const [partialAnalysis, setPartialAnalysis] = useState('');
  const [processedLength, setProcessedLength] = useState(0);
  const { pathname } = useLocation();
  const [sendOverviewTokenByEmail, sendTokenEmailResult] = coinApi.useSendTokenAiOverviewByEmailMutation();
  const [sendOverviewNftByEmail, sendNftEmailResult] = coinApi.useSendNFTAiOverviewByEmailMutation();
  const descriptionRef = useRef();
  const scrollbarRef = useRef();

  useKeydown('Escape', onCancel);

  useEffect(() => {
    const updateScrollbar = () => {
      if (descriptionRef.current && scrollbarRef.current) {
        const { scrollHeight, clientHeight, scrollTop } = descriptionRef.current;
        const scrollPercentage = scrollTop / (scrollHeight - clientHeight);
        const scrollbarHeight = (clientHeight / scrollHeight) * 100;
        const scrollbarTop = scrollPercentage * (100 - scrollbarHeight);

        scrollbarRef.current.style.height = `${scrollbarHeight}%`;
        scrollbarRef.current.style.top = `${scrollbarTop}%`;
      }
    };

    const descriptionEl = descriptionRef.current;
    descriptionEl?.addEventListener('scroll', updateScrollbar);
    updateScrollbar();

    return () => {
      descriptionEl?.removeEventListener('scroll', updateScrollbar);
    };
  }, []);

  useEffect(() => {
    if (!isFullMessageReceived && data.partialMessage) {
      const fullAnalysisPart = data.partialMessage.split('<full_analysis>')[1] || '';
      const newPart = fullAnalysisPart.substring(processedLength);
      setPartialAnalysis((prev) => prev + newPart);
      setProcessedLength(fullAnalysisPart.length);
    }
  }, [data, isFullMessageReceived, processedLength]);

  const handleReceiveViaEmail = () => {
    setIsSending(true);
    trackSentAIReportEmail(user);
    if (pathname.includes('coin')) {
      sendOverviewTokenByEmail({ id: pathname.split('/')[2] });
    } else if (pathname.includes('nft')) {
      sendOverviewNftByEmail({ id: pathname.split('/')[3], blockchain: pathname.split('/')[2] });
    }
  };

  useEffect(() => {
    if (sendTokenEmailResult.isSuccess || sendNftEmailResult.isSuccess) {
      setIsSending(false);
      showSuccessMessage('Email sent successfully');
    } else if (sendTokenEmailResult.isError || sendNftEmailResult.isError) {
      setIsSending(false);
      showErrorMessage('Error sending email');
    }
  }, [sendTokenEmailResult, sendNftEmailResult]);

  const renderContent = () => {
    const content = isFullMessageReceived
      ? data.full_analysis
      : `${partialAnalysis} <aside />`;

    return (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          aside(props) {
            const { node, ...rest } = props;
            return <span className={styles.blinkingDot} {...rest} />;
          },
          p: 'span',
          a: CustomLink,
        }}
        className={styles.markdown}
      >
        {content}
      </ReactMarkdown>
    );
  };

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div className={`${styles.title} mb-3`}>
            <img src={AiInsight} alt="Ai Insight" className={styles.icon} />
            {title || 'AI Summary'}
          </div>
          <div className={`${styles.description_wrapper} mb-3`}>
            <div
              ref={descriptionRef}
              className={styles.description}
            >
              {renderContent()}
            </div>
            <div ref={scrollbarRef} className={styles.custom_scrollbar} />
          </div>
          <div className={`${styles.footer} d-flex justify-content-between align-items-center`}>
            <div className={styles.footerText}>
              This summary is 100% generated via our trained AI models.
              As with all generative AI output, the model can make mistakes.
              Check important information.
            </div>
            <button
              type="button"
              className="regular-button"
              onClick={handleReceiveViaEmail}
              disabled={isSending || !isFullMessageReceived}
            >
              Receive Via Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
