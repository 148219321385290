import React, { useState, useEffect, useMemo } from 'react';
import {
  useLocation, useParams, useSearchParams, Link,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import { ReactComponent as Snapshot } from '../../../assets/icons/camera.svg';
import { ReactComponent as SnapshotActive } from '../../../assets/icons/camera_active.svg';
import SaveSnapshotModal from '../modals/SaveSnapshotModal';
import Tooltip from '../Tooltip';
import uniqueId from '../../../utils/uniqueId';
import { supportedBlockchains } from '../../../utils/supportedBlockchains';
import DownloadModal from '../modals/DownloadModal';
import DownloadTooltip from '../DownloadTooltip';
import { counter } from '../../../tools/Counter';
import { showSuccessMessage, showErrorMessage } from '../../base/Notifications';
import { coinApi } from '../../../api/coin';
import Table from '../../base/Table';
import ExportCSV from '../../svgIcons/ExportCSV';
import ItemPreview from '../ItemPreview';
import ConfirmModal from '../modals/ConfirmModal';
import { createdCustomList } from '../../../store/reducers/segment';
import { aliasData, aliasTooltipInfo } from '../../../utils/wallets';
import tableStyles from '../../base/Table/Table.module.scss';
import styles from './HoldersList.module.scss';
import { ReactComponent as InfoSmall } from '../../../assets/icons/info_small.svg';
import ChainList from '../../base/ChainLogo/ChainList';
import { shortenAddress } from '../../../utils/shortenAddress';
import AgeDate from '../Table/AgeDate';

const warningsList = [
  'No more than 50,000 profiles will be downloaded',
];

const HoldersList = ({
  data,
  isLoading,
  page,
  setPage,
  setDesc,
  limit,
  setLimit,
  desc,
  order,
  setOrder,
  result,
  downloadCSV,
  nft,
  audience,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { name, network } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [snapshotItem, setSnapshotItem] = useState(false);
  const [snapshotName, setSnapshotName] = useState('');
  const [snapshotNft, snapshotNftResult] = coinApi.usePostSnapshotOfNftHolderListMutation();
  const [snapshotCoin, snapshotCoinResult] = coinApi.usePostSnapshotOfCoinHolderListMutation();
  const [header, setHeader] = useState([]);

  const toolTipId = useMemo(() => uniqueId('asset-download'), []);
  const customListTipId = useMemo(() => uniqueId('asset-custom-list'), []);

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);

  useEffect(() => {
    if (snapshotCoinResult.isSuccess) {
      showSuccessMessage('List was successfully saved');
      dispatch(createdCustomList(snapshotCoinResult?.data?.id));
      setTimeout(() => {
        dispatch(createdCustomList(null));
      }, 10000);
      setSnapshotItem(false);
      snapshotCoinResult.reset();
    }
    if (snapshotCoinResult.isError) {
      showErrorMessage(snapshotCoinResult?.error?.data?.detail || 'Something went wrong');
      setSnapshotItem(false);
      snapshotCoinResult.reset();
    }
  }, [dispatch, snapshotCoinResult]);

  useEffect(() => {
    if (snapshotNftResult.isSuccess) {
      showSuccessMessage('List was successfully saved');
      dispatch(createdCustomList(snapshotNftResult?.data?.id));
      setTimeout(() => {
        dispatch(createdCustomList(null));
      }, 10000);
      setSnapshotItem(false);
      snapshotNftResult.reset();
    }
    if (snapshotNftResult.isError) {
      showErrorMessage(snapshotNftResult?.error?.data?.detail || 'Something went wrong');
      setSnapshotItem(false);
      snapshotNftResult.reset();
    }
  }, [dispatch, snapshotNftResult]);

  useEffect(() => {
    const baseHeaders = [
      { field: 'address_name', title: 'Address Name' },
      { field: '', title: 'Alias' },
      { field: 'portfolio_value', title: 'Portfolio Value' },
      { field: 'age', title: 'Wallet Age' },
      { field: '', title: <div className="d-flex justify-content-center">Active On</div> },
    ];
    const sinceHeader = { field: 'since', title: 'Holding Since' };
    const balanceHeader = { field: 'token_qty', title: 'Balance' };
    const shareHeader = { field: 'share_of_portfolio', title: 'Share of Portfolio' };
    const headersWithSince = [...baseHeaders];

    if (!['lost'].includes(audience)) {
      headersWithSince.splice(2, 0, balanceHeader);
      headersWithSince.splice(3, 0, shareHeader);
    }

    if (['current', 'relevant'].includes(audience)) {
      headersWithSince.splice(5, 0, sinceHeader);
    } else if (tableData.includes('since')) {
      const temp = [...tableData];
      temp.forEach((elem) => {
        delete elem.since;
      });
      setTableData(temp);
    }

    setHeader(headersWithSince);
  }, [audience]);

  const truncateString = (str) => (str.length > 50 ? `${str.substring(0, 50)}...` : shortenAddress(str));

  useEffect(() => {
    if (data?.result) {
      const temp = data.result.map((elem, index) => ({
        address_name: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.ALID || elem.address_name || elem.address}`}
              data-tip
            >
              <Link
                role="presentation"
                className={`${styles.name} text-truncate`}
                onClick={() => {
                  setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    scroll: window.scrollY,
                  });
                }}
                to={`/profile/${elem.ALID || elem.address}`}
              >
                {truncateString(elem.address_name || elem.address)}
              </Link>
            </span>
            <ItemPreview
              id={`Name_${elem.ALID || elem.address_name || elem.address}`}
              data={{
                id: elem.address,
                name: elem.address,
                alid: elem.ALID || '',
                type: 'wallet',
              }}
            />
          </>
        ),
        alias: (
          elem.alias
            ? (
              <span className="d-block">
                <div
                  className="d-flex gap-2 align-items-baseline"
                >
                  <span
                    data-for={`alias ${index}`}
                    data-tip
                  >
                    {aliasData[elem.alias_type]}
                  </span>
                  <Tooltip info={aliasTooltipInfo[elem.alias_type]} id={`alias ${index}`} place="top" />
                  <span
                    data-for={`fullAlias ${index}`}
                    data-tip={elem.alias.length > 10 ? elem.alias : ''}
                  >
                    {elem.alias.length > 10 ? `${elem.alias.substring(0, 10)}...` : elem.alias}
                  </span>
                  {elem.alias.length > 2 && <Tooltip info={elem.alias} id={`fullAlias ${index}`} place="top" />}
                </div>
              </span>
            )
            : '-'

        ),
        token_qty: `${elem.token_qty && elem.token_qty >= 1
          ? nft
            ? ethFormat(elem.token_qty, 1)
            : ethFormat(elem.token_qty)
          : '<1'} (${elem.value !== null ? `$${ethFormat(elem.value)}` : '?'})`,
        share_of_portfolio: elem.share_of_portfolio && elem.share_of_portfolio >= 1
          ? `${elem.share_of_portfolio}%`
          : '<1%',
        portfolio_value: `$${ethFormat(elem.portfolio_value)}`,
        since: <AgeDate date={elem.since} defaultText="No Transactions" />,
        age: <AgeDate date={elem.age} defaultText="No Transactions" isAge />,
        network: (
          <ChainList chains={supportedBlockchains(elem.active_on)} small />
        ),
      }));
      if (!['current', 'relevant'].includes(audience)) {
        temp.forEach((elem) => {
          delete elem.since;
        });
      }
      if (['lost'].includes(audience)) {
        temp.forEach((elem) => {
          delete elem.token_qty;
          delete elem.share_of_portfolio;
        });
      }
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data, audience]);

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column align-items-center`}>
      <div className={styles.title}>
        <span> Profile List </span>
        <InfoSmall data-for="profileList" data-tip />
      </div>
      <Tooltip
        id="profileList"
        info="This list may include multiple profiles associated with the same wallet address"
      />
      <div className={
        `${styles.content_info} d-flex align-items-center my-2 w-100`
      }
      >
        {counter(data?.count, page, limit)}
        <div className="d-flex flex-grow-1 gap-2 flex-row-reverse ml-auto">
          <button
            type="button"
            onClick={() => setSnapshotItem(true)}
            className={
              `btn d-flex align-items-center justify-content-center shadow-none m-o p-0 ${styles.button_wrapper}`
            }
          >
            <div
              data-for={customListTipId}
              data-tip
              onMouseEnter={() => setIsActive(true)}
              onMouseLeave={() => setIsActive(false)}
              className={`${styles.snapshot_container} d-flex`}
            >
              {isActive
                ? (
                  <SnapshotActive className="cursor-pointer" />
                )
                : (
                  <Snapshot className="cursor-pointer" />
                )}
            </div>
          </button>
          {!snapshotItem && (
            <Tooltip
              id={customListTipId}
              info="Save snapshot as List"
            />
          ) }
          <button
            type="button"
            className="btn d-flex align-items-center justify-content-center shadow-none m-o p-0"
            data-for={toolTipId}
            data-tip
            onMouseEnter={() => setIsDownloaded(true)}
            onMouseLeave={() => setIsDownloaded(false)}
            onClick={() => downloadCSV(
              {
                id: name,
                wait: true,
                blockchain: network || 'ethereum',
                audience,
              },
            )}
          >
            <ExportCSV hovered={isDownloaded} />
          </button>
          <DownloadTooltip
            id={toolTipId}
            header="Export Profile List into CSV"
            warningsList={warningsList}
          />
        </div>
      </div>
      <div className="w-100">
        <Table
          total={data?.count}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={order}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isLoading}
          min={25}
        />
      </div>
      {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
      {result?.error?.status === 403 ? (
        <ConfirmModal
          title="We are sorry..."
          description="The feature you’re trying to use has restricted access.
                 Please reach out to your Customer Success Manager."
          buttonName="Okay"
          onSubmit={() => result.reset()}
        />
      ) : null}
      {snapshotItem && (
        <SaveSnapshotModal
          value={snapshotName}
          setValue={setSnapshotName}
          onSubmit={() => {
            try {
              if (pathname.split('/')[1] === 'nfts') {
                snapshotNft({
                  id: name, name: snapshotName, network, audience,
                });
              } else {
                snapshotCoin({ id: name, name: snapshotName, audience });
              }
            } catch (err) {
              showErrorMessage(err);
            }
          }}
          onCancel={() => setSnapshotItem(false)}
          loading={
            (snapshotCoinResult.isLoading || snapshotCoinResult.isFetching)
                  || (snapshotNftResult.isLoading || snapshotNftResult.isFetching)
          }
        />
      )}
    </div>
  );
};

export default HoldersList;
