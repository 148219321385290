import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import ExchangesTable from './ExchangesTable';
import { profilesApi } from '../../../api/profiles';

const Exchanges = ({ address }) => {
  const { name } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('txn_count');
  const [limit, setLimit] = useState(25);

  const {
    data: exchanges,
    isLoading: isExchnagesLoading,
    isFetching: isExchnagesFetching,
  } = profilesApi.useGetWalletExchangeDataQuery({
    address: ethers.utils.isAddress(name) ? `?address=${name}` : `?alid=${name}&address=${address}`,
    limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
    page,
  }, { skip: !ethers.utils.isAddress(name) && !address });

  if (true) {
    return (
      <ExchangesTable
        exchanges={exchanges}
        isLoading={isExchnagesLoading || isExchnagesFetching}
        limit={limit}
        setLimit={setLimit}
        page={page}
        desc={desc}
        setPage={setPage}
        setDesc={setDesc}
        order={orderBy}
        setOrder={setOrder}
      />
    );
  }
};

export default Exchanges;
