import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Profile.module.scss';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';
import { webSDKApi } from '../../../api/webSDK';
import TypicalPortfolio from '../../../components/ui/TypicalPortfolio';
import BuyingPower from '../../../components/ui/BuyingPower';
import InvestorPortfolio from './InvestorPortfolio';
import uniqueId from '../../../utils/uniqueId';
import Users from '../../../assets/icons/trackedWebsite/Users.svg';
import WalletDetected from '../../../assets/icons/trackedWebsite/walletDetected.svg';
import CumulatedPortfolioValue from '../../../assets/icons/trackedWebsite/CumulatedPortfolioValue.svg';
import MedianPortfolioValue from '../../../assets/icons/trackedWebsite/MedianPortfolioValue.svg';
import { selectWebSDKFilterParam } from '../../../store/reducers/webSDK';
import WidgetCard from '../../../components/ui/WidgetCard';
import { getCardValue } from '../../../utils/values';

const InvestorProfile = ({ id }) => {
  const [widgetData, setWidgetData] = useState([]);
  const filterParams = useSelector(selectWebSDKFilterParam);
  const {
    currentData: walletTabProfileData,
    isLoading: walletTabProfileLoading,
    isFetching: walletTabProfileFetching,
  } = webSDKApi.useGetWebSDKForWalletTabQuery({
    id,
    ...filterParams,
  });

  useEffect(() => {
    if (walletTabProfileData) {
      setWidgetData([
        {
          title: 'Wallet Count',
          value: getCardValue(walletTabProfileData?.wallet_count),
          icon: Users,
        },
        {
          title: 'Inactive Wallets',
          value: getCardValue(walletTabProfileData?.inactive_wallets_count),
          icon: WalletDetected,
        },
        {
          title: 'Cumulated Portfolio Value',
          value: getCardValue(walletTabProfileData?.cumulated_portfolio_value),
          icon: CumulatedPortfolioValue,
        },
        {
          title: 'Median Portfolio Value',
          value: getCardValue(walletTabProfileData?.median_portfolio_value),
          icon: MedianPortfolioValue,
        },
      ]);
    }
  }, [walletTabProfileData]);

  return (
    <div className={styles.wrapper}>
      <div className="d-flex justify-content-between gap-4">
        {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
          <WidgetCard
            key={uniqueId('website-widget')}
            icon={item.icon}
            info={item.info}
            growth={item.growth}
            title={item.title}
            value={item.value}
            isLoading={walletTabProfileLoading || walletTabProfileFetching}
          />
        ))}
      </div>
      <div className="row mt-4">
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
          <NotableInvestments
            text="tokens"
            symbol="detected wallets"
            isLoading={walletTabProfileLoading || walletTabProfileFetching}
            data={walletTabProfileData?.token}
            holders={walletTabProfileData?.wallet_count}
            info="Tokens most commonly held by your visitors"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
          <NotableInvestments
            text="NFTs"
            symbol="detected wallets"
            isLoading={walletTabProfileLoading || walletTabProfileFetching}
            data={walletTabProfileData?.nft}
            holders={walletTabProfileData?.wallet_count}
            info="NFTs most commonly held by your visitors"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
          <SharedInterests
            symbol="VISITORS"
            data={walletTabProfileData?.shared_interest}
            isLoading={walletTabProfileLoading}
            isFetching={walletTabProfileFetching}
            info="Topics of interest of your visitors"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-lg-6 col-xl-8 mb-4 mb-lg-0">
          <TypicalPortfolio
            data={walletTabProfileData?.typical_portfolio}
            isLoading={walletTabProfileLoading}
            isFetching={walletTabProfileFetching}
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mb-4 mb-lg-0">
          <BuyingPower
            buyingPower={walletTabProfileData?.buying_power}
            isLoading={walletTabProfileLoading}
            isFetching={walletTabProfileFetching}
            info="Total amount available to invest by your visitors"
          />
        </div>
      </div>
      <div className="row mt-lg-4">
        <div className="col-12 col-lg-12 mb-lg-0">
          <InvestorPortfolio
            id={id}
          />
        </div>
      </div>
    </div>
  );
};

export default InvestorProfile;
