import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import { audiencesApi } from '../../../../../api/audiences';
import { showErrorMessage, showSuccessMessage } from '../../../../../components/base/Notifications';
import { ReactComponent as Arrow } from '../../../../../assets/icons/select_arrow.svg';
import { ReactComponent as Search } from '../../../../../assets/icons/search.svg';
import Loader from '../../../../../components/base/Loader';
import uniqueId from '../../../../../utils/uniqueId';
import styles from './AddToListSettings.module.scss';
import RoundSpinner from '../../../../../components/base/RoundSpinner';
import EditableTitle from '../../../../../components/base/EditableTitle';

const AddToListSettings = ({ activeSettings }) => {
  const [titleValue, setTitleValue] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);

  const [customListName, setCustomListName] = useState('');
  const [suggestedList, setSuggestedList] = useState([]);

  const dispatch = useDispatch();

  const {
    register, handleSubmit, setValue, watch, reset, setFocus,
  } = useForm();

  const {
    data: customListsData,
    isLoading: isCustomListsDataLoading,
  } = audiencesApi.useGetTeamSegmentsQuery({
    limit: 100000, itemType: 'custom list',
  }, {
    skip: !showAddModal,
  });
  const [createCustomListAddresses, createCustomListAddressesResult] = audiencesApi.useSubmitCustomListMutation();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const hookInfo = { ...submitData };
    if (titleValue) {
      hookInfo.name = titleValue;
    } else {
      hookInfo.name = 'Add to list';
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...hookInfo, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  const watchedListName = watch('list_name');

  const isSubmitDisabled = !(customListName && !showAddModal)
      || (watchedListName && watchedListName.trim() !== (customListName || '').trim())
      || !!disableChangeStatus;

  useEffect(() => {
    if (showAddModal) {
      setFocus('list_name');
    }
  }, [setFocus, showAddModal]);

  useEffect(() => {
    if (customListsData?.results) {
      const dataName = customListsData.results.map((elem) => elem.name);
      const searchTerm = watch('list_name');

      const filterName = dataName.filter((elem) => typeof elem === 'string'
        && typeof searchTerm === 'string' && elem.toLowerCase().includes(searchTerm.toLowerCase()));

      setSuggestedList(filterName);
    } else {
      setSuggestedList([]);
    }
  }, [customListsData?.results, watch('list_name')]);

  useEffect(() => {
    if (createCustomListAddressesResult.isSuccess) {
      showSuccessMessage('List was successfully created');
      setValue('list_name', createCustomListAddressesResult?.data?.name);
      setValue('list_id', createCustomListAddressesResult?.data?.id);
      setCustomListName(createCustomListAddressesResult?.originalArgs?.name);
      createCustomListAddressesResult.reset();
      setShowAddModal(false);
    }
    if (createCustomListAddressesResult.isError) {
      if (createCustomListAddressesResult.error.status === 400) {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length !== 0
          && 'List with the same name already exists');
      } else {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length
          !== 0 && 'Something went wrong');
      }
      createCustomListAddressesResult.reset();
    }
  }, [createCustomListAddressesResult, setValue]);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setTitleValue(configList[index].name);
        setCustomListName(configList[index].list);
        setValue('list_name', configList[index].list_name);
        setValue('list_id', configList[index].list_id);
      } else {
        reset({
          list_name: '',
          list_id: '',
        });
        setTitleValue('');
      }
    }
  }, [activeSettings.node_id, configList, reset, setValue]);

  const modalContent = useMemo(() => {
    if (isCustomListsDataLoading) {
      return (
        <div className="my-4">
          <Loader />
        </div>
      );
    }
    if (suggestedList.length) {
      return (
        <div className="d-flex flex-column gap-2">
          <span className={styles.title_group}>Select an existing list or create new one</span>
          <div className={styles.wrapper_list}>
            {suggestedList.map((elem) => (
              <div
                key={uniqueId('name_list')}
                className={styles.hover}
              >
                <button
                  {...register('list_id')}
                  className={`${styles.name_list_wrapper} text-truncate my-2 w-100`}
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue('list_name', elem);
                    setValue('list_id', customListsData?.results.filter((item) => item.name === elem)[0].id);
                    setCustomListName(elem);
                    setShowAddModal(false);
                  }}
                >
                  {elem}
                </button>
                <div className={styles.divider_list} />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }, [isCustomListsDataLoading, suggestedList, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between h-100">
      <div>
        <div className={`${styles.title} d-flex align-items-center gap-2`}>
          <EditableTitle
            defaultTitle="Add to list"
            titleValue={titleValue}
            setTitleValue={setTitleValue}
            maxLength={25}
            size={16}
            edit={!disableChangeStatus}
          />
        </div>
        <div className="d-flex flex-column w-100 gap-1 mb-2">
          <div className={styles.title_input}>
            Choose the list
          </div>
          <div
            role="presentation"
            className={disableChangeStatus ? styles.link_area_disabled : styles.link_area}
            onClick={disableChangeStatus
              ? () => {}
              : () => {
                setShowAddModal(true);
                setValue('list_name', '');
              }}
          >
            {!disableChangeStatus && (
              <div width="28px" height="28px" className="cursor-pointer">
                <Search />
              </div>
            )}
            <input
              {...register('list_name')}
              onChange={(e) => {
                setValue('list_name', e.target.value);
              }}
              placeholder="Search for the list or create a new one"
              type="text"
              className="w-100 p-1"
              disabled={disableChangeStatus}
              maxLength={50}
            />
            {!disableChangeStatus && (
              <div className="d-flex align-items-center me-2">
                <div className={`${styles.divider} me-2`} />
                <Arrow className="cursor-pointer" />
              </div>
            )}
            {showAddModal && (
              <div
                role="presentation"
                className={`${styles.modal_wrapper} position-absolute d-flex flex-column gap-2`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="d-flex flex-column gap-2">
                  <span className={styles.title_group}>Create new list</span>
                  <button
                    className={`${styles.name_wrapper} text-truncate`}
                    type="button"
                    disabled={!watch('list_name')}
                    onClick={() => createCustomListAddresses({
                      name: watch('list_name'),
                      addresses: [],
                    })}
                  >
                    {watch('list_name')}
                  </button>
                </div>
                {modalContent}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 mt-4">
        <button
          type="button"
          className={`${styles.btn_cancel}`}
          onClick={() => dispatch(clearActiveSettings())}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="regular-button w-100"
          disabled={isSubmitDisabled}
        >
          Save
        </button>
      </div>
      {showAddModal && (
        <div
          className={`${styles.mod} w-100 h-100 start-0 top-0 position-fixed`}
          onClick={() => setShowAddModal(false)}
          role="presentation"
        />
      )}
      {createCustomListAddressesResult.isLoading || createCustomListAddresses.isFetching
        ? <RoundSpinner />
        : null}
    </form>
  );
};

export default AddToListSettings;
