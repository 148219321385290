import React, { useState } from 'react';
import TitleComponent from '../../../../components/base/TitleComponent';
import InterestsCard from './InterestsCard';
import Loader from '../../../../components/base/Loader';
import uniqueId from '../../../../utils/uniqueId';
import styles from './Interests.module.scss';
import { ReactComponent as InfoSmall } from '../../../../assets/icons/info_small.svg';
import Tooltip from '../../../../components/ui/Tooltip';

const Interests = ({ isFetching, isLoading, walletInterests }) => {
  const [viewShow, setViewShow] = useState(false);

  const tooltipInfo = (
    <div className="d-flex flex-column justify-content-around">
      <div className="d-flex gap-1 align-items-center">
        <span className={styles.dot} />
        <span>Highly interested</span>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <span className={styles.dot_1} />
        <span>Moderately</span>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <span className={styles.dot_2} />
        <span>Mildly</span>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex justify-content-between align-baseline position-relative">
        <TitleComponent>Interests</TitleComponent>
        <div className="d-flex justify-content-start">
          <div
            className={`cursor-pointer position-absolute ${styles.info_btn}`}
            data-for="tooltipId"
            data-tip
          >
            <InfoSmall />
          </div>
          <Tooltip info={tooltipInfo} id="tooltipId" position="center" />
        </div>
        {walletInterests?.length > 4
          ? (
            <div
              className={`${styles.view} cursor-pointer`}
              role="presentation"
              onClick={() => setViewShow(!viewShow)}
            >
              {`View ${viewShow ? 'less' : 'more'}`}
            </div>
          )
          : null}
      </div>
      {isLoading || isFetching
        ? <Loader />
        : walletInterests?.length
          ? (
            <div
              className="d-flex w-100 flex-wrap gap-3"
            >
              {walletInterests.slice().sort((a, b) => b.score - a.score)
                .slice(0, viewShow ? walletInterests.length : 4).map((elem) => (
                  <InterestsCard key={uniqueId('interest_card')} score={elem.score} name={elem.name} />
                ))}
            </div>
          )
          : (
            <div className={styles.no_interests}>
              There are no interests
            </div>
          )}
    </div>
  );
};

export default Interests;
